<div class="">
  <div class="tw-flex tw-row tw-header-show">
    <div style="text-align: center;width: 80px" >
      <img src="./assets/resources/svg/movie-tickets.svg" style="height: 47px;" alt="cartlogo">
        <div style="
                          font-size: 22px;
                          margin-top: -53px;
                          margin-left: 47px;
                          height: 48px;
                          color:green;
                          "

        > <span class="tw-header-number">{{getTickets()}}</span>

      </div>
    </div>
    <div  class="tw-header-font">
      @if (ticketElement.title) {
        <div style="font-weight: 600">
          {{ticketElement.title}}
        </div>
      }
      @if (ticketElement.location) {
        <div style="font-weight: 600">
          {{ticketElement.location.name}}
        </div>
      }
      @if (ticketElement.eventStart) {
        <div>
          {{ticketElement.eventStart}}
        </div>
      }
    </div>
    <div style="text-align: right;width: 77px;margin-right: 10px;">
      {{total | currency :'':'symbol-narrow':'':translate.currentLang}}
    </div>
    <div style="flex: 1">
      @if (!displayDetails) {
        <mat-icon>keyboard_arrow_right</mat-icon>
      }
      @if (displayDetails) {
        <mat-icon>keyboard_arrow_down</mat-icon>
      }
    </div>
  </div>
</div>
