

<div class="mat-elevation-z1" style="margin: 20px">
  @if (translate.currentLang !== 'fr') {
    @if (location?.parameters.pageHtml) {
            <div  style="display: flex;
    justify-content: center;padding: 20px;min-height: 76vh"
        [innerHTML]="location?.parameters.pageHtml | transformHtml">
      </div>
    }
  }
  @if (translate.currentLang === 'fr') {
    @if (location?.parameters.pageHtmlFr) {
            <div   style="display: flex;
    justify-content: center;padding: 20px;min-height: 76vh"
        [innerHTML]="location?.parameters.pageHtmlFr | transformHtml">
      </div>
    }
  }
</div>

