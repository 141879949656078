import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthServiceSocial, AuthServiceSocialConfig} from './auth-service-social.service';

export function configFactory(config: AuthServiceSocialConfig) {
    return config;
}

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        AuthServiceSocial
    ]
})
export class SocialLoginModule {

    public static initialize(config: AuthServiceSocialConfig): ModuleWithProviders<SocialLoginModule> {
        return {
            ngModule: SocialLoginModule,
            providers: [
                AuthServiceSocial,
                {
                    provide: AuthServiceSocialConfig,
                    useValue: config
                }
            ]
        };
    }

}
