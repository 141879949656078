import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'transformHtml'
})
export class TransformHtmlPipe implements PipeTransform {

    constructor(private sanitized: DomSanitizer) {
    }

    transform(value) {
        if (!value) {
            return '';
        }
        return this.sanitized.bypassSecurityTrustHtml('<div>' + value + '<div>');
    }

}
