import { Component, Input, OnInit } from '@angular/core';
import { TicketElement } from '../../../../models/ticket-element';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'tw-client-ticket-header',
  templateUrl: './client-ticket-header.component.html',
  styleUrls: ['./client-ticket-header.component.css']
})
export class ClientTicketHeaderComponent implements OnInit {
  @Input() ticketElement: TicketElement;
  @Input() displayDetails;
  imageUrl = './assets/resources/svg/movie-tickets.svg';
  constructor(public translate: TranslateService) { }

  ngOnInit() {
    if (this.ticketElement.images && this.ticketElement.images.length > 0) {
      if (environment.urlApi.endsWith('/') && this.ticketElement.images[1].url.startsWith('/')) {
        this.imageUrl = environment.urlApi.substring(0, environment.urlApi.length - 1) + this.ticketElement.images[0].url;
      }  else {
        this.imageUrl = environment.urlApi + this.ticketElement.images[1].url;
      }
    }
  }

  get total() {
    let temp = 0;


    this.ticketElement.prices.forEach(p => {
      temp = temp + (p.price + p.serviceCharges) * p.upcomingTicketDetailTokens.length;
    });
    return temp / 100;
  }
  getTickets() {
    let temp = 0;
    this.ticketElement.prices.forEach(p => {
      temp = temp + (p.upcomingTicketDetailTokens.length);
    });
    return temp;
  }
}
