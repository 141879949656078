<div class="tw-padding-10 w-max-width-6" style="position: relative" >

  @if (!hideEditButton && ! showOnlyClientView) {
    <div style="text-align: right;position: absolute;right: 12px">
      <mat-icon aria-label="edit client"  (click)="goEditClient()">edit</mat-icon>
    </div>
  }
  @if ( !client || !client.isCompletedProfile && !showOnlyClientView) {
<div style="margin-bottom: 10px;
    background: #ff000070;
    padding: 10px;">
      <mat-icon aria-label="edit client" style="margin-right: 12px">error_outline</mat-icon>
      <span>{{"client.completeProfil" | translate }}</span>
    </div>
  }
  @if (client) {
    <div>{{client.firstname }} {{ client.lastname}}</div>
    @if (client.phones && client.phones[0] && !client.mock) {
      <div
        class="client-view-phone-temp"
      >{{client?.phones[0]?.number | phone }}</div>
    }
    @if (client.email) {
      <div>{{client?.email }}</div>
    }
    @if (client.company ) {
      <div class="client-view-company-temp" >{{client?.company }}</div>
    }
    <br>
      @if (this.client.addresses && this.client?.addresses.length >0  && showAddress) {
        @if (this.client.addresses[0].company) {
          <span class="client-view-address-company-temp" >{{ this.client.addresses[0].company}}</span>
        }
        <br>
          @if (this.client.addresses[0].appartment) {
            <span >{{ this.client?.addresses[0]?.appartment}}-</span>
          }
          @if (this.client.addresses[0].addressLine1) {
            <span>{{ this.client.addresses[0].addressLine1}}</span>
          }
          @if ( this.client.addresses[0].addressLine2) {
            <span><br>{{ this.client.addresses[0].addressLine2}}</span>
          }
          <br>
            {{this.client?.addresses[0]?.city}}, {{this.client?.addresses[0]?.province}}, {{ this.client?.addresses[0]?.country}}
            {{ (this.client?.addresses[0]?.zipCode).toUpperCase()}}<br>
          }
        }

      </div>
