import {Injectable} from '@angular/core';
import {SymplifyItem, SymplifyMetaData} from '../../../../projects/shared-lib/src/lib/models/symplify-item';
import {HelpsService} from './helps.service';
import {PriceWeb} from '../../../../projects/shared-lib/src/lib/models/price-web';
import {CartPrice} from '../../../../projects/shared-lib/src/lib/models/cart-price';
import {lastValueFrom} from 'rxjs';
import {Cart} from '../../../../projects/shared-lib/src/lib/models/cart';
import {environment} from '../../../../environments';
/* https://help.symplify.com/hc/en-us/articles/360011292099 */
/***
 {{serverUrl}}/{{customerId}}/abandonedcart/clients/www5_8mGXr/carts?order=NEWEST
 GET {{serverUrl}}/{{customerId}}/abandonedcart/clients/www5_8mGXr/carts?order=NEWEST


 https://nam-proxy.symplifymail.com/rest/10330/abandonedcart/clients/www5_8mGXr/carts?order=NEWEST
 Olivier Mineau11 h 26
 GET https://nam-proxy.symplifymail.com/rest/10330/abandonedcart/clients/www5_8mGXr/carts?order=NEWEST
 ***/

declare const carma: any;

@Injectable({
    providedIn: 'root'
})
export class SymplifyCartService {
    client;
    active = false;

    constructor() {

    }

    async init(lang, active = false) {
        this.active = active;
        if (!this.active) {
            return;
        }
        let id = 'www5_8mGXr';
        if (lang === 'fr') {
            id = 'www5_Sq4lB';
        }
        await lastValueFrom(HelpsService.injectScriptObservable('//mimgs.s3.amazonaws.com/scripts/ac.js',
            {id: 'carma_ac', 'data-cid': String(id)}));
        // HelpsService.injectScript('//mimgs.s3.amazonaws.com/scripts/ac.js',
        //     {id: 'carma_ac', 'data-cid': String(id)}).then(() => {
        // }).catch(error => {
        //     //console.error(error);
        // });
    }

    addAllCart(cart: Cart) {
        if (!this.active) {
            return;
        }
        try {
            if (cart) {
                cart?.cartElements.forEach(elm => {
                    this.addToCartPrice(elm.prices);
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    isActiveShow(showId: number) {
        return showId === 1600623108 || showId === 1595847305 || showId === 1572134065 ||//oasis
               showId === 1573530782 || //waitress
               showId === 1599901616 || //New Orleans
               showId === 1596930444 || showId === 1596930443 || showId === 1596930442 || //Canmore
               showId === 1595847302 || //Coldlake
               showId === 1593926344 || //SRFF
               showId === 1613743919 || //Barbu
               showId === 1613743920 || //Dirty Laundry
               showId === 1613743921 || //Le bibliothecaire
               showId === 1612581753 || //Echoes
               showId === 1611460276 || //Michel Pagliaro
               showId === 1612581780 || //Dan Bigras
               showId === 1613743935 || //Gregory Charles
               showId === 1611460261 || //Famille Addams
               showId === 1583938658 || //Tootsie
               showId === 1614288566 || //Megan Brouillard
               showId === 1596930447 || //Rock Story
               showId === 1614288556 || //Styx
               showId === 1612581798 || //Sous les projectuers
               showId === 1613743908 || //Any dreams will do
               showId === 1613743947 || //Arkells
               showId === 1614288576 || //Wildflowers
               showId === 1608297304 || //Boy Golden
               showId === 1604345556 || //Jubilee
               showId === 1613743937 || //Sawyer Brown
               showId === 1595847338 || //Monster Madness
               showId === 1614826750 || //Cirque Eloize
               showId === 1615359809 || //Decembre
               showId === 1593445803 || //Anne & Gilbert
               showId === 1574650058 || //Lutte ExpoCite
               showId === 1606228908 || showId === 1610834379 || showId === 1610834380 || showId === 1596930440 || showId === 1610834378 || showId === 1610834361 || //Harvest
               showId === 1610834353 || showId === 1610834369 || showId === 1610834357 || showId === 1610834363 || showId === 1611460275 || showId === 1610834358 || //Harvest
               showId === 1610834360 || showId === 1610834374 || showId === 1611460257 || showId === 1610834375 || showId === 1610834368 || showId === 1610834364 || //Harvest
               showId === 1610834371 || showId === 1610834366 || showId === 1610834377 || showId === 1610834376 || showId === 1610834359 || showId === 1610834367 || //Harvest
               showId === 1610834373 || showId === 1610834370 || showId === 1610834362 || showId === 1610834372 //Harvest
        ;
    }

    registerEmail(email) {
        if (!this.active) {
            return;
        }
        try {
            carma.ac.registerEmail(email);
        } catch (e) {
            console.log(e);
        }

    }

    addMetaData(metaData: SymplifyMetaData) {
        if (!this.active) {
            return;
        }
        try {
            //carma.ac.addMetaData(metaData);
            for (const [key, value] of Object.entries(metaData)) {
                const t = {};
                t[key] = value;
                console.log(t);
                carma.ac.addMetaData(t);
            }
        } catch (e) {
            console.log(e);
        }

    }

    addToCart(item: SymplifyItem) {
        if (!this.client || !this.active) {
            return;
        }
        try {
            carma.ac.addToCart(item);
        } catch (e) {
            console.log(e);
        }

    }

    addToCartPrice(priceWebs: PriceWeb[] | CartPrice[] | any[]) {
        if (!environment.production) {
            return;
        }
        if (!(environment.systemValues === 'tkp')) {
            return;
        }
        if (!this.client || !this.active) {
            return;
        }
        try {
            let metaData: SymplifyMetaData = {};
            priceWebs.forEach(value => {
                if (this.isActiveShow(value.showId)) {
                    if (value.pageUrl && value.pageUrl.length > 10 && value.title) {
                        const item: SymplifyItem = {
                            id: String(value.id),
                            amount: (value.price + value.serviceCharges)/100,
                            quantity: value.qty,
                            description: value.name,
                            name: value.name
                        };


                        this.addToCart(item);
                        metaData = {
                            image_url: value.imageUrl,
                            sale_url: value.pageUrl,
                            lieu_nom: value.venue,
                            show_date: value.date,
                            show_title: value.title
                        };


                    }
                }
            });
            if (Object.keys(metaData).length > 0) {
                this.addMetaData(metaData);
                this.registerEmail(this.client.email);
            }

        } catch (e) {
            console.log(e);
        }
    }

    removeFromCart(item: SymplifyItem) {
        if (!this.active) {
            return;
        }
        try {
            carma.ac.removeFromCart(item);
        } catch (e) {
            console.log(e);
        }

    }

    removeFromCartPrice(cartPrices: [CartPrice]) {
        if (!this.active) {
            return;
        }
        try {
            cartPrices.forEach(value => {
                const item: SymplifyItem = {
                    id: String(value.id),
                    amount: (value.price + value.serviceCharges)/100,
                    quantity: value.qty,
                    description: value.name,
                    name: value.name
                };
                this.removeFromCart(item);
            });
        } catch (e) {
            console.log(e);
        }

    }

    setCart(cartData, totalAmount) {
        if (!this.active) {
            return;
        }
        try {
            carma.ac.setCart(cartData, totalAmount);
        } catch (e) {
            console.log(e);
        }

    }

    resetCart() {
        if (!this.active) {
            return;
        }
        try {
            carma.ac.resetCart();
        } catch (e) {
            console.log(e);
        }

    }

    purchaseCompleted() {
        if (!this.active) {
            return;
        }
        try {
            carma.ac.purchaseCompleted();
        } catch (e) {
            console.log(e);
        }

    }
}
