import {Directive, forwardRef, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';


@Directive({
    selector: '[twSpecialCharactersValidator]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: forwardRef(() => SpecialCharactersValidatorDirective), multi: true}
    ]
})
export class SpecialCharactersValidatorDirective implements Validator {
    regExpForSpecialCharacterValid = /[!@#$%^&*]/;
    @Input() regExpForSpecialCharacter = /[^a-zA-Z\d!@#$%^&*]/;

    constructor() {
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const v: string = control.value;
        if (this.regExpForSpecialCharacter.test(v)) {
            let t = '';
            console.log(this.regExpForSpecialCharacter.exec(v))
            for (let i = 0; i < v.length; i++) {
                const caractere = v.charAt(i);
                let ct=this.regExpForSpecialCharacter.exec(caractere);
                if (ct) {
                    ct[0] ="<span class='tw-form-error-car'>"+ct[0]+"</span>";
                    if(i === v.length-1){
                        t = t + ct[0] ;
                    }else {
                        t = t + ct[0] +"  ";
                    }

                }
            }
            console.log(t)
            return <ValidationErrors>{
                notAcceptSpecialCharacter:t
            };
        }
        if (!this.regExpForSpecialCharacterValid.test(v)) {
            return <ValidationErrors>{
                minOnSpecialCharacter: 'Include specialCharactersValidatorDirective Characters'
            };
        }
        return null;

    }

}
