<div class="filter-page-container">
  <div style="" class="search">

        <mat-form-field style="color:var(--theme-primary-300);font-size: 17px;
                            width: 100%;max-width: 500px ">
      <mat-icon matPrefix>search</mat-icon>
      <mat-label>Search</mat-label>
      <input type="tel" matInput placeholder="artiste, salle , événement" [(ngModel)]="search" name="search">

    </mat-form-field>
  </div>
  <div class="filter-page-border select-province">
    <button mat-flat-button [matMenuTriggerFor]="menuProvinces" style=" background: transparent;">
      @if (provinceSelected && provinceSelected.ab) {
        {{provinceSelected.ab}}
      }
      @if (!provinceSelected) {
        {{'client.address.province' | translate}}
      }

      <mat-icon>
        expand_more
      </mat-icon>
    </button>
    @if (postal ) {
      <div class="tw-flex tw-flex-flow-row-wrap"
        style="justify-content: space-around"
        >
        <h3 style="text-transform: uppercase">
          {{postal}}
        </h3>
      </div>
    }
  </div>


  <mat-menu #menuProvinces style="background: red" [overlapTrigger]="true" classList="test-menu">
        <mat-form-field style="color:var(--theme-primary-300);font-size: 17px;
                            max-width: 500px;margin: 20px ">
      <!--            <mat-icon matPrefix>search</mat-icon>-->
      <span matPrefix class="material-symbols-outlined">
        location_on
      </span>
      <mat-label>{{'client.address.postal' | translate}}</mat-label>
      <input
        name="postal"
        pattern="^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?$"
        type="tel" matInput placeholder="Exp:H0N"
        style="text-transform: uppercase"
        #postalRef="ngModel"
        [(ngModel)]="postal"

        (click)="$event.stopPropagation()"


        >
      <button matSuffix mat-raised-button color="primary"
      style="text-transform: uppercase">{{'title.apply'| translate}}</button>
      <mat-error>
        <tw-form-error-message [errors]="postalRef.errors"></tw-form-error-message>
      </mat-error>
    </mat-form-field>

    @for (st of provinces; track st) {
      <div class="tw-hover-menu" style="width: 300px;margin: 0 10px">
        <button class="tw-btn-menu" (click)="selectProvince(st)"
          [ngClass]="st.ab === provinceSelected?.ab ? 'tw-btn-menu-active':''">
          @if (translate.currentLang !== 'fr') {
            {{st.nameEn}}
          }
          @if (translate.currentLang === 'fr') {
            {{st.nameFr}}
          }
        </button>
      </div>
    }
  </mat-menu>

  <div class="filter-page-border date-range-select">

    <tw-date-range-select-advanced [_styleDate]="2" [hasButton]="false" (rangeDate)="getDates($event)"
      [matInputStyle]="{fontSize:'17px'}"
    ></tw-date-range-select-advanced>
  </div>


  <div style="display: flex;flex-direction: row;align-items: stretch;">
    <button style="width: 100%;height: -webkit-fill-available;" mat-stroked-button color="primary" class="tw-btn-hover-transition btn-search"
      (click)="searchEvent()"
    > {{ "title.search" | translate}} </button>
  </div>


</div>
