import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SessionLocaleService} from './session-locale.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PermissionUserService {

    constructor(private http: HttpClient,
                private sessionLocaleService: SessionLocaleService) {
    }

    getPermission(): Observable<any> {
        return this.http.get<any>('v1/refresh-tokens/permission');
    }

    userRight(Right, per): boolean {
        try {
            const dd = atob(per);
            let s = '';
            for (let i = 0; i < dd.length; i++) {
                let b = parseInt(String(dd.charCodeAt(i)), 10).toString(2);
                while (b.length !== 8) {
                    b = '0' + b;
                }
                s += b.split('').reverse().join('');
            }
            return (s.charAt(Right) === '1');
        } catch (err) {
            return false;
        }
    }
}
