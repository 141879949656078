import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { PageLoadComponent } from './page-load/page-load.component';



@NgModule({
    declarations: [
        PageLoadComponent
    ],
    exports: [
        PageLoadComponent
    ],
    imports: [
        CommonModule,
        NgOptimizedImage
    ]
})
export class PageLoadModule { }
