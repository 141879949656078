@if (appendToInput) {
  <div>
    <ng-container *ngTemplateOutlet="timepickerOutlet"></ng-container>
  </div>
} @else {
  <ng-container *ngTemplateOutlet="timepickerOutlet"></ng-container>
}
<ng-template #timepickerOutlet>
  <ng-content></ng-content>
</ng-template>


