export enum ImageSizeEnum {

    SMALL = 'SMALL',
    BIG = 'BIG',
    BANNER = 'BANNER',
    SIZE_1920X525 = 'SIZE_1920X525',
    SIZE_1920X525_FR = 'SIZE_1920X525_FR',
    SIZE_728X525 = 'SIZE_728X525',
    SIZE_728X525_FR = 'SIZE_728X525_FR',

    SIZE_825X300 = 'SIZE_825X300',
    SIZE_825X300_FR = 'SIZE_825X300_FR',

    SIZE_400X250 = 'SIZE_400X250',
    SIZE_400X250_FR = 'SIZE_400X250_FR'
}
