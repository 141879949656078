import {Component, Input, OnInit} from '@angular/core';
import {CartService} from '../../../shared/services/cart.service';
import {Tax} from '../../../models/tax';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'tw-cart-tax-info-price',
    templateUrl: './cart-tax-info-price.component.html',
    styleUrls: ['./cart-tax-info-price.component.css']
})
export class CartTaxInfoPriceComponent implements OnInit {

    @Input() idEvent;
    @Input() idSubscription;
    @Input() idMember;
    @Input() idPrice;
    tax: Tax;

    constructor(private cartService: CartService, protected translate: TranslateService) {
    }

    ngOnInit() {
        if (this.idSubscription) {
            this.cartService.getSubscriptionTax(this.idSubscription, this.idPrice).subscribe(value => {
                this.tax = value;
            });
        } else if (this.idMember) {
            this.cartService.getMemberTax(this.idMember).subscribe(value => {
                this.tax = value;
            });
        } else {
            this.cartService.getEventTax(this.idEvent, this.idPrice).subscribe(value => {
                this.tax = value;
            });
        }
    }

}
