<!--https://lh3.googleusercontent.com/a/ALm5wu2K9obAZRsGcBMLw70TBaq6WrBRlJuIc1IohiHbTQ=s36-p-k-no-mo-->

<div class="mat-typography">


  <mat-drawer-container class="sdb-container" [hasBackdrop]="false">
    <mat-drawer   #drawer class="sdb-sidenav" mode="over" style="height: 94vh;background: var(--theme-primary-500)">
      <!--            <div class="tw-flex tw-row">-->
      <!--                <div style="margin: auto;width: 100px;text-align: center">-->
      <!--                    <div class="tw-btn-hover-cancel" (click)="drawer.toggle()">-->
      <!--                        <mat-icon class="icon-close">close</mat-icon>-->
    <!--                    </div>-->
  <!--                </div>-->

  <!--                <a (click)="goToRoute('')">-->
  <!--                    <img-->
  <!--                        src="https://ticketpro.s3.us-east-1.amazonaws.com/image/2022/logo/1-logo-regulier-regular/svg/logo-horizontal-couleur-color-RGB.svg"-->
  <!--                        alt="" width="200" style="margin-top: 10px;">-->
<!--                </a>-->
<!--            </div>-->
<div style="height: 16px;background:var(--theme-primary-50) " >

</div>


<nav class="menu">

  <div class="tw-column">


    @for (menu of menusFilter; track menu; let i = $index) {
      <div
        [ngClass]="menu.reverse ? 'tw-btn-list-menu-reveres':'tw-btn-list-menu' "
        >
        <ng-container>
          <button class="tw-animated-zoomIn" [ngClass]="menu.reverse ? 'tw-btn-menu-reveres':'tw-btn-menu' "
            (click)="goToRoute(menu.url)"  style="--data-x-animated:{{i}}" >
            <!--<mat-icon>voicemail</mat-icon>-->
            <!--<img src="./assets/resources/svg/movie-tickets.svg" style="height: 20px;" alt="cartlogo">-->
            <span>{{ menu.translate | translate }}</span>
          </button>
          <mat-divider [ngClass]="menu.reverse ? 'tw-divider-reveres':'' "></mat-divider>
        </ng-container>
      </div>
    }
    @if (configWeb.languages.length> 1) {
<div class="btn-lang
tw-btn-list-menu-reveres
">
        <tw-langs [languageSupported]="configWeb.languages"></tw-langs>
      </div>
    }
    <mat-divider class="tw-divider-reveres"></mat-divider>

    <div style="height: 80px ;text-align: center;background: var(--theme-primary-500)" class="">
      <a class="tw-primary" href="https://www.facebook.com/Ticketpro.ca" target="_blank">
        <img alt="Facebook"
          src="https://ticketpro.s3.us-east-1.amazonaws.com/image/2022/sociaux/icon_facebook_white.svg"
          width="45">
      </a>
      <a class="tw-primary" href="https://twitter.com/Ticketpro_Ca" target="_blank">
        <img alt="Twitter"
          src="https://ticketpro.s3.us-east-1.amazonaws.com/image/2022/sociaux/icon_twitter_white.svg"
          width="45">
      </a>
      <a class="tw-primary" href="mailto:info@ticketpro.ca" target="_blank">
        <img alt="Email"
          src="https://ticketpro.s3.us-east-1.amazonaws.com/image/2022/sociaux/icon_mail_white.svg"
          width="45">
      </a>
    </div>

  </div>


</nav>

</mat-drawer>
<mat-drawer-content>
  <mat-toolbar color="primary"  class="toolbar-menu ">
    <!--        <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">-->
    <!--            <mat-icon>menu</mat-icon>-->
  <!--        </button>-->
  <mat-toolbar-row>

    <div class="menu-icon"  [ngClass]="drawer.opened ? 'active':''" (click)="drawer.toggle()" >
      <div class="line-1 "></div>
      <div class="line-2 "></div>
      <div class="line-3 "></div>
    </div>
    <!--                    <a mat-button (click)="drawer.toggle()" *ngIf="!drawer.opened">-->
    <!--                        &lt;!&ndash;                        <mat-icon  class="tw-icon-big" style="color:var(&#45;&#45;theme-primary-900) ">menu</mat-icon>&ndash;&gt;-->
    <!--                        <span class="material-symbols-outlined tw-icon-big" style="color:var(&#45;&#45;theme-primary-50) ">-->
    <!--menu-->
  <!--</span>-->
<!--                    </a>-->
<!--                    <a mat-button (click)="drawer.toggle()" *ngIf="drawer.opened">-->
<!--                        &lt;!&ndash;                        <mat-icon  class="tw-icon-big" style="color:var(&#45;&#45;theme-primary-900) ">menu</mat-icon>&ndash;&gt;-->
<!--                        <span class="material-symbols-outlined tw-icon-big" style="color:var(&#45;&#45;theme-primary-50) ">-->
<!--close-->
<!--</span>-->
<!--                    </a>-->

<a class='icon-tkp-menu-a' (click)="goToRoute('')">
  <div class="icon-tkp-menu"
  ></div>
</a>

<div ></div>
<span class="tw-spacer"></span>
<!--        <mat-form-field    appearance="outline" style="width: 100px">-->
<!--            <mat-label>{{'client.address.province' | translate}}</mat-label>-->

<!--            <mat-select >-->
<!--                <mat-option *ngFor="let st of provinces" [value]="st.ab">-->
<!--                    <span *ngIf="translate.currentLang !== 'fr'">{{st.nameEn}}</span>-->
<!--                    <span *ngIf="translate.currentLang === 'fr'">{{st.nameFr}}</span>-->
<!--                </mat-option>-->
<!--            </mat-select>-->
<!--        </mat-form-field >-->

<!--        <button mat-flat-button color="primary" [matMenuTriggerFor]="menuProvinces">-->
<!--            <ng-container *ngIf="provinceSelected && provinceSelected.ab">-->
<!--                {{provinceSelected.ab}}-->
<!--            </ng-container>-->
<!--            <ng-container *ngIf="!provinceSelected">-->
<!--                {{'client.address.province' | translate}}-->
<!--            </ng-container>-->

<!--                    <mat-icon>-->
<!--                        expand_more-->
<!--                    </mat-icon>-->
<!--        </button>-->
<!--        <mat-menu #menuProvinces style="background: red" [overlapTrigger]="true" classList="test-menu">-->
<!--                            <div *ngFor="let st of provinces" class="tw-hover-menu" style="width: 300px;margin: 0 10px">-->
<!--                                <button  class="tw-btn-menu"  (click)="selectProvince(st)">-->
<!--                                    <ng-container *ngIf="translate.currentLang !== 'fr'">{{st.nameEn}}</ng-container>-->
<!--                                    <ng-container *ngIf="translate.currentLang === 'fr'">{{st.nameFr}}</ng-container>-->
<!--                                </button>-->
<!--                            </div>-->
<!--        </mat-menu>-->
@if (showCart) {
  <div class="btn-cart"
                         style="

                        z-index: 1;
                       margin-top: 11px;
                        border-right: 1px var(--theme-primary-400) solid;
                       "
    >
    <tw-app-cart [smallCart]="true"></tw-app-cart>
  </div>
}
@if (configWeb.languages.length> 1) {
  <div style="text-align: right;z-index: 2" class="btn-lang">
    <tw-langs [languageSupported]="configWeb.languages"></tw-langs>
  </div>
}
<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>account_circle</mat-icon>
</button>

<mat-menu #menu style="background: red" [overlapTrigger]="true" classList="test-menu">

  @for (menu of menusAccountFilter; track menu; let j = $index) {
    <div class="tw-hover-menu" style="width: 300px;margin: 0 10px"
      >
      <ng-container>
        <button mat-button class="tw-btn-menu tw-animated-zoomIn" style="--data-x-animated:{{j}}"
          (click)="goToRoute(menu.url)">
          <!--<mat-icon>voicemail</mat-icon>-->
          <!--<img src="./assets/resources/svg/movie-tickets.svg" style="height: 20px;" alt="cartlogo">-->
          {{ menu.translate | translate }}
        </button>
      </ng-container>
      <!--                <ng-container *ngIf="menu.url === 'login'">-->
      <!--                    <tw-login-client></tw-login-client>-->
    <!--                </ng-container>-->
  </div>
}
</mat-menu>


</mat-toolbar-row>
<mat-toolbar-row style="padding: 0;height: auto">
                    <div  style="border-top-left-radius:16px ;
            border-top-right-radius:16px ;
            display: flex; background:var(--theme-primary-50);color: var(--theme-primary-500);width: 100%;align-items: center">





    <!--                <span class="tw-spacer">-->
    <!--                          <div style="text-align: center">-->
    <!--                         <a (click)="goToRoute('')">-->
    <!--                                                             <img-->
    <!--                                                                 src="https://ticketpro.s3.us-east-1.amazonaws.com/image/2022/logo/1-logo-regulier-regular/svg/logo-horizontal-couleur-color-RGB.svg"-->
    <!--                                                                 alt="" width="190" >-->
  <!--                                                       </a>-->

<!--                          </div>-->
<!--                    </span>-->
<div class="filter-pages-events-toolbar">
  <tw-filter-pages-events></tw-filter-pages-events>
</div>


</div>

</mat-toolbar-row>


</mat-toolbar>
<div class="sdb-sidenav-content">
  <div class="tkp-app-body" style="min-height: 85vh">
    <router-outlet></router-outlet>
  </div>
</div>
</mat-drawer-content>


</mat-drawer-container>
<div style=" background-color: var(--theme-primary-50);">
  <tw-button-floating-order
    nameUnique="main-app-footer-button"
  ></tw-button-floating-order>
  <div id="formNewsletterContain" style="background-color:var(--theme-primary-50); width: 100%; height: auto;">
    <div class="FormNewsletter">
      <div  style="font-weight: 800">
        @if (translate.currentLang === 'fr') {
          <h2 class="mat-h2" style="text-transform: uppercase;color:red " > Inscription</h2>
          <h2 class="mat-h2" style="text-transform: uppercase;color:var(--theme-primary-500) " > à notre infolettre   </h2>
          <p style="">Préventes, des offres promotionnelles et des recommandations d'événements</p>
        }
        @if (translate.currentLang !== 'fr') {
          <h1 style="text-transform: uppercase;color:red " > Inscription</h1>
          <h1  style="text-transform: uppercase;color:var(--theme-primary-500) " > à notre infolettre   </h1>
          <p
            class=""
          style="color:var(--theme-primary-500)">Préventes, des offres promotionnelles et des recommandations d'événements</p>
        }

      </div>
      <!--            <div style="text-align: left;text-transform: uppercase ">-->
      <!--                <h2  style="color: red"><span>Inscription</span><br>-->
      <!--                    <span style="color: #121944">à notre infolettre</span></h2>-->
      <!--                <p>Préventes, des offres promotionnelles et des recommandations d'événements</p>-->
    <!--            </div>-->

    <form  class="formNewsletter-ct"
      >



      <!--                <input type="email" id="" placeholder="Courriel*" name="email"-->
      <!--                       oninvalid="this.setCustomValidity('Veuiller remplir cette section.')">-->

      <ng-container>
        <iframe  class="iframe-infol"   src="https://caimgs.s3-ca-central-1.amazonaws.com/pre-rendered/f94f1d0da30f93689bf0ca0f5d08bedd7eee503eb82c6f58ca01a1ce0548a283.html"
        style="width: 100%; " scrolling="yes" marginwidth="0" marginheight="0" frameborder="0" vspace="0" hspace="0"></iframe>
      </ng-container>
      @if (false) {
        <mat-form-field class="tw-input-width" appearance="outline" style="">
          <mat-label>{{'client.email' | translate}}</mat-label>
          <input type="email" matInput placeholder=""
            ngModel required name="lastname" #lastname="ngModel">
          <mat-error>
            <tw-form-error-message [errors]="lastname.errors"></tw-form-error-message>
          </mat-error>
        </mat-form-field>
        <mat-form-field style="margin: 0 10px"
          appearance="outline">
          <mat-label>Choisir votre langue*</mat-label>
          <mat-select   >
            <mat-option value="" disabled="" selected="" hidden="">Choisir votre langue*</mat-option>
            <mat-option value="fr">Français</mat-option>
            <mat-option value="en">English</mat-option>
            <mat-option value="fr, en">Les 2 langues**</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline" style="margin: 0 10px">
          <mat-label>Choisir votre province*</mat-label>
          <mat-select   >
            @for (ct of provinces; track ct) {
              <mat-option [value]="ct">
                @if (translate.currentLang=== 'fr') {
                  <span > {{ct.nameFr}}</span>
                }
                @if (translate.currentLang !== 'fr') {
                  <span > {{ct.nameEn}}</span>
                }
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field  appearance="outline" style="margin: 0 10px">
          <mat-label>{{'client.address.city' | translate}}</mat-label>
          <input type="email" matInput placeholder=""
            ngModel required name="city" #city="ngModel">
          <mat-error>
            <tw-form-error-message [errors]="city.errors"></tw-form-error-message>
          </mat-error>
        </mat-form-field>
        <mat-form-field  appearance="outline" style="margin: 0 10px">
          <mat-label>{{'client.address.postal' | translate}}</mat-label>
          <input type="email" matInput placeholder=""
            ngModel required name="postal" #postal="ngModel">
          <mat-error>
            <tw-form-error-message [errors]="postal.errors"></tw-form-error-message>
          </mat-error>
        </mat-form-field>
        <button class="tw-btn-hover-transition" mat-stroked-button color="primary">
          Envoye
        </button>
        <div class="tw-flex tw-column" style="color: #666">
          <span class="mat-small">*information obligatoire
          </span>
          <span class="mat-small">  signifie pas que vous allez recevoir systématiquement les infolettres en double, mais selon la langue de
          l'événement.</span>
        </div>
      }



    </form>


  </div>
</div>

<div class="footer">
  <div class="footerContain">
    <div class="footerColumn footerTkp"><a href="https://www.ticketpro.ca/"
      title="Billetterie - TICKETPRO.ca Canada"><img
      src="https://ticketpro.s3.us-east-1.amazonaws.com/image/2022/logo/1-logo-regulier-regular/svg/logo-horizontal-eniter-blanc-all-white.svg"
    alt="" width="200"></a></div>
    <div class="footerColumn contactNumber">
      <ul>
        <li class="titreCorpoArea">
          @if (translate.currentLang === 'fr') {
            <span> Service à la clientèle</span>
          }
          @if (translate.currentLang !== 'fr') {
            <span>Customer Service </span>
          }
        </li>
        <li>Québec - 514 790-1111 / 866 908-9090</li>
        <li>Canada atlantique - 888 311-9090</li>
        <li>Ontario - 416 645-9090 / 888 655-9090</li>
        <li>Ouest du Canada - 888 655-9090</li>
      </ul>
    </div>
    <div class="footerColumn sitemap">
      <ul>
        <li><a title="Points de vente" href="/en/pos">
          @if (translate.currentLang === 'fr') {
            <span>Points de vente </span>
          }
          @if (translate.currentLang !== 'fr') {
            <span>Ticketpro Outlets </span>
          }

        </a></li>
        <li><a title="Salles de spectacles" href="/en/locations">

          @if (translate.currentLang === 'fr') {
            <span>Salles de spectacles </span>
          }
          @if (translate.currentLang !== 'fr') {
            <span>Venues </span>
          }</a></li>
          <li><a title="Ticketpro Canada" href="http://ticketprocanada.ca/fr/index.html" target="_blank">
            @if (translate.currentLang === 'fr') {
              <span> Solutions
              de billetterie</span>
            }
            @if (translate.currentLang !== 'fr') {
              <span> Ticketing Solutions</span>
            }
          </a></li>
          <li><a title="Carrière" href="/en/static/careers">
            @if (translate.currentLang === 'fr') {
              <span> Carrière</span>
            }
            @if (translate.currentLang !== 'fr') {
              <span> Career</span>
            }
          </a></li>
        </ul>
      </div>
      <div class="footerColumn sitemap2">
        <ul>
          <li><a href="/en/static/medias">
            @if (translate.currentLang === 'fr') {
              <span>Médias </span>
            }
            @if (translate.currentLang !== 'fr') {
              <span>Medias </span>
            }</a></li>
            <li><a href="/en/static/faq">FAQ</a></li>
            <li><a title="Contact" href="/en/static/contact">Contact</a></li>
            <!--                        <li><a title="Contact" href="">English</a></li>-->
          </ul>
        </div>
        <div class="footerColumn social">
          <ul>
            <li><a href="https://www.facebook.com/Ticketpro.ca" target="_blank"><img alt="Facebook"
              src="https://ticketpro.s3.us-east-1.amazonaws.com/image/2022/sociaux/icon_facebook_white.svg"
            width="60"></a> <a
            href="https://twitter.com/Ticketpro_Ca" target="_blank"><img alt="Twitter"
            src="https://ticketpro.s3.us-east-1.amazonaws.com/image/2022/sociaux/icon_twitter_white.svg"
          width="60"></a> <a
          href="mailto:info@ticketpro.ca"  target="_blank"><img alt="Email"
          src="https://ticketpro.s3.us-east-1.amazonaws.com/image/2022/sociaux/icon_mail_white.svg"
        width="60"></a></li>
      </ul>
    </div>

  </div>
  <br clear="all">
  @if (translate.currentLang=== 'fr') {
    <div class="footerCopyrights"><a href="">© Ticketpro.ca 2003-2022&nbsp;Confidentialité</a> <a
  href="">&nbsp;&nbsp;&nbsp;&nbsp;Ticketpro.ca&nbsp;Accessibilité</a></div>
}
@if (translate.currentLang !== 'fr') {
  <div class="footerCopyrights">
    © Ticketpro.ca 2003-2022&nbsp;<a href="privacy.php">Confidentiality Police</a>&nbsp;-&nbsp;<a href="accessibility.php">Accessibility Policy</a>
  </div>
}
</div>
</div>
</div>




