<div [formGroup]="formGroupPhone" class="tw-flex tw-row-2" style="justify-content: center">
    <mat-form-field class="tw-input-width" appearance="outline" style="margin: 0 20px">
        <mat-label>{{'client.phone.telephone' | translate}}</mat-label>
        <input matInput required formControlName="number" autocomplete='tel' twMyNumberOnly>
        <mat-error>
            <tw-form-error-message [errors]="formGroupPhone.controls['number'].errors"></tw-form-error-message>
        </mat-error>
    </mat-form-field>
    <div class="tw-input-width" style="margin: 0 20px"></div>
</div>
