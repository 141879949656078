import {Component, Host, Input, OnChanges, OnInit, Optional} from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import {MatFormField} from '@angular/material/form-field';
import {FormErrorContainer} from '../../models/form-error-container';

@Component({
    selector: 'tw-form-error-message',
    templateUrl: './form-error-message.component.html',
    styleUrls: ['./form-error-message.component.css']
})
export class FormErrorMessageComponent implements OnInit, OnChanges {
    displayedFormErrors: {name:any,message:any,values:any[]}[] = [];
    @Input() customFormErrors: FormErrorContainer|any = {} as {
        required: string,
        minlength: string,
        maxlength: string,
        min: string,
        max: string,
        email: string,
        pattern: string,
        cardValidator: string,
        equalValidator: string,
        validateEmail: string,
        notAcceptSpecialCharacter:string,
        minlengthCharacters:string
    };
    @Input() errors: ValidationErrors | any;

    constructor(@Optional() @Host() parent: MatFormField) {
    }

    ngOnInit() {
    }

    getErrorMessage(errorKey: string) {
        if (this.customFormErrors[errorKey]) {
            // if error message is overridden in html tag with custom messages
            return (this.customFormErrors[errorKey]);
        } else if (this.errors[errorKey] && this.errors[errorKey].message) {
            // if it's a custom validation error with multiple possible messages
            return this.errors[errorKey].message;
        } else {
            return `formMsg.errors.${errorKey}`;
        }
    }

    ngOnChanges() {
        this.displayedFormErrors = [];

        if (this.errors) {
            if (this.errors.required) {
                const t =
                    {
                        name: 'required',
                        message: this.getErrorMessage('required'),
                        values: []
                    };

                this.displayedFormErrors.push(t);
                return;
            }
            for (let errorKey in this.errors) {
                console.log(this.errors[errorKey]);
                let val=[]

                if (this.errors[errorKey]) {
                    if(errorKey === "minlength"){
                        val = [this.errors[errorKey].requiredLength]
                        errorKey="minlengthCharacters";
                    }
                    if(errorKey === "maxlength"){
                        val = [this.errors[errorKey].requiredLength]
                        errorKey="maxlengthCharacters";
                    }
                    if(errorKey === "notAcceptSpecialCharacter"){
                        val = [this.errors[errorKey]]
                    }
                    const t = {
                        name:errorKey,
                        message: this.getErrorMessage(errorKey),
                        values: val
                    };
                    this.displayedFormErrors.push(t);
                }
            }
        }
    }
}
