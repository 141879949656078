/**
 * @deprecated utilse l'interface  le model de shared-lib/src/lib/models **/
export enum CreditCardProcessorTypeEnum {
    PAYMENTECH = 'PAYMENTECH',
    MONERIS = 'MONERIS',
    PAYPAL = 'PAYPAL',
    EXACT = 'EXACT',
    STRIPE = 'STRIPE',
    PAYSAFE = 'PAYSAFE',
    DO_NOT_USE = 'DO_NOT_USE',
    CARDCONNECT = 'CARDCONNECT',
    GLOBALPAYMENTS='GLOBALPAYMENTS'
}
