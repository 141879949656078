import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CalendarObserverService {
    private calendarSub = new Subject<any>();
    private dateSelected = new Subject<any>();
     pageResponse = new Subject<any>();

    constructor() {
    }

    watchCalendar(): Observable<any> {
        return this.calendarSub.asObservable();
    }

    watchDateCalendar(): Observable<any> {
        return this.dateSelected.asObservable();
    }
    addPageResponse(page) {
        return this.pageResponse.next({ date: new Date(), page: page });
    }

    addCalendarCurrent(date: Date, pageId) {
        this.calendarSub.next({ date: date, pageId: pageId });
    }

    addDateSelected(date: Date, pageId) {
        this.dateSelected.next({ date: date, pageId: pageId });
    }

}
