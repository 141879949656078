import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonFloatingOrderComponent } from './button-floating-order/button-floating-order.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
    declarations: [
        ButtonFloatingOrderComponent
    ],
    exports: [
        ButtonFloatingOrderComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        TranslateModule,
        MatButtonModule
    ]
})
export class ButtonFloatingOrderModule { }
