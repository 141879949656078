import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder,UntypedFormGroup} from '@angular/forms';

import {WebsiteSaleService} from '../../../shared/services/website-sale.service';
import {ConfigWebsite} from '../../../models/config-website';
import {ClientService} from '../../client.service';
import {MessageAction, MessageObserverService} from '../../../shared/services/message-observer.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ClientDetails} from '../../../models/client-details';
import {Address, EnumAddressType} from '../../../models/address';
import {Phone} from '../../../models/phone';
import {HttpParams} from '@angular/common/http';
import { ActionCartService } from '../../../shared/services/action-cart.service';

@Component({
    selector: 'tw-client-select-checkout-method',
    templateUrl: './client-select-checkout-method.component.html',
    styleUrls: ['./client-select-checkout-method.component.css']
})
export class ClientSelectCheckoutMethodComponent implements OnInit, AfterViewInit {
    guestForm: UntypedFormGroup;
    websiteSaleConfig: ConfigWebsite;
    showGuest = false;
    timeOut = true;
    load = true;
    clientRequest: ClientDetails;
    clientClone: any;
    isCompletedProfile: boolean;
    phoneClone: any;
    addressClone: any;

    constructor(protected fb: UntypedFormBuilder,
                private router: Router,
                private clientService: ClientService,
                private messageObserverService: MessageObserverService,
                public translate: TranslateService,
                public websiteSaleService: WebsiteSaleService,
               private actionCartService: ActionCartService,
    ) {
        this.websiteSaleConfig = this.websiteSaleService.getLocalConfig();
        this.guestForm = this.fb.group({
            address: this.fb.group({}),
            phone: this.fb.group({}),
            client: this.fb.group({})
        });
        if (this.websiteSaleConfig && this.websiteSaleConfig.flowStandard) {
            this.guestForm.addControl('address2', this.fb.group({}));
            this.guestForm.addControl('isSameAddress', this.fb.control(false));
            this.guestForm.get('isSameAddress').valueChanges.subscribe(res => {
               // console.log(res);
                if (res) {
                    this.guestForm.removeControl('address2');
                } else {
                    this.guestForm.addControl('address2', this.fb.group({}));
                }
            });
        }
        this.clientService.logoutClient(this.websiteSaleConfig.accountCode).subscribe(() => {

        });
        this.timeOut = this.actionCartService.cartTimeOut.value === -1;
        this.actionCartService.cartTimeOut.subscribe(value => {
           // console.log(value)
            this.timeOut = value === -1;
        });
    }

    ngOnInit() {
        if (this.websiteSaleConfig.clientAccount === 'NO') {
            const account = this.websiteSaleService.getLocalConfig();
            this.clientService.logoutClient(account.accountCode).subscribe(() => {

            });
        }
        this.guestForm.valueChanges.subscribe(val => {
            this.createClientRequest(val);
        });
    }

    get addressGroup() {
        return this.guestForm.get('address');
    }

    get address2Group() {
        return this.guestForm.get('address2');
    }

    get clientGroup() {
        return this.guestForm.get('client');
    }


    get phoneGroup() {
        return this.guestForm.get('phone');
    }

    addressChange(val) {

    }

    onSubmit() {
        this.load = false;

        const account = this.websiteSaleService.getLocalConfig();
        this.clientRequest.accountId = account.accountId;
        this.clientService.createClient(this.clientRequest).subscribe(() => {
            this.load = true;
            this.clientService.getClientSelf(new HttpParams().set('cache', '0')).subscribe(() => {
                this.load = true;
                this.router.navigate([this.translate.currentLang + '/client/middlewareClient'])
                    .then(value => {
                        this.load = true;
                    }).catch(error => {
                   // console.error(error);
                    this.load = true;
                    this.messageObserverService.addMessage(<MessageAction>{dialog: true, message: 'error router'});

                });
            }, error1 => {
                this.load = true;
                this.messageObserverService.addMessage(<MessageAction>{dialog: true, message: error1.error.error.message});
            });

        }, error1 => {
            this.load = true;
            this.messageObserverService.addMessage(<MessageAction>{dialog: true, message: error1.error.error.message});

        });
    }

    private createClientRequest(val: any) {
       // console.log(val)
        const clone = Object.assign({}, val);
        this.clientRequest = Object.assign({}, val.client);
        this.clientRequest.addresses = [];
        this.clientRequest.addresses.push(this.convertToAddress(clone.address));
        if (val.isSameAddress !== undefined) {
            if (val.isSameAddress) {
                this.clientRequest.addresses.push(this.convertToAddress(clone.address));
            } else {
                this.clientRequest.addresses.push(this.convertToAddress(clone.address2, EnumAddressType.COMPANY));
            }
        }
        this.clientRequest.phones = [];
        this.clientRequest.phones.push(this.convertToPhone(clone.phone));
    }

    convertToAddress(val, type ?: EnumAddressType): Address {
        const address = <Address>{};
       // console.log(val)
        if (val) {
            address.addressLine1 = val.addressLine1;
            address.addressLine2 = val.addressLine2;
            address.appartment = val.appartment;
            address.city = val.city;
            address.zipCode = val.zipCode;
            address.country = val._country || val.country;
            if (!type) {
                address.type = EnumAddressType.BILLING;
            } else {
                address.type = type;
            }

            if (address.country === 'CA') {
                address.province = val.statesCAD;
            }
            if (address.country  === 'US') {
                address.province = val.statesUS;
            }
            if (address.country  !== 'US' && address.country  !== 'CA') {
                address.province = val.statesWorld;
            }
        }
       // console.log(address);
        return address;
    }

    convertToPhone(val): Phone {
        const phone = <Phone>{};
        if (val) {
            phone.number = val.number;

        }
        return phone;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 20);
    }
}
