import {Directive, forwardRef, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
    selector: '[twLowercaseValidator]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: forwardRef(() => LowercaseValidatorDirective), multi: true}
    ]
})
export class LowercaseValidatorDirective implements Validator {
    @Input() regExpForLower = /^(?=.*[a-z])(.*)$/;

    constructor() {
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const v = control.value;
        if (!this.regExpForLower.test(v)) {
            return <ValidationErrors>{
                minOneLowerCase: 'Include Uppercase Characters:(e.g. abcdef )'
            };
        }
        return null;
    }

}
