// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
   // urlApi: 'https://www.c2tix.com/api/',
    //urlApi: 'https://www.jungleislandtickets.com/api/',
    urlApi: 'http://127.0.0.1:9001/',
   // urlApi: 'https://billetterie.feq-test.com/api/',
   // urlApi: 'https://staging.jungleislandtickets.com/api/',
   // urlApi: 'https://www.c2m-staging.com/api/',
    //urlApi: 'https://wlt-staging.com/api/',
    log: {
        active: true,
        NB: 0,
        random: 0
    },
    defaultLocale: 'en',
    basePath: '/',
    systemValues: 'tkp',
    urlGwt : 'http://127.0.0.1:8080',
    // production: false,
    // urlApi: 'http://127.0.0.1:9001/',
    // log: {
    //     active: true,
    //     NB: 0,
    //     random: 0
    // },
    // defaultLocale: 'en',
    // basePath: '/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
