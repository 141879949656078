import {Directive, forwardRef, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[twHasNumberValidator]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: forwardRef(() => HasNumberValidatorDirective), multi: true}
    ]
})
export class HasNumberValidatorDirective implements Validator {
    @Input() regExpForNumber = /^(?=.*[0-9])(.*)$/;
  constructor() { }

    validate(control: AbstractControl): ValidationErrors | null {
        const v = control.value;
        if (!this.regExpForNumber.test(v)) {
            return <ValidationErrors>{
                minNumber: 'Include number Characters:(e.g. 1256899 )'
            };
        }
        return null;
    }

}
