<div style="margin:0 auto;" >
  <div class="tw-flex tw-flex-flow-row-wrap" style="justify-content: center;position: relative">
    <form #user="ngForm" (ngSubmit)="authClient(user)">
      <mat-form-field class="tw-input-width" appearance="outline" style="margin: 0 20px">
        <mat-label>{{'client.email' | translate}}</mat-label>
        <input matInput ngModel required name="username"
          #username="ngModel">
        <mat-error>
          <tw-form-error-message [errors]="username.errors"></tw-form-error-message>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="tw-input-width" appearance="outline" style="margin: 0 20px">
        <mat-label>{{'client.password' | translate}}</mat-label>
        <input matInput type="password"
          ngModel required
          name="password"
          #password
          >
        <tw-show-password matSuffix [inputRef]="password"></tw-show-password>
        <mat-error>
          is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </form>

  </div>
  <div class="tw-flex tw-column" style="max-width: 641px;margin: 0 auto" >
    <div style="margin: 15px 0">
      <button class="tw-primary tw-button" mat-raised-button color="primary" (click)="authClient(user)"
        [disabled]="load && !user.valid "> {{ 'title.login' |
        translate }}
        @if (!load) {
          <div class="cssload-speeding-wheel"></div>
        }
      </button>
    </div>
    <div class="tw-flex tw-row-2" style="padding-bottom: 21px; border-bottom: 1px #00000038 solid; position: relative">
      <!--                <div style="margin-right:20px">-->
      <!--                    <mat-checkbox>Se souvenir de moi</mat-checkbox>-->
    <!--                </div>-->
    <div style="margin: 3px 20px" >
      <a color="primary"  (click)="goto('client-forgot-password')"  > {{ 'client.forgotPassword' | translate
      }}? </a>
    </div>
    <div>

      @if (!isCheckoutPage) {
        <a mat-button  color="primary"  (click)="goto('signup')"  > {{ 'title.signUp' | translate }} </a>
      }
    </div>
    @if (false) {
<div  style="position: absolute;background: white;right: 50%;
    top: 78%;" >
        Or
      </div>
    }
  </div>


  @if (false) {
<div   class="tw-flex tw-row-2" style=" width: 223px;
    margin: 10px auto;" >
      @if (clientService.hasGoogle) {
        <div style="flex-grow: 1">
          <button mat-icon-button (click)="signInWithGoogle()">
            <img src="./assets/resources/svg/google.svg" style="height: 39px;" alt="cart">
          </button>
        </div>
      }
      <div style="flex-grow: 1" >
        @if (clientService.hasFacebook) {
          <button mat-raised-button color="primary" class="tw-primary" (click)="signInWithFB()">
            <img src="./assets/resources/svg/facebook.svg" style="height: 39px;" alt="cart">   Continue with Facebook
          </button>
        }
      </div>
    </div>
  }
</div>
</div>




