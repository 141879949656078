@if (!isEditable) {
  <input class="timepicker-dial__control timepicker-dial__item"
    [ngClass]="{'active': isActive}"
    [ngModel]="time | timeLocalizer: timeUnit: true"
    (ngModelChange)="time = $event"
    [disabled]="disabled"
    (input)="updateTime()"
    (focus)="saveTimeAndChangeTimeUnit($event, timeUnit)"
    readonly
    [ngxMatTimepickerAutofocus]="isActive"
    >
} @else {
  <input class="timepicker-dial__control timepicker-dial__item timepicker-dial__control_editable"
    [ngClass]="{'active': isActive}"
    [ngModel]="time | ngxMatTimepickerParser: timeUnit | timeLocalizer: timeUnit : true"
    (ngModelChange)="onModelChange($event)"
    [disabled]="disabled"
    (input)="updateTime()"
    (focus)="saveTimeAndChangeTimeUnit($event, timeUnit)"
    [ngxMatTimepickerAutofocus]="isActive"
    (keydown)="onKeydown($event)"
    (keypress)="changeTimeByKeyboard($event)">
}

