import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PageSaleService } from '../page/services/page-sale.service';
import { PriceManagerService } from '../page/services/price-manager.service';

@Injectable({
    providedIn: 'root'
})
export class CanActiveCartGuard  {
    constructor(private pageService: PageSaleService, public priceManagerService: PriceManagerService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (this.pageService && this.pageService.page &&  this.pageService.page.sameNbTicketForAllItems) {
            if (this.priceManagerService.isValidToSamePriceForAllItems(this.pageService.page)) {
                return true;
            }
            return false;
        }
        return true;
    }

}
