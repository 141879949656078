import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListLocationWebComponent} from './list-location-web/list-location-web.component';
import {LocationWebViewComponent} from './location-web-view/location-web-view.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {TranslateModule} from '@ngx-translate/core';
import {PipeModule} from '../../../projects/shared-lib/src/lib/pipe';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';


@NgModule({
  declarations: [
      ListLocationWebComponent,
      LocationWebViewComponent
  ],
    imports: [
        CommonModule,
        MatProgressBarModule,
        TranslateModule,
        PipeModule,
        MatButtonModule,
        MatCardModule,

    ]
})
export class LocationWebModule { }
