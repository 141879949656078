<div class=" tw-margin-5">
  <form style="margin: auto;max-width: 800px" [formGroup]="profileForm" >
    <h2><b>{{'client.modifyProfile' | translate}}</b></h2>
    <div >
      <tw-client-form [formGroupClient]="clientGroup" [client]="client"></tw-client-form>
    </div>
    <!--<div *ngIf="false && isProfileGuest && configWebsite.clientAccount !== 'NO'" class="tw-padding-10 mat-elevation-z1">-->
    <!--<mat-form-field class="tw-input">-->
    <!--<input formControlName="password" type="password" matInput placeholder="{{'client.password' | translate}}" >-->
    <!--<mat-error>-->
    <!--<tw-form-error-message [errors]="profileForm.controls['password'].errors"></tw-form-error-message>-->
  <!--</mat-error>-->
<!--</mat-form-field>-->
<!--<mat-form-field class="tw-input">-->
<!--<input formControlName="cPassword" type="password" matInput placeholder="{{'client.passwordConfirm' | translate}}" >-->
<!--<mat-error>-->
<!--<tw-form-error-message [errors]="profileForm.controls['cPassword'].errors" ></tw-form-error-message>-->
<!--</mat-error>-->
<!--</mat-form-field>-->
<!--</div>-->
<div class="item-phone">
  <tw-phone-form [formGroupPhone]="phoneGroup" [phone]="phone"></tw-phone-form>
</div>
<div class="item-address ">
  <tw-address [formGroupAddress]="addressGroup" [address]="address"></tw-address>
</div>
<tw-client-newsletter #newsletterComponent [typeOfNewsletter]="newsletterType" ></tw-client-newsletter>
        <div style="
    min-height: 50px;
    width: 100%;
    margin: auto;
    text-align: right;
    padding-top: 20px;
    ">
  <button  class="tw-primary" mat-raised-button color="primary" type="submit" [disabled]="!profileForm.valid || !load" (click)="updateClient()" >{{ "formMsg.submit" | translate }}
    @if (!load) {
      <div class="cssload-speeding-wheel"></div>
    }
  </button>
</div>
<!--<pre>{{profileForm.value | json}}</pre>-->
<!--        <pre>{{client | json}}</pre>-->
<!--        <pre>{{this.client | json}}</pre>-->
<!--<pre>{{address | json}}</pre>-->
</form>

</div>
