import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActionButtonFloatingOrderEnum, ButtonFloatingOrderService} from '../button-floating-order.service';
import {NavigationEnd, Router} from '@angular/router';
import {Combo2State, PageCombo2Service} from '../../page/component/page-combo2/page-combo2.service';

@Component({
    selector: 'tw-button-floating-order',
    templateUrl: './button-floating-order.component.html',
    styleUrls: ['./button-floating-order.component.css']
})
export class ButtonFloatingOrderComponent implements OnInit, OnDestroy ,AfterViewInit{
    @Input() nameUnique;
    @Input() isDialog= false
    qtyReserved = 0;
    load = true;
    splitPrices = true;
    bestsSelect = true;
    total: number;
    _sub = [];
    combo2State: Combo2State;
    activeBtn = false;


    constructor(public translate: TranslateService,
                public pageCombo2Service: PageCombo2Service,
                public buttonFloatingOrderService: ButtonFloatingOrderService,
                public router: Router) {
    }



    ngOnDestroy(): void {
        this._sub.forEach(val => {
            val.unsubscribe();
        });
    }

    ngOnInit(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {

                setTimeout(() => {
                    const t = document.querySelectorAll('.btn-floating-order');
                    if (t && t.length < 2) {
                        const btFooterElm = document.getElementById('main-app-footer-button');
                        if (btFooterElm) {
                            btFooterElm.classList.add('main-app-footer-button-hide');
                            //this.qtyReserved = 0;
                            this.setQty(0);
                        }

                    }
                    //console.log(t.length, t);
                }, 2000);


            }

        });
        const a = this.buttonFloatingOrderService.action.asObservable().subscribe({
            next: (value) => {
                //console.log('=============================', value);
                if (value.name === ActionButtonFloatingOrderEnum.DATA) {
                    if (value.data.qtyReserved !== undefined) {
                        // this.qtyReserved = value.data.qtyReserved;
                        this.setQty(value.data.qtyReserved);
                    }
                    if (value.data.total !== undefined) {
                        this.total = value.data.total;
                    }
                    if (value.data.load !== undefined) {
                        this.load = value.data.load;
                    }
                    if (value.data.splitPrices !== undefined) {
                        this.splitPrices = value.data.splitPrices;
                    }
                    if (value.data.bestsSelect !== undefined) {
                        this.bestsSelect = value.data.bestsSelect;
                    }

                }

            }
        });
        const combo = this.pageCombo2Service.combo2StateSubject.asObservable().subscribe(value => {
            this.combo2State = value;
            this.setQty(this.qtyReserved);
        });
        this._sub.push(combo);

        this._sub.push(a);
    }
    ngAfterViewInit(): void {
        const pageCon = document.querySelector('tw-page-prices-leves-container');
        //console.log('======================================', pageCon);
        if (!pageCon) {
            const btFooterElm = document.getElementById('main-app-footer-button');
            btFooterElm.classList.add('main-app-footer-button-hide');
        }
    }

    setQty(qty) {
        this.qtyReserved = qty;
        this.activeBtn = this.qtyReserved > 0;
        if (this.combo2State && !this.combo2State.hasRightToGoOptional) {
            this.activeBtn = false;
        }
    }

    createHoldPage() {
        this.buttonFloatingOrderService.action.next({name: ActionButtonFloatingOrderEnum.HOLD,isDialog:this.isDialog});

    }

    closeDialog() {
        this.buttonFloatingOrderService.action.next({name: ActionButtonFloatingOrderEnum.CLOSE});
    }
}
