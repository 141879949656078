@if (cartElements) {
  <div class="tw-max-width-6 tw-cart-summary-container">
    @for (el of cartElements; track el; let i = $index) {
      <div>
        @switch (el._type) {
          @case (el._type ==='combo') {
            <div>
              <tw-cart-header [cartElement]="el"></tw-cart-header>
              @for (combo of el.events; track combo) {
                <tw-cart-header [cartElement]="combo"></tw-cart-header>
                <tw-cart-detail [cartPrices]="combo.prices"></tw-cart-detail>
              }
            </div>
          }
          @default {
            <div style="margin-bottom: 5px" class="tw-max-width-6 mat-elevation-z1">
              <!--<ng-template ngFor let-pr [ngForOf]="el.prices" let-ip="index">-->
              <!--<tw-ga [price]="pr" [cartElement]="el" *ngIf="pr.ga"></tw-ga>-->
              <!--<tw-rs  [cartElement]="el" *ngIf="!pr.ga"></tw-rs>-->
              <!--<hr *ngIf="el.prices.length !== ip+1">-->
            <!--</ng-template>-->
            <tw-rs  [cartElement]="el" [isInCheckout]="isInCheckout"></tw-rs>
          </div>
        }
      }
    </div>
  }
  @if (showTax) {
    <tw-cart-tax-view [tax]="tax"></tw-cart-tax-view>
  }
</div>
}
