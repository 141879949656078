import { SeatJson } from './seat-json';
/**
 * @deprecated utilse l'interface  le model de shared-lib/src/lib/models **/
export interface TicketDetailToken {
  sodId: number;
  seat: SeatJson;
  mailCategory: MailCategory;
  saleId:any;
}
/**
 * @deprecated utilse l'interface  le model de shared-lib/src/lib/models **/
export enum MailCategory {
  E_TICKETS = 'E_TICKETS',
  SMARTPHONE = 'SMARTPHONE',
  MAIL = 'MAIL',
  WILLCALL = 'WILLCALL'
}
