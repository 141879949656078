import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild} from '@angular/core';
import {Cart} from '../../../models/cart';
import {ApplePayJS} from './schema';
import {HelpsService} from '../../../utils/helps.service';
import {environment} from '../../../../../../../environments/environment';
import {WebsiteSaleService} from '../../../../../../../src/modules/shared/services/website-sale.service';
import {TypePayment} from '../../../../../../../src/modules/models/config-website';
import {getAllowedCardNetworks} from '../../../utils/utilsShared';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';


/** https://github.com/kakismash/ng-wallet   https://github.com/sumitvekariya/angular-apple-pay/tree/master/src/app **/
@Component({
    selector: 'lib-apple-pay',
    templateUrl: './apple-pay.component.html',
    styleUrls: ['./apple-pay.component.scss']
})
export class ApplePayComponent implements AfterViewInit {

    @ViewChild('applePay', {static: false}) but: ElementRef;
    @Input() totalShippingSelected = 0;
    @Input() cart: Cart;
    @Output() applePay = new EventEmitter<ApplePayJS.ApplePayPayment>();
    private merchantIdentifier = environment.applePay.merchantIdentifier;
    activeBtn = false;
    typeModePayment: TypePayment;

    constructor(private renderer: Renderer2,
                private websiteSaleService: WebsiteSaleService,private http:HttpClient,public translate:TranslateService
    ) {
            this.typeModePayment = this.websiteSaleService.getLocalConfig().account;
    }


    async ngAfterViewInit() {

        HelpsService.injectScript('https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js').then(() => {

            if (this.isApplePaySession()) {
                if (ApplePaySession.canMakePayments()) {
                    this.activeBtn = true;
                    const promise = ApplePaySession.canMakePaymentsWithActiveCard(this.merchantIdentifier);
                    promise.then( (canMakePayments)=> {
                        console.info('canMakePayments =>('+canMakePayments +') merchantIdentifie =>'+ this.merchantIdentifier);
                        if(canMakePayments){
                            this.activeBtn = true;
                        }else {
                            // if (ApplePaySession.openPaymentSetup) {
                            //     // Display the Set up Apple Pay Button here…
                            //     ApplePaySession.openPaymentSetup(this.merchantIdentifier)
                            //         .then(function(success) {
                            //             alert('success'+success)
                            //             if (success) {
                            //
                            //             } else {
                            //                 // Open payment setup failed
                            //
                            //             }
                            //         })
                            //         .catch(function(e) {
                            //             // Open payment setup error handling
                            //             alert('errr')
                            //         });
                            // }
                        }
                      //  alert('canMak'+canMakePayments)
                    })


                }else {
                    console.info('canMakePayments null');
                }

            }

        });


    }

    isApplePaySession(): boolean {
        let toReturn = true;
        try {

            if (!ApplePaySession) {
                toReturn = false;
            }
            return toReturn;
        } catch (e) {
            return false;
        }
    }

    validateMerchant():Observable<any> {
        environment.applePay.initiativeContext = window.location.hostname;
        return this.http.post<any>('v1/pay/apple/create-session',environment.applePay);
    }

    onApplePayClick() {


        if (!(window as any).ApplePaySession) {
            return;
        }

        console.log('On Apple Pay Clicked');

        let paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
            countryCode: 'CA',
            currencyCode: 'CAD',
            supportedNetworks: getAllowedCardNetworks(this.typeModePayment),
            merchantCapabilities: ['supports3DS', 'supportsDebit', 'supportsCredit'],
            ...this.getAppleTransactionInfo()
        };

        //  paymentRequest = paymentRequestTest1;

        // Create ApplePaySession
        let session;

        try {
            // alert(new (window as any).ApplePaySession)
            session = new ApplePaySession(12, paymentRequest);

            session.onvalidatemerchant = async (event: any) => {
                try {
                    const merchantSession = await lastValueFrom(this.validateMerchant()) ;
                    session.completeMerchantValidation(JSON.parse(merchantSession.result));
                } catch (e) {
                    console.error(e)
                }
            };

             session.onpaymentmethodselected = (event: any) => {
            //     // Define ApplePayPaymentMethodUpdate based on the selected payment method.
            //     // No updates or errors are needed, pass an empty object.
                   console.info('onpaymentmethodselected', event);
            //     const update: ApplePayJS.ApplePayLineItem = {amount: '', label: ''};
                   session.completePaymentMethodSelection({
                       label: 'Total',
                       type: 'final',
                       amount: String((this.cart.tax.totalAfterTaxes + this.totalShippingSelected) / 100)
                   }, []);
            };

            // session.onshippingmethodselected = (event: any) => {
            //     // Define ApplePayShippingMethodUpdate based on the selected shipping method.
            //     // No updates or errors are needed, pass an empty object.
            //     alert('onshippingmethodselected');
            //     const update: ApplePayJS.ApplePayLineItem = {amount: '', label: ''};
            //     session.completeShippingMethodSelection(1, update, []);
            // };
            //
            // session.onshippingcontactselected = (event: any) => {
            //     // Define ApplePayShippingContactUpdate based on the selected shipping contact.
            //     const update = {
            //         status: 'STATUS_SUCCESS',
            //         newShippingMethods: {
            //             'label': 'Free Shipping',
            //             'detail': 'Arrives in 5 to 7 days',
            //             'amount': '0.00',
            //             'identifier': 'FreeShip'
            //         },
            //         newTotal: {
            //             'label': 'Free Shipping',
            //             'amount': '50.00',
            //             'type': 'final'
            //         },
            //         newLineItems: [{
            //             'label': 'Free Shipping',
            //             'amount': '50.00',
            //             'type': 'final'
            //         }]
            //     };
            //     alert('onshippingcontactselected');
            //     session.completeShippingContactSelection(update.status, [update.newShippingMethods], update.newTotal, update.newLineItems);
            //
            // };

            session.onpaymentauthorized = (event: any) => {
                // Define ApplePayPaymentAuthorizationResult
                const result = {
                    'status': ApplePaySession.STATUS_SUCCESS
                };

                session.completePayment(result.status);
                this.applePay.emit(event.payment);
                console.info('onpaymentauthorized'+JSON.stringify(event?.payment));
            };

            session.oncancel = (event: any) => {
                // Payment cancelled by WebKit
                console.info('oncancel', event);
            };

            session.begin();
        } catch (e) {
            console.error(e);
            //alert(JSON.stringify(paymentRequest));
        }


    }


    getAppleTransactionInfo(): { lineItems?: ApplePayJS.ApplePayLineItem[], total: ApplePayJS.ApplePayLineItem } {
        const cart: Cart = this.cart;
        const transactionInfo: { lineItems?: ApplePayJS.ApplePayLineItem[], total: ApplePayJS.ApplePayLineItem } = {
            total: {label: 'Total', type: 'final', amount: '0.00'}
        };
        transactionInfo.lineItems = [];
        if (cart?.tax) {
            if (cart.tax.subtotal) {
                const a: ApplePayJS.ApplePayLineItem = {
                    label: 'Subtotal',
                    type: 'final',
                    amount: String(cart.tax.subtotal / 100),
                };
                transactionInfo.lineItems.push(a);
            }
            if (cart.tax.tax1) {
                const a: ApplePayJS.ApplePayLineItem = {
                    label: cart.tax.descriptionTax1,
                    type: 'final',
                    amount: String(cart.tax.tax1 / 100),
                };
                transactionInfo.lineItems.push(a);
            }
            if (cart.tax.tax2) {
                const a: ApplePayJS.ApplePayLineItem = {
                    label: cart.tax.descriptionTax2,
                    type: 'final',
                    amount: String(cart.tax.tax2 / 100),
                };
                transactionInfo.lineItems.push(a);
            }
            if (cart.tax.tax3) {
                const a: ApplePayJS.ApplePayLineItem = {
                    label: cart.tax.descriptionTax3,
                    type: 'final',
                    amount: String(cart.tax.tax3 / 100),
                };
                transactionInfo.lineItems.push(a);
            }

            transactionInfo.total = {
                label: 'Total',
                type: 'final',
                amount: String((cart.tax.totalAfterTaxes + this.totalShippingSelected) / 100)
            };


        }
        return transactionInfo;

    }

}

export declare class ApplePaySession extends EventTarget {

    /**
     * Creates a new instance of the ApplePaySession class.
     * @param version - The version of the ApplePay JS API you are using.
     * @param paymentRequest - An Apple​Pay​Payment​Request object that contains the information that is displayed on the Apple Pay payment sheet.
     */
    constructor(version: number, paymentRequest: ApplePayJS.ApplePayPaymentRequest);

    /**
     * The requested action succeeded.
     */
    static readonly STATUS_SUCCESS: number;

    /**
     * The requested action failed.
     */
    static readonly STATUS_FAILURE: number;

    /**
     * The billing address is not valid.
     */
    static readonly STATUS_INVALID_BILLING_POSTAL_ADDRESS: number;

    /**
     * The shipping address is not valid.
     */
    static readonly STATUS_INVALID_SHIPPING_POSTAL_ADDRESS: number;

    /**
     * The shipping contact information is not valid.
     */
    static readonly STATUS_INVALID_SHIPPING_CONTACT: number;

    /**
     * The PIN information is not valid. Cards on the China Union Pay network may require a PIN.
     */
    static readonly STATUS_PIN_INCORRECT: number;

    /**
     * The maximum number of tries for a PIN has been reached and the user has been locked out. Cards on the China Union Pay network may require a PIN.
     */
    static readonly STATUS_PIN_LOCKOUT: number;

    /**
     * The required PIN information was not provided. Cards on the China Union Pay payment network may require a PIN to authenticate the transaction.
     */
    static readonly STATUS_PIN_REQUIRED: number;

    /**
     * A callback function that is automatically called when the payment UI is dismissed with an error.
     */
    oncancel: (event: ApplePayJS.Event) => void;

    /**
     * A callback function that is automatically called when the user has authorized the Apple Pay payment, typically via TouchID.
     */
    onpaymentauthorized: (event: ApplePayJS.ApplePayPaymentAuthorizedEvent) => void;

    /**
     * A callback function that is automatically called when a new payment method is selected.
     */
    onpaymentmethodselected: (event: ApplePayJS.ApplePayPaymentMethodSelectedEvent) => void;

    /**
     * A callback function that is called when a shipping contact is selected in the payment sheet.
     */
    onshippingcontactselected: (event: ApplePayJS.ApplePayShippingContactSelectedEvent) => void;

    /**
     * A callback function that is automatically called when a shipping method is selected.
     */
    onshippingmethodselected: (event: ApplePayJS.ApplePayShippingMethodSelectedEvent) => void;

    /**
     * A callback function that is automatically called when the payment sheet is displayed.
     */
    onvalidatemerchant: (event: ApplePayJS.ApplePayValidateMerchantEvent) => void;

    /**
     * Indicates whether or not the device supports Apple Pay.
     * @returns true if the device supports making payments with Apple Pay; otherwise, false.
     */
    static canMakePayments(): boolean;

    /**
     * Indicates whether or not the device supports Apple Pay and if the user has an active card in Wallet.
     * @param merchantIdentifier - The merchant ID received when the merchant enrolled in Apple Pay.
     * @returns true if the device supports Apple Pay and there is at least one active card in Wallet; otherwise, false.
     */
    static canMakePaymentsWithActiveCard(merchantIdentifier: string): Promise<boolean>;

    /**
     * Displays the Set up Apple Pay button.
     * @param merchantIdentifier - The merchant ID received when the merchant enrolled in Apple Pay.
     * @returns A boolean value indicating whether setup was successful.
     */
    static openPaymentSetup(merchantIdentifier: string): Promise<boolean>;

    /**
     * Verifies if a web browser supports a given Apple Pay JS API version.
     * @param version - A number representing the Apple Pay JS API version being checked. The initial version is 1.
     * @returns A boolean value indicating whether the web browser supports the given API version. Returns false if the web browser does not support the specified version.
     */
    static supportsVersion(version: number): boolean;

    /**
     * Aborts the current Apple Pay session.
     */
    abort(): void;

    /**
     * Begins the merchant validation process.
     */
    begin(): void;

    /**
     * Call after the merchant has been validated.
     * @param merchantSession - An opaque message session object.
     */
    completeMerchantValidation(merchantSession: any): void;

    /**
     * Call when a payment has been authorized.
     * @param status - The status of the payment.
     */
    completePayment(status: number): void;

    /**
     * Call after a payment method has been selected.
     * @param newTotal - An Apple​Pay​Line​Item dictionary representing the total price for the purchase.
     * @param newLineItems - A sequence of Apple​Pay​Line​Item dictionaries.
     */
    completePaymentMethodSelection(newTotal: ApplePayJS.ApplePayLineItem, newLineItems: ApplePayJS.ApplePayLineItem[]): void;

    /**
     * Call after a shipping contact has been selected.
     * @param status - The status of the shipping contact update.
     * @param newShippingMethods - A sequence of ApplePayShippingMethod dictionaries.
     * @param newTotal - An Apple​Pay​Line​Item dictionary representing the total price for the purchase.
     * @param newLineItems - A sequence of Apple​Pay​Line​Item dictionaries.
     */
    completeShippingContactSelection(
        status: any,
        newShippingMethods: ApplePayJS.ApplePayShippingMethod[],
        newTotal: ApplePayJS.ApplePayLineItem,
        newLineItems: ApplePayJS.ApplePayLineItem[]): void;


    /**  version 3
     * Call after a shipping contact has been selected.
     * @param update - version 3.

     */

    completeShippingContactSelection(
        update: ApplePayJS.ApplePayShippingContactUpdate
    ): void;

    /**
     * Call after the shipping method has been selected.
     * @param status - The status of the shipping method update.
     * @param newTotal - An Apple​Pay​Line​Item dictionary representing the total price for the purchase.
     * @param newLineItems - A sequence of Apple​Pay​Line​Item dictionaries.
     */
    completeShippingMethodSelection(status: number, newTotal: ApplePayJS.ApplePayLineItem, newLineItems: ApplePayJS.ApplePayLineItem[]): void;
}
