import {Component, OnInit} from '@angular/core';
import {ClientService} from '../../client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {WebsiteSaleService} from '../../../shared/services/website-sale.service';
import {MessageAction, MessageObserverService} from '../../../shared/services/message-observer.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {HttpParams} from '@angular/common/http';
import {FlexPassResponse} from '../../../models/flex-pass-response';
import {KeyServiceService, ModeKeyServeEnum} from '../../../key-server-container/key-service.service';

@Component({
    selector: 'tw-list-flex-pass',
    templateUrl: './list-flex-pass.component.html',
    styleUrls: ['./list-flex-pass.component.css']
})
export class ListFlexPassComponent implements OnInit {
    loadPage = true;
    listFlexPass: FlexPassResponse[] = [];

    constructor(private clientService: ClientService,
                private route: ActivatedRoute,
                private router: Router,
                private websiteSaleService: WebsiteSaleService,
                private messageObserverService: MessageObserverService,
                public translate: TranslateService,
                public dialog: MatDialog,
                public keyServiceService:KeyServiceService
    ) {


    }

    ngOnInit(): void {
        this.getListFlexPass();
    }

    getListFlexPass() {
        this.loadPage = false;

        this.clientService.listFlexPass(new HttpParams().set('cache', '0')).subscribe(value => {
            //console.log(value);
            this.listFlexPass = value;

           const list = this.keyServiceService.getKeysFromSession(ModeKeyServeEnum.FLEX_PASS)
            //console.log(list)
            if(list){
                const newList = list.filter(key=> value
                    .find(flex=>
                        String(flex.flexpassEventId) === String(key.id)) === undefined
                )
                newList.forEach(value1 => this.keyServiceService.deleteKeysAfterSale(value1,ModeKeyServeEnum.FLEX_PASS))
                //console.log(newList)
            }


            this.loadPage = true;

        }, error1 => {
            this.loadPage = true;
            // console.log(error1);
            this.messageObserverService.addMessage(<MessageAction>{ dialog: true, message: error1.error.error.message });
        });
    }

    goFlex(fp: FlexPassResponse) {
        fp.load = true;
        this.router.navigate([this.translate.currentLang + '/client/event/'+ fp.flexpassEventId +'/flex-pass/' + fp.id])
            .then(value => {
                fp.load = false;
            }).catch(error => {
            // console.error(error);
            fp.load = true;

            this.messageObserverService.addMessage(<MessageAction>{ dialog: true, message: 'error router' });

        });
    }
}
