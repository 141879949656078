import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionLocaleService } from './session-locale.service';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class SessionRedisService {

    constructor(private http: HttpClient,
                private sessionLocaleService: SessionLocaleService,
                protected route: ActivatedRoute,
                public cookieService: CookieService
    ) {
    }

    getXSessionToken(): Observable<any> {

        let ht = new HttpParams().set('cache', '0');
        //console.log(this.route.snapshot.queryParams);
        this.transformToAssocArray(window.location.search).forEach(value => {
            ht = ht.set(value.key, value.val);
            if (value.key === 'auto') {
                // this.removeSessionLocal();

            }
        });
        if (!ht.get('auto')) {
            const a = this.getAutoParam(window.location.search);
            //console.log(a);
            if (a && a.length === 1) {
                ht = ht.set('auto', a[0].val);
            }
        }
        //console.log('params ******* ====> ', ht);

        return this.http.post<any>('v1/session', {}, { params: ht })
            .pipe(
                map(s => {
                    this.addSessionLocal(s.sessionToken);
                    return s;
                })
            );
    }

    addSessionLocal(val) {
        //console.log('session add====>', val);
        SessionLocaleService.putSync('sessionToken', val, true);
    }

    removeSessionLocal() {
        SessionLocaleService.deleteByKey('sessionToken', true);
        this.cookieService.delete('X-Session-Token');
        this.cookieService.getAll()
    }

    getXSessionLocal(val?): string {

        const session = SessionLocaleService.getSync('sessionToken', true);
        //console.log('session get ====> ------------------', val, session);
        return session;
    }

    transformToAssocArray(prmstr: string): any[] {

        const params = [];
        if (!prmstr) {
            return params;
        }
        prmstr = prmstr.replace('?', '');

        const prmarr = prmstr.split('&');
        for (let i = 0; i < prmarr.length; i++) {
            const tmparr = prmarr[i].split('=');
            if (tmparr && tmparr.length === 2) {
                params.push({ key: String(tmparr[0]), val: tmparr[1] });
            }
        }
        return params;
    }

    getAutoParam(prmstr): any[] {
        const params = [];
        if (!prmstr) {
            return params;
        }
        prmstr = prmstr.replace('?', '');

        const prmarr = prmstr.split('&');
        for (let i = 0; i < prmarr.length; i++) {
            const tmparr = prmarr[i].split('auto=');
            if (tmparr && tmparr.length === 2) {
                params.push({ key: String(tmparr[0]), val: tmparr[1] });
            }
        }
        return params;
    }
}
