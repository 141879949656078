import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { RedirectUrlService } from '../../../app/redirect-url.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Directive({
    selector: '[twClickRedirectUrl]'
})
export class ClickRedirectUrlDirective {
    @HostListener('click', ['$event']) onClick($event) {
        //console.info('clicked: ' + $event);
        this.redirectUrlService._url.next('ok');
    }

    constructor(private router: Router, private redirectUrlService: RedirectUrlService, private el: ElementRef, public tr: TranslateService, public renderer: Renderer2) {
        const showButton = this.router.url.search('reservationToken') !== -1;
        if (showButton) {
            //this.el.nativeElement.style.display = 'none';
        }

        //  this.renderer.addClass(this.el.nativeElement, 'onsale-temp');
    }

}
