@if (tickets && tickets.ticketElements.length > 0) {
  <div style="max-width: 600px;"  [ngStyle]="{'margin': 'auto', 'margin-bottom': '10px'}" >
    <tw-client-tickets-summary [ticketElements]="tickets.ticketElements" ></tw-client-tickets-summary>
  </div>
}

@if (tickets && tickets.ticketElements.length === 0) {
  <h3 style="text-align: center;">{{'client.noTickets' | translate}}</h3>
}
@if (!tickets) {
  <div class="loader">Loading...</div>
}
