import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationExtras, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {PageSaleService} from '../../../page/services/page-sale.service';
import {SubdomainService} from '../../../shared/services/subdomain.service';
import {KeyServiceService, ModeKeyServeEnum} from '../../../key-server-container/key-service.service';
import {WebsiteSaleService} from '../../../shared/services/website-sale.service';
import {TranslateService} from '@ngx-translate/core';
import {PageLoadService} from '../../../../../projects/shared-lib/src/lib/component/page-load/page-load.service';
import {catchError, mergeMap} from 'rxjs/operators';
import {keyServerStateEnum} from '../../../models/key-server';

@Injectable({
  providedIn: 'root'
})
export class FlexPassResolver {
    constructor(private pageApi: PageSaleService,
                private subdomainService: SubdomainService,
                private keyServiceService: KeyServiceService,
                private websiteSaleService: WebsiteSaleService,
                private router: Router,
                private translate: TranslateService,

    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const id = route.paramMap.get('flexEventId');
        //console.log(route.paramMap.get('flexEventId'));
        // console.log(state);
        const t = this.websiteSaleService.utf8_to_b64(window.location.pathname);
        //console.log(this.websiteSaleService.b64_to_utf8(t));



        return this.keyServiceService.getKeyServer(ModeKeyServeEnum.FLEX_PASS, id)
            .pipe(
                mergeMap((key) => {

                        const navigationExtras: NavigationExtras = {
                            queryParams: { ...route.queryParams, 'urlRedirect': t }
                        };
                        //console.log(key.state)

                        switch (key.state) {
                            case keyServerStateEnum.AUTHORIZED: {

                                return of([]);

                            }
                            default: {
                                this.router.navigate([this.translate.currentLang + '/key-server/flex-pass/' + id], navigationExtras);

                                return of([]);
                            }
                        }


                    }
                ),
                catchError(err => {
                    // console.log(err);
                    return of(<any>[]);
                })
            );


    }
}
