import {ApiInterceptorService} from './api-interceptor.service';
import {AuthInterceptorService} from './auth-interceptor.service';
import {SessionSaleInterceptorService} from './session-sale.interceptor.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {CachingInterceptor} from './caching-interceptor.service';
import {RequestCacheWithMap, RequestCache} from "../services/request-cache-with-map.service";
import {SessionLocaleService} from "../services/session-locale.service";
import {MessageObserverService} from '../../shared/services/message-observer.service';
import {AuthenticationUserService} from '../../shared/services/authentication-user.service';

export const SHARED_INTERCEPTORS_SALE: any [] = [
    {provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SessionSaleInterceptorService, multi: true}
];
export const SHARED_INTERCEPTORS_ADMIN: any [] = [
    {provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
];
// export const SHARED_PROVIDE: any [] = [
//     { provide: RequestCacheWithMap, useClass: RequestCacheWithMap, multi: true },
//     { provide: RequestCache, useClass: RequestCache, multi: true },
//     { provide: SessionLocaleService, useClass: SessionLocaleService, multi: true },
//     { provide: MessageObserverService, useClass: MessageObserverService, multi: true },
//     // { provide: AuthenticationUserService, useClass: AuthenticationUserService, multi: true },
//
// ];
export const SHARED_PROVIDE: any [] = [
    RequestCacheWithMap,
    RequestCache,
    SessionLocaleService,
    MessageObserverService,
    AuthenticationUserService
    // { provide: AuthenticationUserService, useClass: AuthenticationUserService, multi: true },

];

