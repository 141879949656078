import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[twColorBySaleState]'
})
export class ColorBySaleStateDirective implements OnInit {
    @Input() saleState;
    @Input() colorOnsale = 'white';

    constructor(private el: ElementRef, private renderer: Renderer2) {

    }

    ngOnInit(): void {
        // console.log(this.saleState);
        switch (this.saleState) {
            case 'ONSALE':
                this.el.nativeElement.style.color = this.colorOnsale;
                this.renderer.addClass(this.el.nativeElement, "onsale-temp");


                break;
            case 'SOLDOUT':
                this.el.nativeElement.style.color = 'red';
                this.renderer.addClass(this.el.nativeElement, "sold-out-temp");
                break;
            case 'WASONSALE':
            case 'CANCELLED':
            case 'NOTAVAILABLE':
                this.el.nativeElement.style.color = 'black';
                this.renderer.addClass(this.el.nativeElement, "was-onsale-temp");
                this.renderer.addClass(this.el.nativeElement, "cancelled-temp");
                this.renderer.addClass(this.el.nativeElement, "not-available-temp");
                break;
            case 'INVISIBLE':
            default:
                this.el.nativeElement.style.color = 'transparent';

        }
    }

}
