import {Component, OnInit} from '@angular/core';
import {InvoiceService} from '../../invoice.service';
import {ClientService} from '../../client.service';
import {Invoices} from '../../../models/invoices';
import {HttpParams} from '@angular/common/http';
import {AnalyticsService} from '../../../shared/services/analytics.service';

@Component({
    selector: 'tw-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
    invoices: Invoices;
    constructor(private invoiceService: InvoiceService,
                private clientService: ClientService,
                private analyticsService: AnalyticsService) {
    }

    ngOnInit() {
        const client = this.clientService.getClientLocal();
        this.analyticsService.trackInvoicesView();
        const extraParams = new HttpParams().set('clientId', String(client.id));
        this.invoiceService.getInvoices(extraParams).subscribe(value => {
            value.invoices.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
            });
            this.invoices = value;
        }, error1 => {
            //console.log(error1);
        });
    }

}
