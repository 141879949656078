// Import the core angular services.
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SubdomainService } from './subdomain.service';


@Injectable({
    providedIn: 'root'
})
export class BrowserFaviconsService {

    private elementId = 'favicons-service-injected-node';

    private useCacheBusting = true;

    // I initialize the Favicons service.
    constructor(private subdomainService: SubdomainService) {

    }

    public activate(name: string): void {
        if (!name) {
            //console.log(`Favicon for [ ${name} ] not found.`);
            return;
        }
        if (typeof name !== 'string') {
           // console.log(`url format  [ ${name} ] not supported.`);
            return;
        }
        const td = name.split('.');
        if (name.charAt(0) === '/') {
            name = name.substr(1);
        }
        const url = this.subdomainService.getUrlWithSubdomain() + name;
        if (td[1]) {
            this.setNode(td[1], url);
        } else {
            this.setNode('jpeg', url);
        }
    }

    private addNode(type: string, href: string): void {

        const linkElement = document.createElement('link');
        linkElement.setAttribute('id', this.elementId);
        linkElement.setAttribute('rel', 'icon');
        linkElement.setAttribute('type', type);
        linkElement.setAttribute('href', href);
        document.head.appendChild(linkElement);

    }

    private cacheBustHref(href: string): string {
        const date = new Date()
        date.setHours(date.getHours(), 0, 0 ,0)
        const timestamp = date.valueOf();
        const augmentedHref = (href.indexOf('?') === -1)
            ? `${href}?faviconCacheBust=${timestamp}`
            : `${href}&faviconCacheBust=${timestamp}`
        ;
        return (augmentedHref);
    }

    private removeExternalLinkElements(): void {
        const linkElements = document.querySelectorAll('link[rel*=\'icon\' ]');
        for (const linkElement of Array.from(linkElements)) {
            linkElement.parentNode.removeChild(linkElement);
        }
    }

    private removeNode(): void {
        const linkElement = document.head.querySelector('#' + this.elementId);
        if (linkElement) {
            document.head.removeChild(linkElement);
        }
    }

    private setNode(type: string, href: string): void {

        const augmentedHref = this.useCacheBusting
            ? this.cacheBustHref(href)
            : href
        ;

        this.removeNode();
        this.addNode(type, augmentedHref);

    }

}
