
@if (cartElement) {
  <tw-cart-header   [cartElement]="cartElement"   [type]="cartElement._type" (click)="openPrices=!openPrices"></tw-cart-header>
}
@if (openPrices) {
  <div >
    @for (pr of cartElement.prices; track pr; let ip = $index) {
      <tw-cart-detail   [cartPrices]="[pr]"   [location]="cartElement.location" ></tw-cart-detail>
      @if (cartElement.prices.length !== ip+1) {
        <hr>
        }
      }
      <tw-cart-parking  [parking]="cartElement.parking" [isInCheckout]="isInCheckout"></tw-cart-parking>
    </div>
  }





