<!--<div class="tw-margin-5 mat-elevation-z1" >-->
<!--<div class="tw-padding-10 tw-center-600 tw-margin-5 " style="min-height: 600px">-->
<!--<h1>404 Error Page #1</h1>-->

<!--<section class="error-container">-->
<!--<span><span>4</span></span>-->
<!--<span>0</span>-->
<!--<span><span>4</span></span>-->
<!--</section>-->
<!--<button (click)="showError=!showError">-->
<!--<mat-icon mat-button color="warn">error</mat-icon>-->
<!--<mat-icon *ngIf="!showError">keyboard_arrow_right</mat-icon>-->
<!--<mat-icon *ngIf="showError">keyboard_arrow_down</mat-icon>-->
<!--</button>-->


<!--<pre *ngIf="showError">-->
<!--{{message | json }}-->
<!--</pre>-->
<!--</div>-->

<!--</div>-->

<div id="notfound">
  @if (!message) {
    <div class="notfound" >
      <div class="notfound-404">
        <h1>4<span>0</span>4</h1>
      </div>
      <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
      <a routerLink="./">home page</a>
    </div>
  }
  @if (message) {
    <div class="notfound">
      <div class="notfound-404">
        <h1>5<span>0</span>0</h1>
      </div>
      <p>Oops,something whent wrong </p>
      <p>Try to refresh this page or feel free to contact us if the problem persists </p>
      <!--<a routerLink="/">home page</a><br><br>-->
      <button class="tw-primary" (click)="showError=!showError">
        <mat-icon mat-button color="warn">error</mat-icon>
        @if (!showError) {
          <mat-icon>keyboard_arrow_right</mat-icon>
        }
        @if (showError) {
          <mat-icon>keyboard_arrow_down</mat-icon>
        }
      </button>
      @if (showError) {
        <pre>
          {{message | json }}
        </pre>
      }
    </div>
  }
</div>



