@if (invoices && invoices.invoices.length >0 ) {
  <div style="margin: auto;max-width: 600px;" >
    @if (invoices.invoices.length>0) {
      <div class="tw-margin-5" >
        <h2><b>{{ 'title.invoices' | translate }}</b></h2>
        @for (invoice of invoices.invoices; track invoice; let i = $index) {
          <tw-invoice-view [invoice]="invoice"></tw-invoice-view>
        }
      </div>
    }
  </div>
} @else {
  <div class="tw-center-600 tw-padding-10 mat-elevation-z1" style="min-height: 100px;text-align: center">
    {{ 'invoice.emptyList' | translate }}
  </div>
}



