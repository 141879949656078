import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Invoice} from '../../../models/invoice';
import {TranslateService} from '@ngx-translate/core';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';

@Component({
  selector: 'tw-client-invoice-header',
  templateUrl: './client-invoice-header.component.html',
  styleUrls: ['./client-invoice-header.component.css']
})
export class ClientInvoiceHeaderComponent implements OnInit {
  @Input() invoice: Invoice;
  @Input() isShowingDetails: boolean;
  @Output() toggleDetails = new EventEmitter();

  constructor(public translate: TranslateService) {
    registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
  }

  toggle() {
    this.toggleDetails.emit();
  }

  ngOnInit() {

  }

}
