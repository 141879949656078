
@if (!isSummary) {
  <section>
    @if (cart) {
      @if (cart &&  cart.cartElements.length === 0) {
        <div style="margin: auto;text-align: center">
          <div style="">
            <span>{{ "cart.emptyShipping" | translate }}</span>
          </div>
          <div style="margin-top: 10px">
            <a mat-raised-button twClickRedirectUrl class="bt-continue-shopping-temp">
              <span (click)="trackContinueShopping()">{{ "cart.continueShopping" | translate }}</span>
            </a>
          </div>
        </div>
      }
    }
    @if (!isSummary && cart && cart.cartElements.length > 0) {
      <div class="tw-flex tw-column "  style="padding: 15px;position: relative" >
        <div  style="text-align: center"><span  style="font-weight: 600;"> Vos billets son reserves</span></div>
        <div class="border-panel-small " style="text-align: center">
          <div style="margin: 15px">
            @if (future) {
              <tw-timer-view [reservationDate]="future" (offTimer)="timerHold($event)"
                [remainingTime]="cart.remainingTime"
              ></tw-timer-view>
            }
          </div>
        </div>
        <div class="margin-20">
          <span style="font-weight:800 ">Votre commande</span>
          <button  mat-button routerLink="."
            color="primary">
            {{ "cart.modifier" | translate }}
          </button>
        </div>
        @if (cart.cartElements) {
          <ng-container class="">
            @for (el of cart.cartElements; track el) {
              @switch (el._type) {
                @case (el._type ==='combo') {
                  <div>
                    <tw-cart-header [cartElement]="el"></tw-cart-header>
                    @for (combo of el.events; track combo) {
                      <tw-cart-header [cartElement]="combo"></tw-cart-header>
                      <tw-cart-detail [cartPrices]="combo.prices"></tw-cart-detail>
                    }
                  </div>
                }
                @default {
                  <div class="tw-flex tw-column fontSize" >
                    <div class="margin-20 tw-flex"  style="height: 100px;width: 100%;background: gray;justify-content: center">
                      <div style="margin: 35px">image</div>
                    </div>
                    <div class="tw-flex tw-column margin-20">
                      @if (el.title) {
                        <div class="">
                          <span style="font-weight:600 ">{{el.title}}</span>
                        </div>
                      }
                      @if (el.eventStart) {
                        <div>
                          {{el.eventStart}}
                        </div>
                      }
                    </div>
                    @for (pr of el.prices; track pr) {
                      <div class="tw-flex tw-row margin-20">
                        <div style="flex-grow: 4">
                          {{pr.cartDetailToken.length}} x
                          @if (pr.priceLevel) {
                            <span>{{pr.priceLevel}} / </span>
                            }{{pr.name}}
                          </div>
                          <div>
                            {{(pr.price*pr.cartDetailToken.length) / 100 | currency :'':'symbol-narrow':'':translate.currentLang}}
                          </div>
                        </div>
                        @if (pr.serviceCharges) {
                          <div class="tw-flex tw-row margin-20">
                            <div style="flex-grow: 4">
                              @if (pr.priceLevel) {
                                <span> {{ "title.serviceCharge" | translate }} </span>
                              }
                            </div>
                            <div>
                              {{(pr.serviceCharges *pr.cartDetailToken.length) / 100 | currency :'':'symbol-narrow':'':translate.currentLang}}
                            </div>
                          </div>
                        }
                      }
                      <div>
                        <div>
                        </div>
                        <div>
                        </div>
                      </div>
                    </div>
                  }
                }
              }
            </ng-container>
          }
          <div style="border-top: 3px #f0f0f2 solid;">
            <tw-cart-tax-view [tax]="cart.tax" typeStyle="2"></tw-cart-tax-view>
          </div>
          <div class="tw-margin-5">
            <button style="width: 100%;" class="tw-primary bt-continue-shopping-temp" mat-raised-button color="primary"
              (click)="goToHome()" twClickRedirectUrl>{{ "cart.continueShopping" | translate }}
            </button>
          </div>
          <div class="tw-margin-5">
            <button style="width: 100%;" class="tw-primary" mat-raised-button color="primary"
              (click)="goToCheckout()">{{ "cart.buyNow" | translate }}
            </button>
          </div>
        </div>
      }
    </section>
  }


  @if (isSummary) {
    <section style="background: #d0d0d0;position: relative" >
      @if (openCart && cart && cart.cartElements.length > 0) {
<div  style=" position: absolute;
                      bottom: 38px;  background: #d0d0d0;width: 100%; height: 60vh; overflow: auto" >
          <div class="tw-flex tw-column"
             style="padding: 15px;
               " >
            @if (cart.cartElements) {
              <ng-container class="">
                @for (el of cart.cartElements; track el) {
                  @switch (el._type) {
                    @case (el._type ==='combo') {
                      <div>
                        <tw-cart-header [cartElement]="el"></tw-cart-header>
                        @for (combo of el.events; track combo) {
                          <tw-cart-header [cartElement]="combo"></tw-cart-header>
                          <tw-cart-detail [cartPrices]="combo.prices"></tw-cart-detail>
                        }
                      </div>
                    }
                    @default {
                      <div class="tw-flex tw-column fontSize" >
                        <div class="tw-flex tw-column margin-20">
                          @if (el.title) {
                            <div class="">
                              <span style="font-weight:600 ">{{el.title}}</span>
                            </div>
                          }
                          @if (el.eventStart) {
                            <div>
                              {{el.eventStart}}
                            </div>
                          }
                        </div>
                        @for (pr of el.prices; track pr) {
                          <div class="tw-flex tw-row margin-20">
                            <div style="flex-grow: 4">
                              {{pr.cartDetailToken.length}} x
                              @if (pr.priceLevel) {
                                <span>{{pr.priceLevel}} / </span>
                                }{{pr.name}}
                              </div>
                              <div>
                                {{(pr.price*pr.cartDetailToken.length) / 100 | currency :'':'symbol-narrow':'':translate.currentLang}}
                              </div>
                            </div>
                            @if (pr.serviceCharges) {
                              <div class="tw-flex tw-row margin-20">
                                <div style="flex-grow: 4">
                                  @if (pr.priceLevel) {
                                    <span> {{ "title.serviceCharge" | translate }} </span>
                                  }
                                </div>
                                <div>
                                  {{(pr.serviceCharges *pr.cartDetailToken.length) / 100 | currency :'':'symbol-narrow':'':translate.currentLang}}
                                </div>
                              </div>
                            }
                          }
                          <div>
                            <div>
                            </div>
                            <div>
                            </div>
                          </div>
                        </div>
                      }
                    }
                  }
                </ng-container>
              }
              <div style="border-top: 3px #f0f0f2 solid;">
                <tw-cart-tax-view [tax]="cart.tax" typeStyle="2"></tw-cart-tax-view>
              </div>
            </div>
          </div>
        }
        @if (cart && cart.cartElements && cart.cartElements.length>0) {
          <button style="width: 95%;" mat-button   (click)="openCart =! openCart" >
            {{"title.sommaire"| translate }}
            @if (!openCart) {
              <mat-icon >keyboard_arrow_right</mat-icon>
            }
            @if (openCart) {
              <mat-icon>keyboard_arrow_up</mat-icon>
            }
          </button>
        }
      </section>
    }




