// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    urlApi: 'https://mta-staging.com/api/',
    log: {
        active: true,
        NB: 0,
        random: 0
    },
    defaultLocale: 'en',
    basePath: '/',
    systemValues: 'mta',
    urlGwt: 'gwt',
    oldFlow: true,
    splitPrice: false,
    oldCheckout: false,
    color: {
        primary: '#151a4f',
        accent: '#008000',
        warn: '#f44336',
        text: '#FFF',
        Background: '#FFF'
    },
    recaptcha: {
        siteKey: '',
        active: false
    },
    googlePay:{
        gateway: '',
        gatewayMerchantId: ''
    },
    applePay:{
        merchantIdentifier: '',
        displayName: '',
        initiative: '',
        initiativeContext: ''
    },
    /**  Pour que Google Pay et Apple Pay fonctionnent, il est nécessaire de rediriger vers un sous-domaine checkout.ticketpro.ca */
    checkout:false,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
