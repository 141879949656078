
@if (tax) {
  <div class="mat-elevation-z8 tw-tax-container tw-flex tw-row-reverse">
    <div class="tw-column" style="width: 184px">
      <div class="tw-flex tw-row">
        <div class="tw-tax-text">
          {{'checkout.subtotal' | translate}}
        </div>
        <div class="tw-tax-number">
          {{(tax.subtotal)/100 | currency :'':'symbol-narrow':'':translate.currentLang}}
        </div>
      </div>
      @if (tax.serviceCharge) {
        <div class="tw-flex tw-row">
          <div class="tw-tax-text">
            {{'title.serviceCharge' | translate}}
          </div>
          <div class="tw-tax-number">
            {{tax.serviceCharge/100 | currency :'':'symbol-narrow':'':translate.currentLang}}
          </div>
        </div>
      }
      @if (tax.tax1>0) {
        <div class="tw-flex tw-row">
          <div class="tw-tax-text">
            {{tax.descriptionTax1}}
          </div>
          <div class="tw-tax-number">
            {{tax.tax1/100 | currency :'':'symbol-narrow':'':translate.currentLang}}
          </div>
        </div>
      }
      @if (tax.tax2>0) {
        <div class="tw-flex tw-row">
          <div class="tw-tax-text">
            {{tax.descriptionTax2}}
          </div>
          <div class="tw-tax-number">
            {{tax.tax2/100 | currency :'':'symbol-narrow':'':translate.currentLang}}
          </div>
        </div>
      }
      @if (tax.tax3>0) {
        <div class="tw-flex tw-row">
          <div class="tw-tax-text">
            {{tax.descriptionTax3}}
          </div>
          <div class="tw-tax-number">
            {{tax.tax3/100 | currency :'':'symbol-narrow':'':translate.currentLang}}
          </div>
        </div>
      }
      <hr>
        <div class="tw-flex tw-row">
          <div class="tw-tax-text">
            {{'checkout.total' | translate}}
          </div>
          <div style="font-weight: bold;font-size: larger" class="tw-tax-number">
            {{(tax.totalAfterTaxes)/100 | currency :'':'symbol-narrow':'':translate.currentLang}}
          </div>
        </div>
      </div>
    </div>
  }
