import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DrawCalendarService {
    private firstWeekDay = 0;

    constructor() {
    }

    weekStartDate(date: Date) {
        const startDate = new Date(date.getTime());
        while (startDate.getDay() !== this.firstWeekDay) {
            startDate.setDate(startDate.getDate() - 1);
        }
        return startDate;
    }

    CalendarException(message: string) {
        return message;
    }

    monthDates(year: number, month: number) {
        if ((year < 1970)) {
            throw this.CalendarException('year must be a number >= 1970');
        }
        if ((month < 0) || (month > 11)) {
            throw new Error('month must be a number (Jan is 0)');
        }
        const weeks = [];
        let week = [];
        let i = 0,
            date = this.weekStartDate(new Date(year, month, 1));
        do {
            for (i = 0; i < 7; i++) {
                week.push(this.getDayOrZero(date, month));
                date = new Date(date.getTime());
                date.setDate(date.getDate() + 1);
            }
            weeks.push(week);
            week = [];
        } while ((date.getMonth() <= month) && (date.getFullYear() === year));
        return weeks;
    }

    getMonthDates(dt: Date): any[] {
        const month = dt.getMonth();
        const year = dt.getFullYear();
        if ((year < 1970)) {
            throw this.CalendarException('year must be a number >= 1970');
        }
        if ((month < 0) || (month > 11)) {
            throw new Error('month must be a number (Jan is 0)');
        }
        const weeks = [];
        let week = [];
        let i = 0,
            date = this.weekStartDate(new Date(year, month, 1));
        do {
            for (i = 0; i < 7; i++) {
                week.push(this.getDayOrZero(date, month));
                date = new Date(date.getTime());
                date.setDate(date.getDate() + 1);
            }
            weeks.push(week);
            week = [];
        } while ((date.getMonth() <= month) && (date.getFullYear() === year));
        return weeks;
    }

    getDayOrZero(date: Date, month: number): Date {

        return date.getMonth() === month ? date : null;
    }

    daysInMonth(year: number, month: number) {
        return new Date(year, month + 1, 0).getDate();
    }

}
