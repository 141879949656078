import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Invoices} from '../models/invoices';
import {SubdomainService} from '../shared/services/subdomain.service';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {

    constructor(private http: HttpClient, private subdomainService: SubdomainService) {
    }

    getInvoices(httpParams: HttpParams): Observable<Invoices> {
        return this.http.get<Invoices>('v1/invoices', {params: httpParams});
    }

    getInvoicesHtml(httpParams: HttpParams): Observable<any> {
        return this.http.get(`backend/v1/ng-invoice.html`, {params: httpParams, responseType: 'text'});
    }

}
