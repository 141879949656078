import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {UntypedFormGroup} from '@angular/forms';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ClientVerificationService {

    constructor(private http: HttpClient) {
    }

    validateEmail(email: string): Observable<any> {
        return this.http.get(`${environment.urlApi}v1/xverify/email/${btoa(email)}`).pipe(
            catchError(() => {
                return of({
                    email: {
                        status: 'valid'
                    }
                });
            })
        );
    }

    validatePhone(phone: string): Observable<any> {
        return this.http.get(`${environment.urlApi}v1/xverify/phone/${btoa(phone)}`);
    }

    validateAddress(profileForm: UntypedFormGroup): Observable<any> {
        const address = profileForm.value.address;
        const street = address.addressLine1;
        const zip = address.zipCode;
        const city = address.city;
        const country = address._country;
        return this.http.get(`${environment.urlApi}v1/xverify/address/${btoa(street)}/${btoa(city)}/${btoa(country)}/${btoa(zip)}`);
    }

    validateIpAddress(): Observable<any> {
        return this.http.get(`${environment.urlApi}v1/xverify/ip`);
    }

    validateEmailMailCheck(email: string): Observable<any> {
        return this.http.get(`${environment.urlApi}v1/mailcheck/${btoa(email)}`);
    }

}
