

@if (paymentRequest) {
  <google-pay-button environment="PRODUCTION"
    [buttonColor]="buttonColor"
    [buttonLocale]="translate.currentLang"
    [buttonSizeMode]="isCustomSize ? 'fill' : 'static'"
    [paymentRequest]="paymentRequest"
    [style.width.px]="buttonWidth"
    [style.height.px]="buttonHeight"
    [paymentAuthorizedCallback]="onPaymentAuthorized.bind(this)"
    [cancelCallback]="cancelCallback"
    [errorCallback]="errorCallback"
  ></google-pay-button>
}
<div id="container"></div>

