import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {LangsObserverService} from '../modules/shared/services/langs-observer.service';

@Injectable({
    providedIn: 'root'
})
export class AppResolveTranslateService {

    constructor(private router: Router,
                public translate: TranslateService,
                private langsObserverService: LangsObserverService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const lang = route.paramMap.get('lang');
        this.translate.use(lang);
        this.langsObserverService.addLang(lang);
        return undefined;
    }
}
