import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TicketPrice} from '../../../../models/ticket-price';

@Component({
    selector: 'tw-ticket-details',
    templateUrl: './client-ticket-details.component.html',
    styleUrls: ['./client-ticket-details.component.css']
})
export class ClientTicketDetailsComponent implements OnInit {
    @Input() ticketPrices: TicketPrice[];
    @Output() displayPdf: EventEmitter<string> = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit() {
    }

    displayPdfFile(url: string) {
        this.displayPdf.emit(url);
    }

}
