import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Phone} from '../../../models/phone';

@Component({
  selector: 'tw-phone-form',
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.css']
})
export class PhoneFormComponent implements OnInit {

    @Input() formGroupPhone: UntypedFormGroup;
    @Input()  phone: Phone;

    constructor(private fb: UntypedFormBuilder) {

    }

    ngOnInit() {
        this.formGroupPhone.addControl('number', this.fb.control((this.phone && this.phone.number) ? this.phone.number : '', Validators.required));
    
    }

}
