import { Injectable } from '@angular/core';
import { ThemePalette } from '../../models/config-website';
import { Palette, saveColor } from '../../../../projects/shared-lib/src/lib/utils/colorPalatteHelp';
import { environment } from '../../../../environments';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    constructor() {
    }

    static changeTheme(themePalette: ThemePalette) {
        ThemeService.oldTheming(themePalette);
        ThemeService.themming(themePalette);
    }

    static oldTheming(themePalette: ThemePalette) {
        let elm = document.querySelector<any>(':root');
        if (themePalette.primaryPalette || themePalette.primaryHex) {
            //  const temp = document.querySelector<any>('.theme-wrapper');
            const color = themePalette.primaryHex ? themePalette.primaryHex : themePalette.primaryPalette;
            elm.style.setProperty('--primaryBackground', color);

        } else {
            elm.style.setProperty('--primaryBackground', environment.color.primary);
        }
        if (themePalette.accentPalette) {
            // const temp = document.querySelector<any>('.theme-wrapper');
            const color = themePalette.accentHex ? themePalette.accentHex : themePalette.accentPalette;
            elm.style.setProperty('--accentBackground', color);
        } else {
            elm.style.setProperty('--accentBackground', environment.color.accent);
        }
        if (themePalette.warnPalette) {
            // const temp = document.querySelector<any>('.theme-wrapper');
            const color = themePalette.warnHex ? themePalette.warnHex : themePalette.warnPalette;
            elm.style.setProperty('--warnBackground', color);
        }
        if (themePalette.backgroundPalette) {
            // const temp = document.querySelector<any>('.theme-wrapper');
            elm.style.setProperty('--appBackground', themePalette.backgroundPalette);
        }
    }

    static themming(themePalette: ThemePalette) {

        if (themePalette.primaryPalette || themePalette.primaryHex) {
            //  const temp = document.querySelector<any>('.theme-wrapper');
            const color = themePalette.primaryHex ? themePalette.primaryHex : themePalette.primaryPalette;
            saveColor(color, Palette.primary);
        } else {
            saveColor(environment.color.primary, Palette.primary);
        }
        if (themePalette.accentPalette) {
            // const temp = document.querySelector<any>('.theme-wrapper');
            const color = themePalette.accentHex ? themePalette.accentHex : themePalette.accentPalette;
            saveColor(color, Palette.accent);
        } else {
            saveColor(environment.color.accent, Palette.accent);
        }
        if (themePalette.warnPalette) {
            // const temp = document.querySelector<any>('.theme-wrapper');
            const color = themePalette.warnHex ? themePalette.warnHex : themePalette.warnPalette;
            saveColor(color, Palette.warn);
        }
        if (themePalette.backgroundPalette) {
            // const temp = document.querySelector<any>('.theme-wrapper');

        }
    }
}
