import {Component, Input, OnInit} from '@angular/core';
import {CartElement} from '../../../models/cart-element';

@Component({
    selector: 'tw-rs',
    templateUrl: './rs.component.html',
    styleUrls: ['./rs.component.css']
})
export class RsComponent implements OnInit {
    @Input() cartElement: CartElement;
    @Input() isInCheckout = false;
    openPrices = true;

    constructor() {
    }

    ngOnInit() {
    }

}
