import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartSummaryComponent } from './component/cart-summary/cart-summary.component';
import { CartHeaderComponent } from './component/cart-header/cart-header.component';
import { CartDetailComponent } from './component/cart-detail/cart-detail.component';
import { CartTaxViewComponent } from './component/cart-tax-view/cart-tax-view.component';
import { CartLineComponent } from './component/cart-line/cart-line.component';
import { MaterialModule } from '../material';
import { AppCartComponent } from './component/app-cart/app-cart.component';
import { ActivatedRoute, RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { GaComponent } from './component/ga/ga.component';
import { RsComponent } from './component/rs/rs.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LangsObserverService } from '../shared/services/langs-observer.service';
import { CartTaxInfoPriceComponent } from './component/cart-tax-info-price/cart-tax-info-price.component';
import { CartParkingComponent } from './component/cart-parking/cart-parking.component';
import { CartDesktopComponent } from './component/cart-desktop/cart-desktop.component';
import { CanActiveCartGuard } from './can-active-cart.guard';


export const routesCart: Routes = [
    {
        path: '',
        component: AppCartComponent,
        canActivate: [CanActiveCartGuard]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routesCart),
        SharedModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule,

    ],
    declarations: [
        CartSummaryComponent,
        CartHeaderComponent,
        CartDetailComponent,
        CartTaxViewComponent,
        CartLineComponent,
        AppCartComponent,
        GaComponent,
        RsComponent,
        CartTaxInfoPriceComponent,
        CartParkingComponent,
        CartDesktopComponent
    ],
    exports: [CommonModule,
        CartSummaryComponent,
        CartHeaderComponent,
        CartDetailComponent,
        CartTaxViewComponent,
        CartLineComponent,
        AppCartComponent,
        GaComponent,
        RsComponent,
        CartTaxInfoPriceComponent,
        CartDesktopComponent
    ]
})
export class CartModule {
    constructor(private translate: TranslateService,
                private langsObserverService: LangsObserverService) {

        this.langsObserverService.watchLang().subscribe(value => {
            this.translate.use(value);
        });
    }
}
