import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {lastValueFrom, Observable} from 'rxjs';
import {ClientService} from '../modules/client/client.service';
import {TranslateService} from '@ngx-translate/core';
import { WebsiteSaleService } from '../modules/shared/services/website-sale.service';

@Injectable({
    providedIn: 'root'
})
export class RedirectPasswordExpiryService  {
    constructor(
        private clientService: ClientService,
        private router: Router,
        private translate: TranslateService,
        private websiteSaleService: WebsiteSaleService
    ) {}

    async canActivate(): Promise<boolean> {

        if (!this.websiteSaleService.getLocalConfig()) {
            await this.websiteSaleService.getConfigWeb()
        }
        const account = this.websiteSaleService.getLocalConfig();
        const isLoggedIn = await lastValueFrom(this.clientService.isLoggedIn(account?.accountCode || ''));
        if (isLoggedIn) {
            const currentUser = this.clientService.getClientLocal();
            if (currentUser && currentUser.mustChangePassword) {
                await this.router.navigate([`${this.translate.currentLang}/client/auth/change-password`]);
                return false;
            }
        }
        return true;
    }
}
