import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export enum ActionButtonFloatingOrderEnum {
    DATA = 'DATA',
    HOLD = 'HOLD',
    CLOSE = 'CLOSE'
}

export interface ActionButtonFloatingOrder {
    name: ActionButtonFloatingOrderEnum;
    data?: {
        splitPrices?: boolean,
        load?: boolean,
        qtyReserved?: number,
        bestsSelect?: boolean,
        total?: number
    };
    value?: any;
    isDialog?:boolean;

}


@Injectable({
    providedIn: 'root'
})
export class ButtonFloatingOrderService {

    action = new Subject<ActionButtonFloatingOrder>();

    constructor() {

    }
}
