import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowPasswordComponent } from './show-password/show-password.component';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';



@NgModule({
    declarations: [
        ShowPasswordComponent
    ],
    exports: [
        ShowPasswordComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatFormFieldModule
    ]
})
export class ShowPaswwordModule { }
