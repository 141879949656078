import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { environment } from '../../../../environments/environment';
//import {ComponentRef} from '@angular/core/src/linker/component_factory';
import { Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { DatePipe, Location } from '@angular/common';
import { ComponentRef } from '@angular/core';
import { SubdomainService } from './subdomain.service';
import {from, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HelpsService {
    static dateEqual(date: Date, date2: Date): boolean {
        return date.getFullYear() === date2.getFullYear() && date.getMonth() === date2.getMonth() && date.getDate() === date2.getDate();
    }

    static createComponent(T, viewContainerRef: ViewContainerRef): ComponentRef<any> {
        if (!viewContainerRef) {
            return;
        }
        if (viewContainerRef) {
            viewContainerRef.clear();
        }

        //const factory = componentFactoryResolver.resolveComponentFactory(T);
        return viewContainerRef.createComponent(T);
    }

    static createHttpParams(listPram: Params, httpParams?: HttpParams, skip?: string): HttpParams {
        if (!httpParams) {
            httpParams = new HttpParams();
        }
        Object.keys(listPram).forEach(value => {
            if (skip !== value) {
                httpParams = httpParams.set(value, listPram[value]);
            }
        });
        return httpParams;
    }

    static httpParamsToParams(httpParams: HttpParams) {
        const queryParams = {};
        httpParams.keys().forEach((key) => {
                queryParams[key] = httpParams.get(key);
            }
        );
        return queryParams;
    }

    static injectScript(src: string,attributes?: { [key: string]: string }) {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.async = true;
            script.src = src;
            script.type = 'text/javascript';
            // Ajouter les attributs spécifiés au script
            if(attributes){
                for (const key in attributes) {
                    if (attributes.hasOwnProperty(key)) {
                        script.setAttribute(key, attributes[key]);
                    }
                }
            }

            script.addEventListener('load', resolve);
            script.addEventListener('error', () => reject('Error loading script.'));
            script.addEventListener('abort', () => reject('Script loading aborted.'));
            document.body.appendChild(script);
        });
    }
    static injectScriptObservable(src: string,attributes?: { [key: string]: string }) {
        return  from(HelpsService.injectScript(src,attributes));
    }

    static injectCss(src: string) {
        return new Promise((resolve, reject) => {
            const script = document.createElement('link');
            script.href = src;
            script.rel = 'stylesheet';
            script.addEventListener('load', resolve);
            script.addEventListener('error', () => reject('Error loading script.'));
            script.addEventListener('abort', () => reject('Script loading aborted.'));
            document.head.appendChild(script);
        });
    }

    static dateToString(val: Date): string {
        const dt = new DatePipe('en-US');
        const s = dt.transform(val, 'yyyy-MM-ddTHH:mm:ssZ');
        if (s) {
            return s.substring(0, 19);
        }
        return null;
    }

    static dateToStringWithoutTimezone(date) {
        const dt = new DatePipe('en-US');
        let s = dt.transform(date, 'yyyy-MM-ddTHH:mm:ss');
        s = String(s + '-04:00');
        return s;
    }

    static getHeadForAgGrid(list) {
        const columnDefs = [];
        if (!list || list.length === 0) {
            return columnDefs;
        }
        const obj = list[0];
        const keys = Object.keys(obj);
        keys.sort();

        keys.forEach(function(element) {
            const headerNameFormat = element.split('__');
            columnDefs.push({
                    headerName: headerNameFormat[1],
                    field: element.toLowerCase(),
                    width: 200
                }
            );
        });
        return columnDefs;
    }

    static getUrlParams(): any {

        const params = {};
        try {
            window.location.search.substring(1).split('&').filter(str => str.length > 0).forEach(paramString => {
                const equalSignIndex = paramString.indexOf('=');
                params[paramString.substring(0, equalSignIndex)] = paramString.substring(equalSignIndex + 1);
            });

            // console.log('params', params);

            return params;
        }catch (e) {
            return params;
        }

    }

    static getValueParam(key: string): any {
        if (!key) {
            return null;
        }
        const temp = HelpsService.getUrlParams();
        if (Object.keys(temp).length === 0) {
            return null;
        }

        return temp[key];
    }

    constructor(public l: Location,
                private subdomainService: SubdomainService) {
    }

    public formatUrlImage(imageUrl): string {
        if (imageUrl && imageUrl.search('http') === -1) {
            return this.subdomainService.getApiUrl(imageUrl.startsWith('/') ? imageUrl.substring(1, imageUrl.length) : imageUrl)
        }
        return imageUrl;
    }

    selectChange(val) {
        let url = window.location.href;

        //console.log(url)
        const temp = this.l.path();
        const arr = temp.split('?');
        if (val === 'fr') {
            url = url.replace('/en/', '/' + val + '/');
        }
        if (val === 'en') {
            url = url.replace('/fr/', '/' + val + '/');
            arr[0] = arr[0].replace('/fr', '/' + val);
        }
        window.open(url, '_self');
    }


}
