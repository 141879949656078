import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { CartPrice } from '../../../../projects/shared-lib/src/lib/models/cart-price';
import { Parking } from '../../../../projects/shared-lib/src/lib/models/parking';
import { CartService } from '../../shared/services/cart.service';
import { Cart } from '../../../../projects/shared-lib/src/lib/models/cart';
import { Page } from '../../models/page';

import { PriceWeb } from '../../../../projects/shared-lib/src/lib/models/price-web';

export interface TotalPrices {
    total: number;
    qty: number;
}

export interface SelectSamePriceForAllItems {
    nbItem: number;
    firstItems?: { codePrice: string, qty: number ,price?:CartPrice}[];
}

@Injectable({
    providedIn: 'root'
})
export class PriceManagerService {
    priceSelected: PriceWeb[] = [];
    totalSameNbTicketForAllItems = 0;
    private subjectTotal = new Subject<TotalPrices>();
    private subjectPriceSelection = new Subject<any>();

    selectSamePriceForAllItems = new BehaviorSubject<SelectSamePriceForAllItems>(null);


    constructor(private cartService: CartService) {
    }

    watchTotal(): Observable<TotalPrices> {
        return this.subjectTotal.asObservable();
    }

    watchPriceSelection(): Observable<any> {
        return this.subjectPriceSelection.asObservable();
    }

    setPricesSelect(val: PriceWeb[]) {
        this.priceSelected = val;
    }

    qtyMinusOne(price: CartPrice): number {

        if (price.cartDetailToken) {
            price.qty = price.cartDetailToken.length;
        }
        let futureQty = 0;
        if (price.divisor && price.divisor > 0) {
            futureQty = price.qty - price.divisor;
        } else {
            futureQty = price.qty - 1;
        }

        price.qty = futureQty;

        if (price.qty < price.minTickets) {
            price.qty = 0;
        }
        if (price.minTickets <= price.qty || price.qty === 0) {
            price.total = (price.serviceCharges + price.price) * price.qty;
        }

        this.deleteAllQTYPriceEqualZero();
        this.removePrice(price);
        this.getTotalForAllPrice();
        return price.qty;
    }


    qtyPlusOne(price: CartPrice): number {
        /**
         * const va = this.gePriceByCart();
         if (va && va.cartDetailToken) {
            price.qty = va.cartDetailToken.length;
        }
         */
        if (!price.qty) {
            price.qty = 0;
        }
        let futureQty = 0;
        if (price.divisor && price.divisor > 0) {
            futureQty = price.qty + price.divisor;
        } else {
            futureQty = price.qty + 1;
        }

        if (futureQty >= 0 &&
            (futureQty <= price.maxTickets || price.maxTickets === 0)
        ) {
            price.qty = futureQty;
            if (price.qty === 1 && price.minTickets > 0) {
                price.qty = price.minTickets;
            }
            if (price.minTickets <= price.qty) {
                price.total = (price.serviceCharges + price.price) * price.qty;
            }
        }
        //console.log('price:', price);
        this.addPrice(price);
        this.getTotalForAllPrice();
        return price.qty;
    }

    getFutureQtyPlusOne(price) {
        let futureQty = price?.qty || 0;
        if (price.divisor && price.divisor > 0) {
            futureQty = price.qty + price.divisor;
        } else {
            futureQty = price.qty + 1;
        }
        if (price.maxTickets && futureQty > price.maxTickets) {
            futureQty = price.maxTickets;
        }
        if (futureQty >= 0 && (futureQty <= price.maxTickets || price.maxTickets === 0) ) {
            if (futureQty === 1 && price.minTickets > 0) {
                futureQty = price.minTickets;
            }
        }
        return futureQty;
    }
    getFutureQtyMinusOne(price) {
        let futureQty = price?.qty || 0;
        if (price.divisor && price.divisor > 0) {
            futureQty = price.qty - price.divisor;
        } else {
            futureQty = price.qty - 1;
        }
        if (futureQty < price.minTickets) {
            futureQty = 0;
        }
        return futureQty;
    }

    clearPricesSelected() {
        this.priceSelected = [];
        this.subjectPriceSelection.next([]);
        this.getTotalForAllPrice();
    }

    getPricesSelected() {
        return this.priceSelected;
    }

    getEventsHttpParams(httpParams?: HttpParams): HttpParams {
        if (!httpParams) {
            httpParams = new HttpParams();
        }
        const temp = this.priceSelected.map(item => item.eventId)
            .filter((value, index, self) => self.indexOf(value) === index);
        if (temp.length === 0) {
            return httpParams;
        }
        httpParams = httpParams.set('events', temp.join(','));
        return httpParams;
    }

    addValidPrice(price: PriceWeb) {
        this.addValidPriceWithoutObserver(price);
        this.addPrice(price);
        this.getTotalForAllPrice();
    }

    addValidPriceWithoutObserver(price: PriceWeb) {
        const futureQty = price.qty;

        if (futureQty >= 0 &&
            (futureQty <= price.maxTickets || price.maxTickets === 0)
        ) {
            price.qty = futureQty;
            if (price.qty === 1 && price.minTickets > 0) {
                price.qty = price.minTickets;
            }
            if (price.minTickets <= price.qty) {
                price.total = (price.serviceCharges + price.price) * price.qty;
            }
        } else {
            throw  new Error('invalid qty =' + price.qty + ' Min Tickets =' + price.minTickets + ' Max Tickets = ' + price.maxTickets);
        }

    }

    addPrice(price: PriceWeb) {
        //console.log(price);
        //console.log(this.priceSelected);
        this.priceSelected = this.priceSelected.filter(val => (!this.hasSamePrice(val, price) && val.qty > 0));
        if (price.qty > 0) {
            this.priceSelected.push(price);

        }
        this.subjectPriceSelection.next(this.priceSelected);

        //console.log(this.priceSelected)
        // console.log('###############################')
    }

    addPrices(prices: PriceWeb[]) {
        this.priceSelected = prices;
        this.subjectPriceSelection.next(prices);
    }

    removePrice(price: PriceWeb) {
        this.priceSelected = this.priceSelected
            .filter(val => (!this.hasSamePrice(val, price) && val.qty > 0))
            .filter(val => !(price.eventId === val.eventId && val.parkingId && price.qty <= 0));

        if (price.qty > 0) {
            this.priceSelected.push(price);
        }
        this.subjectPriceSelection.next(this.priceSelected);
    }

    addPriceParking(priceParking: Parking, date: any, qty: number) {
        const temp = <PriceWeb>{
            subscriptionId: null,
            byocId: null,
            eventId: priceParking.eventId,
            price: priceParking.amount,
            parkingId: priceParking.id,
            parkingDate: date,
            parkingType: priceParking.type,
            qty: qty,
            total: priceParking.amount * qty
        };
        this.priceSelected = this.priceSelected.filter(val => (!this.hasSamePrice(val, temp) && val.qty > 0));
        this.priceSelected.push(temp);
        console.log('priceslected', this.priceSelected);
        // The parking is tracked when displayed
        this.deleteAllQTYPriceEqualZero();
    }

    hasSamePrice(val: PriceWeb, val2: PriceWeb): boolean {
        return val.eventId === val2.eventId &&
            val.id === val2.id &&
            val.subscriptionId === val2.subscriptionId &&
            val.hoursId === val2.hoursId &&
            val.comboId === val2.comboId &&
            val.parkingId === val2.parkingId &&
            val.parkingDate === val2.parkingDate &&
            val.byocId === val2.byocId &&
            val.giftCertificatesId === val2.giftCertificatesId &&
            val.comboGroupId === val2.comboGroupId;
    }

    deleteAllQTYPriceEqualZero() {
        this.priceSelected = this.priceSelected.filter(val => (val.qty > 0));
    }

    doSinglePricesInPriceLevelConfirmed() {
        this.priceSelected = this.priceSelected.filter(val => (val.qty > 0));
    }


    getTotalForAllPrice() {
        let total = 0;
        let qty = 0;
        this.priceSelected.forEach(p => {
            total = total + p.total;
            qty = qty + p.qty;
        });

        this.subjectTotal.next(<TotalPrices>{
            qty: qty,
            total: total
        });
    }

    updatePriceSeats(val: PriceWeb, seatIds: any[]) {
        this.priceSelected.forEach(value => {
            if (value.id === val.id) {
                value.seatIds = seatIds;
            }
        });
    }

    /**
     * pour subscription stfx university
     * ***/
    isValidToSamePriceForAllItems(page: Page) {
        this.totalSameNbTicketForAllItems = 0;
        const c = <Cart>this.cartService.cartSub.value;
        if (!page || !page.subscriptions || page.subscriptions.length === 0 || !c || c.cartElements.length === 0) {
            return false;
        }
        const m = [];
        c.cartElements.forEach(value => {
            if (value._type === 'subscription' && this.isValidSubscriptions(value.id, page)) {
                value.prices.forEach(price => {
                    const id = String(price.subscriptionId) + 'tag';
                    price.qty = price.cartDetailToken.length;
                    if (!m[id]) {
                        m[id] = [];
                        m[id].push(price);
                    } else {
                        m[id].push(price);
                    }
                });
            }

        });
        console.log(m);
        Object.keys(m)?.forEach(value => {
            const prices = m[value];
            prices.forEach(p => {
                this.totalSameNbTicketForAllItems = this.totalSameNbTicketForAllItems +
                    (p.qty * (p.price + p.serviceCharges));
            });

        });
        console.log(this.totalSameNbTicketForAllItems);
        const firstItems = [];
        c.cartElements.forEach(value => {
            if (value._type === 'subscription' && this.isValidSubscriptions(value.id, page)) {
                value.prices.forEach(pc => {
                    if (pc.subscriptionId === page.subscriptions[0].id) {
                        firstItems.push({
                            codePrice: this.getUniqueName(pc),
                            qty: pc.cartDetailToken.length
                        });
                    }
                });
            }

        });

        //console.log(Object.keys(m).length);

        if (page.subscriptions.length !== Object.keys(m).length) {
            return false;
        }
        let valid = true;

        Object.keys(m).forEach(value => {
            const priceWebs = m[value];
            if (priceWebs.length !== firstItems.length) {
                valid = false;
            }
            priceWebs.forEach(p => {
                if (!(firstItems.find(pc => pc.codePrice === this.getUniqueName(p) && p.qty === pc.qty))) {
                    valid = false;
                }
            });

        });
        return valid;
    }

    isValidSubscriptions(id, page: Page) {
        if (!page.subscriptions) {
            return false;
        }
        return page.subscriptions.find(val => val.id === id) !== undefined;
    }

    getUniqueName(p: PriceWeb) {
        return p.priceLevel + p.priceType;
    }

    /**
     *
     * gePriceByCart(price): CartPrice {
      const priceCart =  this.cartService.cart.cartElements.find(value => value.id === price.eventId);
      return priceCart;
    }
     */

}
