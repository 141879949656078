import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, forkJoin, lastValueFrom, Observable, of} from 'rxjs';
import {flatMap, map, mergeMap} from 'rxjs/operators';
import {Client} from '../models/client';
import {SessionLocaleService} from '../shared/services/session-locale.service';
import {ClientDetails} from '../models/client-details';
import {WebsiteSaleService} from '../shared/services/website-sale.service';
import {AuthServiceConfigItem, AuthServiceSocial, AuthServiceSocialConfig} from '../shared/sociallogin/auth-service-social.service';
import {FacebookLoginProvider, GoogleLoginProvider} from '../shared/sociallogin/providers';
import {FacebookUser} from '../models/facebook-user';
import {GoogleUser} from '../models/google-user';
import {FlexPassResponse} from '../models/flex-pass-response';
import {ExchangeFlexPassRequest} from '../models/exchange-flex-pass-request';
import {XVerifyResponseContainer} from '../models/client-xverify-response';
import {HelpsService} from '../shared/services/helps.service';
import {SymplifyCartService} from '../shared/services/symplify-cart.service';
import {CartService} from '../shared/services/cart.service';


@Injectable({
    providedIn: 'root'
})
export class ClientService {
    private client: ClientDetails;
    authServiceSocial: AuthServiceSocial;
    hasGoogle = false;
    hasFacebook = false;
    subLogin = new BehaviorSubject(null);
    _subNewsletter = new BehaviorSubject(null);

    constructor(private http: HttpClient,
                private sessionLocaleService: SessionLocaleService,
                private websiteSaleService: WebsiteSaleService,
                public symplifyCartService: SymplifyCartService) {

    }


    createInstanceAuthServiceSocial() {
        const provide = <AuthServiceConfigItem[]>[];
        const configWebsite = this.websiteSaleService.getLocalConfig();
        if (configWebsite.social) {
            Object.keys(configWebsite.social).forEach(value => {

                if (value === 'FACEBOOK') {
                    const version = configWebsite.facebookVersion || 'v3.2'; // 2.8
                    this.hasFacebook = true;
                    const facebookLoginProvider = new FacebookLoginProvider(configWebsite.social[value].trim());
                    facebookLoginProvider.version = version.trim();
                    provide.push({
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: facebookLoginProvider
                    });
                }
                if (value === 'GOOGLE') {
                    this.hasGoogle = true;
                    provide.push({
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(configWebsite.social[value].trim())
                    });
                }
            });
        }
        this.authServiceSocial = new AuthServiceSocial(new AuthServiceSocialConfig(provide));
        //this.authServiceSocial = new AuthServiceSocial(authServiceConfig);
    }


    loginClient(body: any, httpParams ?: HttpParams): Observable<any> {
        return this.http.post<any>('v1/clients/actions/authorize', body, {params: httpParams});
        //     .pipe(
        //     mergeMap(() => {
        //         const acc = this.websiteSaleService.getLocalConfig();
        //         return this.isLoggedIn(acc.accountCode);
        //     }),
        //     mergeMap(() => {
        //         return this.getClientSelf();
        //     })
        // );
    }

    async loginClientAsync(body: any, httpParams ?: HttpParams) {
        await lastValueFrom(this.loginClient(body, httpParams));
        const acc = this.websiteSaleService.getLocalConfig();
        const client= await lastValueFrom(this.getClientSelf(new HttpParams().set('cache', '0')));
        const isLogin = await lastValueFrom(this.isLoggedIn(acc.accountCode));
        return  client;
    }


    logoutClient(accountCode: string, httpParams ?: HttpParams): Observable<any> {
        this.authServiceSocial?.signOut().then(() => {
            this.setClient(null);
            this.loggedOutFromSession();
        });
        return this.http.post<any>('v1/accounts/' + accountCode + '/actions/logout', {}, {params: httpParams}).pipe(
            map(value => {

                this.setClient(null);
                this.loggedOutFromSession();
                return value;
            }));
    }

    getClientSelf(httpParams ?: HttpParams): Observable<ClientDetails> {
        return this.http.get<ClientDetails>('v1/clients/self', {params: httpParams}).pipe(
            map(value => {
                // this.client = value;
                this.setClient(value);
                SessionLocaleService.putSync('cl', value.id, true);
                return value;
            })
        );
    }

    setClient(val) {
        this.client = val;
        try {
            if (this.client?.email) {
                //this.symplifyCartService.registerEmail(this.client.email);
                this.symplifyCartService.client = this.client;
            } else {
                this.symplifyCartService.registerEmail(null);
                this.symplifyCartService.client = null;
            }
        } catch (e) {

        }

    }


    createClient(body: any, httpParams ?: HttpParams): Observable<any> {
        return this.http.post<any>('v1/clients', body, {params: httpParams});
    }

    updateClient(client: Client, httpParams ?: HttpParams): Observable<any> {
        return this.http.put<any>('v1/clients', client, {params: httpParams})
            .pipe(
                flatMap(() => {
                    return this.getClientSelf(new HttpParams().set('cache', '0'));
                }),
                map(value => {
                    return value;
                })
            );
    }

    changePassword(body: any, idClient: any, httpParams ?: HttpParams): Observable<any> {
        return this.http.post<any>('v1/clients/' + idClient + '/actions/change-password', body, {params: httpParams});
    }
    async changePasswordAsync(body: any, idClient: any, httpParams ?: HttpParams) {
        await lastValueFrom(this.changePassword(body,idClient,httpParams))
        await lastValueFrom(this.getClientSelf(new HttpParams().set('cache', '0')));
    }

    restPassword(body: any, httpParams ?: HttpParams): Observable<any> {
        return this.http.post<any>('v1/client/actions/reset-password', body, {params: httpParams});
    }

    sendEmailRestPassword(body: any, httpParams ?: HttpParams): Observable<any> {
        return this.http.post<any>('v1/client/actions/reset-password-request', body, {params: httpParams});
    }

    isLoggedInFromSession() {
        return SessionLocaleService.getSync('tw', true) !== null;
    }

    loggedOutFromSession() {
        SessionLocaleService.deleteByKey('cl', true);
        SessionLocaleService.deleteByKey('tw', true);
        SessionLocaleService.deleteByKey('member', true);
        this.setClient(null);
        this.subLogin.next(false);
    }

    isLoggedIn(accountCode: string, httpParams ?: HttpParams): Observable<boolean> {
        return this.http.post<boolean>('v1/accounts/' + accountCode + '/actions/authentication', {}, {params: httpParams})
            .pipe(map(value => {
                //** tw =login  **//
                if (value) {
                    SessionLocaleService.putSync('tw', new Date(), true);
                }
                this.subLogin.next(value);
                return value;
            }));
    }

    getClientLocal(): ClientDetails {
        if (this.client) {
            return JSON.parse(JSON.stringify(this.client));
        }
        return null;
    }

    getConnectedClientId(): number {
        return this.getClientLocal() ? this.getClientLocal().id : undefined;
    }

    connectFacebook(body: FacebookUser, callFrom?: string, httpParams ?: HttpParams): Observable<any> {
        // console.log(callFrom);
        return this.http.post<any>('v1/clients/facebook', body, {params: httpParams}).pipe(
            flatMap(() => {
                const acc = this.websiteSaleService.getLocalConfig();
                return this.isLoggedIn(acc.accountCode);
            }),
            flatMap(() => {
                return this.getClientSelf(new HttpParams().set('cache', '0'));
            })
        );

    }

    connectGoogle(body: GoogleUser, httpParams ?: HttpParams): Observable<any> {
        return this.http.post<any>('v1/clients/google', body, {params: httpParams}).pipe(
            flatMap(() => {
                const acc = this.websiteSaleService.getLocalConfig();
                return this.isLoggedIn(acc.accountCode);
            }),
            flatMap(() => {
                return this.getClientSelf(new HttpParams().set('cache', '0'));
            })
        );
    }

    flexPassExchange(body: ExchangeFlexPassRequest, httpParams ?: HttpParams): Observable<any> {
        const isSwt = HelpsService.getValueParam('swt');
        if (isSwt) {
            const refreshToken = HelpsService.getValueParam('refreshToken');
            return this.http.post<any>('backend/v1/cart/flexpass/exchange', body, {
                params: httpParams,
                headers: {'Authorization': 'Bearer ' + refreshToken}
            });
        }

        return this.http.post<any>('backend/v1/cart/flexpass/exchange', body, {
            params: httpParams

        });
    }

    flexPass(invoices: any, httpParams ?: HttpParams): Observable<FlexPassResponse> {
        return this.http.get<FlexPassResponse>('v1/flexpass/' + invoices + '/events', {params: httpParams});
    }

    flexPassById(flexPassId: any, httpParams ?: HttpParams): Observable<FlexPassResponse> {
        return this.http.get<FlexPassResponse>('v1/flexpass/' + flexPassId, {params: httpParams});
    }

    listFlexPass(httpParams ?: HttpParams): Observable<FlexPassResponse[]> {
        return this.http.get<FlexPassResponse[]>('v1/flexpasses', {params: httpParams});
    }

    getXVerifyEmailResponse(email: string): Observable<XVerifyResponseContainer> {
        return this.http.get<XVerifyResponseContainer>(`v1/xverify/email/` + email);
    }

    mailcheckGet(email: string): Observable<any> {
        return this.http.get<any>(`v1/mailcheck/` + email).pipe(
            flatMap(ws => {
                if (ws) {
                    return forkJoin([
                        this.getXVerifyEmailResponse(ws)]
                    );
                } else {
                    return forkJoin([
                        this.getXVerifyEmailResponse(email)]
                    );
                }
            })
        );
    }
}
