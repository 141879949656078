import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Item } from '../../../models/item';
import { fromEvent, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageSaleService } from '../../../page/services/page-sale.service';
import { MessageAction, MessageObserverService } from '../../services/message-observer.service';

@Component({
    selector: 'tw-widget-select-event',
    templateUrl: './widget-select-event.component.html',
    styleUrls: ['./widget-select-event.component.css']
})
export class WidgetSelectEventComponent implements OnInit {
    @Input() indexWeek: any;
    @Input() items: Item[];
    @Input() showPoint;
    @Output() openSelectionEvents = new EventEmitter<any>();
    @Output() eventIdSelect = new EventEmitter<any>();
    styleEventPopop: any;
    private subscription: Subscription;
    showListEvents = false;

    selectedValue: any;
    hasONSALE = false;

    constructor(public translate: TranslateService,
                private router: Router, private messageObserverService: MessageObserverService) {
    }

    ngOnInit() {
        console.log(this.showPoint)
        if (!this.showPoint) {
            this.showPoint = this.items.length >= 4;

        }
        this.items.forEach(value => {
            if (value.saleState === 'ONSALE') {
                this.hasONSALE = true;
            }
        });
        this.subscription = fromEvent(document, 'scroll')
            .subscribe(value => {
                // console.log(value);
                this.applayChange();
            });
    }

    selectChange(selectedValue: any) {

    }

    applayChange() {
        const el = document.querySelector('.cl-month-container');

        // console.log(document.querySelector(".cl-month-container").getBoundingClientRect())
        // @ts-ignore
        if (el) {
            const t = <DOMRect>el.getBoundingClientRect();
            const st = {
                position: 'fixed',
                display: this.showListEvents ? 'inherit' : 'none',
                width: t.width + 'px',
                height: t.height + 'px',
                left: t.x + 'px',
                top: t.y + 'px'

            };
            // console.log(st);
            this.styleEventPopop = st;
        }

    }

    gotoPage(id: any) {
        // console.log(id);
        // if (this.pageSaleService.page) {
        //     const t = window.location.pathname;
        //     const ar = t.split('pages/');
        //     console.log(t.split('pages/'));
        //     const u = ar[0] + 'pages/' + this.pageSaleService.page.id;
        //     this.router.navigate([`${u}/performances/${id}`], { queryParamsHandling: 'merge' })
        //         .catch(error => {
        //             console.error(error);
        //         });
        // } else {
        //     this.router.navigate([`${window.location.pathname}/performances/${id}`], { queryParamsHandling: 'merge' })
        //         .catch(error => {
        //             console.error(error);
        //         });
        // }
        //
        this.eventIdSelect.emit(null);
        setTimeout(() => {
            this.eventIdSelect.emit({ event: id, week: this.indexWeek });
        }, 700);

        // this.router.navigate([`${window.location.pathname}/performances/${id}`], { queryParamsHandling: 'merge' })
        //     .catch(error => {
        //         //  console.error(error);
        //     });
    }

    goToEvent(code) {
        this.router.navigate([`${window.location.pathname}/performances/${code}`], { queryParamsHandling: 'merge' })
            .catch(error => {
                //  console.error(error);
            });
    }

    showListEvent() {
        console.log(this.items);
        if (this.items && this.items.length === 1) {
            if (this.items[0].saleState !== 'ONSALE') {
                if (this.items[0].note) {
                    this.showNote(this.items[0]);
                }

                return;
            }

            // this.router.navigate([`${window.location.pathname}/performances/${this.items[0].id}`], { queryParamsHandling: 'merge' })
            //     .catch(error => {
            //         //  console.error(error);
            //     });
            this.eventIdSelect.emit({ event: this.items[0], week: this.indexWeek });
            return;
        }
        this.showListEvents = true;
        this.applayChange();
        this.openSelectionEvents.emit({ showListEvents: true, items: this.items });
    }

    goBack() {

        this.showListEvents = false;
        // this.applayChange();
    }

    showNote(h: Item) {
        this.messageObserverService.addMessage(<MessageAction>{

            dialog: true, message:
            h.note


            , translate: false
        });
    }
}
