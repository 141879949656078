
<!--<div style="width: 100%;text-align: right">-->
<!--    <button mat-icon-button color="primary"   [mat-dialog-close]="filmSelected$" aria-label="dd">-->
<!--        <mat-icon>close</mat-icon>-->
<!--    </button>-->
<!--</div>-->
<div class="tw-flex tw-row-reverse mat-dialog-title">

  <mat-form-field class="tw-input tw-max-width-6">
    <input matInput placeholder="{{'formMsg.filter' | translate}}*" [formControl]="queryField">
  </mat-form-field>
</div>
<mat-dialog-content class="mat-typography">
  @for (film of films$; track film; let i = $index) {
    <div class="tw-flex tw-row " style="border-bottom:1px rgba(168,168,168,0.82) solid" (click)="filmSelected(film)"
      [ngClass]="filmSelected$ && filmSelected$.value && filmSelected$.value.id === film.id? 'activeSelect':''"
      >
      <div style="margin: 20px">
        @if (film  && film.images && film.images[0] ) {
          <img style="max-width: 100px"
            [src]="film.images[0].url | twUrl"
            >
        }
      </div>
      @if (film) {
        <div style="flex-grow: 5;align-items: center;padding: 16px;"
          >
          <div style="align-items: center;">
            <h2 style="margin: 0;padding: 0">{{film.title}}</h2>
            <h3 style="margin: 0">{{film.itemHours[0].formattedDate}}</h3>
            @if (film.saleState !=='ONSALE') {
              <div>
                <h3 style="color: red">{{'checkout.soldOut' | translate}}  </h3>
              </div>
            }
            {{film.venue}}<br>
            <!--                {{film.location | json}}-->
            @if (film.location) {
              <div>
                @if (film.location.addressLine1) {
                  <span> {{film.location.addressLine1}}</span>
                  }@if (film.location.addressLine1) {
                  <br>
                  }
                  @if (film.location.city) {
                    <span> {{film.location.city}}</span>
                  }
                  @if (film.location.provinceState) {
                    <span>, {{film.location.provinceState}} </span>
                  }
                  @if (film.location.postalCode) {
                    <span>, {{film.location.postalCode}}</span>
                  }
                  @if (film.location.country) {
                    <span>, {{film.location.country}}</span>
                  }
                </div>
              }
            </div>
          </div>
        }
      </div>
    }

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{"title.Cancel" | translate}}</button>
    <button mat-button [mat-dialog-close]="filmSelected$" cdkFocusInitial>{{"title.OK" | translate}}</button>
  </mat-dialog-actions>


  <!---->
