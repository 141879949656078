import { NgModule } from '@angular/core';



import {  MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {  MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import {  MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import {MatInputModule } from '@angular/material/input';
import {  MatListModule } from '@angular/material/list';
import {  MatMenuModule } from '@angular/material/menu';
import {MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatExpansionModule} from '@angular/material/expansion';


@NgModule({
    imports: [
        MatInputModule,
        MatCardModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatIconModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatRadioModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatDialogModule,
        MatSnackBarModule,
        MatMenuModule, MatDatepickerModule,
        MatStepperModule,
        MatExpansionModule



    ],
    exports: [
        MatInputModule,
        MatCardModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatIconModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatRadioModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatDialogModule,
        MatSnackBarModule,
        MatMenuModule, MatDatepickerModule,
        MatStepperModule,
        MatExpansionModule


    ],
})
export class MaterialModule { }

// import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
//
// @NgModule({
//     imports: [
//         CommonModule
//     ],
//     declarations: []
// })
// export class MaterialModule { }
