import {Component, Input, OnInit} from '@angular/core';
import {InvoiceService} from '../../invoice.service';
import {ClientService} from '../../client.service';
import {Invoice} from "../../../models/invoice";
import {HttpParams} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'tw-invoice-view',
    templateUrl: './invoice-view.component.html',
    styleUrls: ['./invoice-view.component.css']
})
export class InvoiceViewComponent implements OnInit {
    @Input() invoice: Invoice;
    isShowingDetails: boolean = false;
    htmlReport: string;

    toggleDetails() {
        this.isShowingDetails = !this.isShowingDetails;
        const client = this.clientService.getClientLocal();
        const params = new HttpParams().set('s', String(this.invoice.id)).set('cl', String(client.id));
        this.invoiceService.getInvoicesHtml(params).subscribe((htmlReport) => {
            this.htmlReport = htmlReport;
        });
    }

    constructor(private invoiceService: InvoiceService,
                private clientService: ClientService, public translate: TranslateService) {
    }

    ngOnInit() {
    }

}
