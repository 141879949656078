import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { lastValueFrom, Observable } from 'rxjs';
import { ClientService } from './client.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { WebsiteSaleService } from '../shared/services/website-sale.service';
import { SessionRedisService } from '../shared/services/session-redis.service';
import {ClientDetails} from '../models/client-details';

@Injectable({
    providedIn: 'root'
})
export class ClientAuthGuardService {
    constructor(private clientService: ClientService,
                private router: Router,
                private translate: TranslateService,
                private websiteSaleService: WebsiteSaleService,
                private sessionRedisService: SessionRedisService) {
    }



    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.checkAuthentication();
    }

    async checkAuthentication(): Promise<boolean> {
        let account = this.websiteSaleService.getLocalConfig();
        //console.log('account 1*************** ', account);
        // Implement your authentication in authService

        if (!account) {
            await this.websiteSaleService.getConfigWeb();
            account = this.websiteSaleService.getLocalConfig();
            //console.log('account ############### ', account);
            //console.log('session ################ ', this.sessionRedisService.getXSessionLocal());

        }
        //console.log('session pp ################ ', this.sessionRedisService.getXSessionLocal());

        const a = await lastValueFrom(this.clientService.isLoggedIn(account.accountCode));
        if (!a) {
            let lang = this.translate.currentLang;
            if(!lang){
                lang = this.websiteSaleService.getLang();
            }
            this.router.navigate([lang + '/client/login']).then(() => {
            });
        }
        return a;
    }
}
