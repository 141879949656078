import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {Location} from '../../../../projects/shared-lib/src/lib/models/location';
import {ImageSizeEnum} from '../../../../projects/shared-lib/src/lib/models/image-size-enum';
import {MessageAction, MessageObserverService} from '../../shared/services/message-observer.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'tw-list-location-web',
    templateUrl: './list-location-web.component.html',
    styleUrls: ['./list-location-web.component.css']
})
export class ListLocationWebComponent implements OnInit {
    locations: Location[];
    load: boolean;
    pageSelected: any;
    ImageSizeEnum = ImageSizeEnum;

    constructor(public http: HttpClient,   private router: Router,
                public translate: TranslateService,
                private messageObserverService: MessageObserverService,) {
    }

    async ngOnInit() {
      await this.getLocations()
    }

    async getLocations() {
        const lc = await lastValueFrom(this.http.get<{ locations: Location[] }>('v1/locations'));
        this.locations = lc.locations;
    }

    goTaPage(page: Location) {
        this.load = true;
        this.pageSelected = page;


        this.router.navigate([this.translate.currentLang + '/locations/' + page.id],

            {queryParamsHandling: 'merge'})
            .then(value => {
                this.load = false;
            }).catch(error => {
            //console.log(error);
            this.messageObserverService.addMessage(<MessageAction>{dialog: true, message: 'page.notAccessible', translate: true});
            this.load = false;
        });
    }
}
