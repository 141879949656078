import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogMsgComponent} from '../component/dialog-msg/dialog-msg.component';

export interface MessageAction {
    id:any;
    dialog: boolean;
    config: any;
    snackBar: {
        vrPosition: string;
        hrPosition: string;
        panelClass: string;
        duration:number;
    };
    message: string;
    translate: boolean;
    title: any;
    dialogRef:boolean;
}

@Injectable({
    providedIn: 'root'
})
export class MessageObserverService {
    private message = new Subject<MessageAction>();
    dialog: MatDialogRef<DialogMsgComponent, any>

    constructor() {
    }

    watchMessage(): Observable<MessageAction> {
        return this.message.asObservable();
    }

    addMessage(messageAction: MessageAction) {
        this.message.next(messageAction);
    }
}
