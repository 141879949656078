import {ModeKeyServeEnum} from '../key-server-container/key-service.service';

export enum keyServerStateEnum {
    WAITINGROOM = 'waiting-room',
    QUEUE = 'queue',
    AUTHORIZED = 'authorized',

}

export interface KeyServer {
    state: keyServerStateEnum;
    token: string;
    onSale: number;
    message: string;
    note: string;
    onSaleEta: number;
    url: string; /** cette valeur est setté cote front-end **/
    displayPresaleBox:boolean
}

export interface KeyServerEta {
    eta: number;
    peopleBeforeMe: number;
    message: string;
    speed: number;
}
export interface KeyServerRequest {
    mode: ModeKeyServeEnum;
    id: string;
    presale: string;

}
// export class KeyServer {
//     keyServerStateEnum: keyServerStateEnum;
//     token: string;
//     onSale: number;
//     message: string;
//     onSaleEta: number;
// }

