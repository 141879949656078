import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {LoginClientComponent} from '../component/login-client/login-client.component';
import {ClientForgotPasswordComponent} from '../component/client-forgot-password/client-forgot-password.component';
import {ClientChangePasswordComponent} from '../component/client-change-password/client-change-password.component';
import {ClientProfilComponent} from '../component/client-profil/client-profil.component';
import {ClientTicketsComponent} from '../component/ticket/client-tickets/client-tickets.component';
import {InvoicesComponent} from '../component/invoices/invoices.component';
import {InvoiceViewComponent} from '../component/invoice-view/invoice-view.component';
import {ClientSignupComponent} from '../component/client-signup/client-signup.component';
import {ClientAuthGuardService} from '../client-auth-guard.service';
import {ClientSelectCheckoutMethodComponent} from '../component/client-select-checkout-method/client-select-checkout-method.component';
import {MiddlewareClientComponent} from '../component/middleware-client/middleware-client.component';
import {MiddlewareClientResolveService} from '../component/middleware-client/middleware-client-resolve.service';
import {FlexPassComponent} from '../component/flex-pass/flex-pass.component';
import { ListFlexPassComponent } from '../component/list-flex-pass/list-flex-pass.component';
import {FlexPassResolver} from '../component/flex-pass/flex-pass.resolver';
import {RedirectPasswordExpiryService} from '../../../app/redirect-password-expiry.service'


export const clientRoute: Routes = [
    {
        path: '',
        component: LoginClientComponent,
    },
    {
        path: 'login',
        component: LoginClientComponent,
    },
    {
        path: 'signup',
        component: ClientSignupComponent,
    },
    {
        path: 'client-forgot-password',
        component: ClientForgotPasswordComponent,
    },
    {
        path: 'client-reset-password/:token', /** TODO BACKEND CHANGEMENT**/
        component: ClientChangePasswordComponent,
    },
    {
        path: 'select-checkout-method',
        component: ClientSelectCheckoutMethodComponent,
    },
    {
        path: 'middlewareClient',
        component: MiddlewareClientComponent,
        resolve: {
            data: MiddlewareClientResolveService
        }
    },
    {
        path: 'profile-guest',
        component: ClientProfilComponent,
    },
    {
        path: 'event/:flexEventId/flex-pass/:invoiceId',
        component: FlexPassComponent,
        resolve:{
            data:FlexPassResolver
        }
    },
    {
        path: 'auth',
        canActivate: [ClientAuthGuardService],
        children: [
            {
                path: '',
                children: [
                    {
                        path: 'change-password',
                        component: ClientChangePasswordComponent,
                    },
                    {
                        path: 'profile',
                        canActivate: [RedirectPasswordExpiryService],
                        component: ClientProfilComponent,
                    },
                    {
                        path: 'tickets',
                        canActivate: [RedirectPasswordExpiryService],
                        component: ClientTicketsComponent,
                    },
                    {
                        path: 'invoices',
                        canActivate: [RedirectPasswordExpiryService],
                        component: InvoicesComponent,
                    },
                    {
                        path: 'invoices/:invoiceId',
                        canActivate: [RedirectPasswordExpiryService],
                        component: InvoiceViewComponent,
                    },
                    {
                        path: 'flex-pass-list',
                        canActivate: [RedirectPasswordExpiryService],
                        component: ListFlexPassComponent,
                    }
                ]
            }
        ]
    }

];
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(clientRoute)
    ],
    declarations: [],
    exports: [RouterModule]
})
export class ClientRoutingModule { }
