import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { environment } from '../../../../environments';


@Directive({
    selector: '[twHideByEnvironment]'
})
export class HideByEnvironmentDirective {
    @Input() set environment(env: string) {
        //console.log(environment.systemValues, env);


        if (env.search(environment.systemValues) !== -1) {
           // console.log(environment.systemValues, env);
            this.elementRef.nativeElement.style.display = 'none';
        }
    }

    constructor(private elementRef: ElementRef) {
    }
}
