import {BaseLoginProvider} from '../entities/base-login-provider';
import {AuthResponseFacebook, SocialUser} from '../entities/user';
import {LoginOpt} from '../auth-service-social.service';

declare let FB: any;

export class FacebookLoginProvider extends BaseLoginProvider {

    public static readonly PROVIDER_ID = 'FACEBOOK';

    constructor(
        private _clientId: string,
        private _opt: LoginOpt = {scope: 'email,public_profile'},
        private _locale: string = 'en_US',
        private _fields: string = 'name,email,picture,first_name,last_name',
        private _version: string = 'v3.2'
    ) {
        super();
    }


    set clientId(value: string) {
        this._clientId = value;
    }

    set opt(value: LoginOpt) {
        this._opt = value;
    }

    set locale(value: string) {
        this._locale = value;
    }

    set fields(value: string) {
        this._fields = value;
    }

    set version(value: string) {
        this._version = value;
    }

    initialize(): Promise<void> {
        return new Promise((resolve, reject) => {
            // console.log({
            //
            //     appId: this._clientId,
            //     autoLogAppEvents: true,
            //     cookie: true,
            //     xfbml: true,
            //     version: this._version
            // }
            // )
            BaseLoginProvider.loadScript(FacebookLoginProvider.PROVIDER_ID,
                `//connect.facebook.net/${this._locale}/sdk.js`,
                () => {
                    FB.init({
                        appId: this._clientId,
                        autoLogAppEvents: true,
                        cookie: true,
                        xfbml: true,
                        version: this._version
                    });
                    // FB.AppEvents.logPageView(); #FIX for #18

                    this._readyState.next(true);
                    // @ts-ignore
                    resolve('ok');
                });
        });
    }

    getLoginStatus(): Promise<SocialUser> {
        return new Promise((resolve, reject) => {
            this.onReady().then(() => {
                try {
                    //console.log(FB.getUserID())
                    FB.getLoginStatus((response: any) => {
                        //console.log(response)
                        if (response.status === 'connected') {
                            let authResponse = <AuthResponseFacebook>response.authResponse;
                            FB.api(`/me?fields=${this._fields}`, (fbUser: any) => {
                                let user: SocialUser = new SocialUser();
                                user.id = fbUser.id;
                                user.name = fbUser.name;
                                user.email = fbUser.email;
                                user.photoUrl = `https://graph.facebook.com/'${fbUser.id}/picture?type=normal`;
                                user.firstName = fbUser.first_name;
                                user.lastName = fbUser.last_name;
                                user.authToken = authResponse.accessToken;
                                user.facebook = fbUser;
                                user.facebookAuthResponse = authResponse;
                                resolve(user);
                            });
                        }
                    })
                }catch (e) {
                    //console.log(e)
                }

            });
        });
    }

    signIn(opt?: LoginOpt): Promise<SocialUser> {
        return new Promise((resolve, reject) => {
            this.onReady().then(() => {
                FB.login((response: any) => {
                   // console.log(response)
                    if (response.authResponse) {
                        let authResponse = response.authResponse;
                        FB.api(`/me?fields=${this._fields}`, (fbUser: any) => {
                            let user: SocialUser = new SocialUser();
                            if(fbUser && fbUser.error){
                                reject(fbUser.error.message);
                            }
                            user.id = fbUser.id;
                            user.name = fbUser.name;
                            user.email = fbUser.email;
                            user.photoUrl = `https://graph.facebook.com/${fbUser.id} /picture?type=normal`;
                            user.firstName = fbUser.first_name;
                            user.lastName = fbUser.last_name;
                            user.authToken = authResponse.accessToken;
                            user.facebook = fbUser;
                            user.facebookAuthResponse = authResponse;
                            resolve(user);
                        });
                    } else {
                        reject('User cancelled login or did not fully authorize.');
                    }
                }, this._opt);
            });
        });
    }

    signOut(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.onReady().then(() => {
                FB.logout((response: any) => {
                    resolve("ok");
                });
            });
        });
    }

}
