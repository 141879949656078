import {Component, OnInit} from '@angular/core';
import {TicketElements} from '../../../../models/ticket-elements';
import {TicketsService} from '../../../tickets.service';
import {AnalyticsService} from '../../../../shared/services/analytics.service';


@Component({
    selector: 'tw-client-tickets',
    templateUrl: './client-tickets.component.html',
    styleUrls: ['./client-tickets.component.css']
})
export class ClientTicketsComponent implements OnInit {
    tickets: TicketElements;

    constructor(private ticketService: TicketsService,
                private analyticsService: AnalyticsService) {
    }


    ngOnInit() {
        this.analyticsService.trackUpcomingTicketsView();
        this.ticketService.getUpcomingTickets().subscribe((tickets: TicketElements) => {
            this.tickets = tickets;
        });
    }
}
