import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {Cart} from '../../../../projects/shared-lib/src/lib/models/cart';
import {distinctUntilChanged, filter, flatMap, groupBy, map, mergeMap, toArray} from 'rxjs/operators';
import {HoldItemRequest, ItemType} from '../../models/hold-item-request';
import {ReserveTicketPrice} from '../../models/reserve-ticket-price';
import {PriceWeb} from '../../../../projects/shared-lib/src/lib/models/price-web';
import {CartElement} from '../../../../projects/shared-lib/src/lib/models/cart-element';
import {CartItem} from '../../../../projects/shared-lib/src/lib/models/cart-item';
import {CartDetailToken} from '../../../../projects/shared-lib/src/lib/models/cart-detail-token';
import {Address} from '../../models/address';
import {Phone} from '../../models/phone';
import {Tax} from '../../models/tax';
import {SplitHoldRequest} from '../../models/split-hold-request';
import {CreditCard} from '../../models/credit-card';
import {SymplifyCartService} from './symplify-cart.service';
import {CartPrice} from '../../../../projects/shared-lib/src/lib/models/cart-price';

@Injectable({
    providedIn: 'root'
})
export class CartService {

    cartSub = new BehaviorSubject<Cart | CreditCard | any>(null);

    constructor(private http: HttpClient, public symplifyCartService: SymplifyCartService) {
    }

    static getEventsHttpParams(cartElements: CartElement[]): Observable<number[]> {

        const source = from(cartElements);
        return source.pipe(
            flatMap(ct => ct.prices),
            map(p => p.eventId),
            distinctUntilChanged(),
            toArray()
        );
    }

    static getAddressByType(ads: Address[], type: string): Address {
        for (let index = 0; index < ads.length; index++) {
            if (type === ads[index].type) {
                return ads[index];
            }
        }
        return null;
    }

    static getPhoneByType(ads: Phone[], type: string): Phone {
        if(!ads){
            return null;
        }
        for (let index = 0; index < ads.length; index++) {
            if (type === ads[index].type) {
                return ads[index];
            }
        }
        return null;
    }


    getCart(httpParams ?: HttpParams): Observable<Cart> {
        if (!httpParams) {
            httpParams = new HttpParams().set('cache', '0');
        } else {
            httpParams = httpParams.set('cache', '0');
        }
        return this.http.get<Cart>('v1/cart/self', {params: httpParams}).pipe(
            map(value => {

                value.cartElements.forEach(value1 => {
                    if (value1._type !== 'subscription') {
                        value1.prices = value1.prices.filter(p => p.confirmed);
                    }
                });
                value.cartElements = value.cartElements.filter(value1 => value1.prices.length > 0);

                // if (value.reservationTime) {
                //     value.reservationTime = value.reservationTime - (10 * 60 * 1000);
                // }
                this.cartSub.next(value);
                return value;
            })
        );
    }

    deleteAllCart(): Observable<any> {
        this.symplifyCartService.resetCart();
        return this.http.delete<any>('v1/cart/delete');

    }

    createHolds(body: HoldItemRequest[]) {
        return this.http.post<any>('v1/holds', body);
    }

    deleteHolds(ct: CartItem[], httpParams ?: HttpParams) {
        return this.http.post<any>('v1/holds/actions/release', ct, {params: httpParams});
    }

    getPriceLevelHolds(priceLevels: number | string[], performancesId, promo, httpParams = new HttpParams) {
        if (promo) {
            httpParams = httpParams.set('promo', promo);
        }
        return this.http.post<any>('v1/holds-pricelevel/' + performancesId, {
            priceLevels: priceLevels
        }, {params: httpParams});
    }

    getPriceLevelHoldsForSubscription(priceLevels: number | string[], subscriptionId, promo, httpParams = new HttpParams) {
        if (promo) {
            httpParams = httpParams.set('promo', promo);
        }
        return this.http.post<any>('v1/holds-pricelevel-subscription/' + subscriptionId, {
            priceLevels: priceLevels
        }, {params: httpParams});
    }

    splitPriceLevelHolds(splitHoldRequest: SplitHoldRequest[]) {
        return this.http.post<any>('v1/holds-pricelevel/split', splitHoldRequest);
    }

    deleteAll(cartElements: CartElement[]): Observable<any> {
        const cart = this.convertToCartItem(cartElements);
        this.cartSub.next(null);
        return this.deleteHolds(cart).pipe(
            flatMap(() => {
                return this.deleteAllCart();
            })
        );
    }

    clearHoldsTicketNotConfirmed() {
        return this.http.delete<any>('v1/cart/unconfirmed');
    }

    clearCart(): Observable<any> {
        const cart = this.cartSub.value;
        if (cart && cart.cartElements && cart.cartElements.length > 0) {
            return this.deleteAll(cart.cartElements);
        }
        return of('ok');
    }

    getTaxByPrice(idEvent: any, idPrices: any): Observable<Tax> {
        return this.http.get<Tax>('v1/taxes/' + idEvent + '/' + idPrices);
    }

    getEventTax(idEvent: any, idPrices: any): Observable<Tax> {
        return this.http.get<Tax>('v1/events/' + idEvent + '/taxes/' + idPrices);
    }

    getSubscriptionTax(idSubscription: any, idPrices: any): Observable<Tax> {
        return this.http.get<Tax>('v1/subscriptions/' + idSubscription + '/taxes/' + idPrices);
    }

    getMemberTax(idMember: any): Observable<Tax> {
        return this.http.get<Tax>('v1/members/' + idMember + '/taxes');
    }

    formatPriceHoldToReserveTicketsRequest(priceDetails: PriceWeb[], keyServerToken?: string, urlImage?: string): HoldItemRequest[] {
        // console.log(priceDetails);
        const source = from(<PriceWeb[]>priceDetails);
        const holdItemRequest: HoldItemRequest[] = [];
        const urlPage = window.location.href;

        const events = source.pipe(
            filter(p => (
                p.subscriptionId === null
                    || p.subscriptionId === undefined)
                && (p.byocId === null || p.byocId === undefined)
                && (p.comboId === null || p.comboId === undefined)
                && p.parkingId === undefined
                && (p.memberId === null || p.memberId === undefined)
                && (p.giftCertificatesId === null || p.giftCertificatesId === undefined)
            ),
            groupBy(person => person.eventId),
            mergeMap(group => group.pipe(toArray()))
        );
        events.subscribe((val) => {
            //  console.log(val);
            const temp = <HoldItemRequest>{
                prices: this.convertPricesToReserveTicketPrice(val),
                keyserverToken: keyServerToken || null,
                type: ItemType.SHOW,
                id: val[0].eventId
            };
            //  console.log(temp);
            holdItemRequest.push(temp);
        });

        const member = source.pipe(
            filter(p => (p.memberId !== null && p.memberId !== undefined)),
            groupBy(p => p.memberId),
            mergeMap(group => group.pipe(toArray()))
        );
        member.subscribe((val) => {
            const temp = <HoldItemRequest>{
                prices: this.convertPricesToReserveTicketPrice(val),
                keyserverToken: keyServerToken || null,
                type: ItemType.MEMBER,
                id: val[0].memberId,
                memberId: val[0].memberId
            };
            holdItemRequest.push(temp);
        });

        const combo = source.pipe(
            filter(p => (p.comboId !== null && p.comboId !== undefined)),
            groupBy(person => person.eventId),
            mergeMap(group => group.pipe(toArray()))
        );
        combo.subscribe((val) => {
            const temp = <HoldItemRequest>{
                prices: this.convertPricesToReserveTicketPrice(val),
                keyserverToken: keyServerToken || null,
                type: ItemType.COMBO,
                id: val[0].eventId,
                comboGroupId: val[0].comboGroupId,
                comboId: val[0].comboId,
                comboQty: val[0].qtyCombo
            };
            holdItemRequest.push(temp);
        });

        const comboByoc = source.pipe(
            filter(p => (p.byocId !== null && p.byocId !== undefined)),
            groupBy(person => person.eventId),
            mergeMap(group => group.pipe(toArray()))
        );
        comboByoc.subscribe((val) => {
            const temp = <HoldItemRequest>{
                prices: this.convertPricesToReserveTicketPrice(val),
                keyserverToken: keyServerToken || null,
                type: ItemType.BYOC,
                id: val[0].eventId,
                byocId: val[0].byocId
            };
            holdItemRequest.push(temp);
        });


        const SUBSCRIPTION = source.pipe(
            filter(p => p.subscriptionId !== null && p.subscriptionId !== undefined),
            groupBy(person => person.subscriptionId),
            mergeMap(group => group.pipe(toArray()))
        );
        SUBSCRIPTION.subscribe((val) => {
            const temp = <HoldItemRequest>{
                prices: this.convertPricesToReserveTicketPrice(val),
                keyserverToken: keyServerToken || null,
                type: ItemType.SUBSCRIPTION,
                id: val[0].subscriptionId
            };
            holdItemRequest.push(temp);
        });


        /** TODO PARKING **/
        const PARKING = source.pipe(
            filter(p => p.parkingId !== undefined),
            groupBy(pe => pe.eventId),
            mergeMap(group => group.pipe(toArray()))
        );
        PARKING.subscribe((val) => {
            // res.push(val)
            const temp = <HoldItemRequest>{
                prices: this.convertPricesToReserveTicketPrice(val),
                keyserverToken: keyServerToken || null,
                type: ItemType.PARKING,
                id: val[0].eventId,
                pageUrl: urlPage,
                imageUrl: urlImage
            };
            holdItemRequest.push(temp);
        });
        const giftCertificates = source.pipe(
            filter(p => (p.giftCertificatesId !== null && p.giftCertificatesId !== undefined)),
            groupBy(p => p.giftCertificatesId),
            mergeMap(group => group.pipe(toArray()))
        );
        giftCertificates.subscribe((val) => {
            const temp = <HoldItemRequest>{
                prices: this.convertPricesToReserveTicketPrice(val),
                keyserverToken: keyServerToken || null,
                type: ItemType.GIFT_CERTTIFICATES,
                id: val[0].giftCertificatesId,
                giftCertificatesId: val[0].giftCertificatesId
            };
            holdItemRequest.push(temp);
        });

        return holdItemRequest;

    }

    convertPricesToReserveTicketPrice(p: PriceWeb[] | CartPrice[] | any[]): ReserveTicketPrice[] {
        const temp: ReserveTicketPrice[] = [];
        p.forEach(price => {
            //console.log(price)
            const reserveTicketPrice = <ReserveTicketPrice>{
                qty: price.qty,
                promoId: price.promoId,
                id: price.id,
                expositionId: price.hoursId,
                seatIds: price.seatIds,
                parkingId: price.parkingId || null,
                parkingDate: price.parkingDate || '',
                parkingType: price.parkingType || '',
                presale: this.getPresale(price),
                confirmed: price.confirmed,
                amount: price.price + price.serviceCharges,
                referenceId: price.referenceId,
                comboGroupDetailId: price.comboGroupDetailId,
                giftCertificatesId:price.giftCertificatesId,
                imageUrl: price.imageUrl,
                pageUrl: price.pageUrl,
                venue: price.venue,
                date: price.date,
                title: price.title


            };
            temp.push(<ReserveTicketPrice>reserveTicketPrice);
        });
        return temp;

    }

    convertToCartItem(cartElements: CartElement[]): CartItem[] {
        const temp: CartItem[] = [];
        cartElements.forEach(car => {
            car.prices.map(p => {
                const cat = <CartItem>{
                    eventId: p.eventId,
                    subscriptionId: p.subscriptionId,
                    reservationId: null,
                    clientSubscriptionId: null,
                    holds: this.formatToHold(p.cartDetailToken),
                    questionResponse: null,
                    shipping: null,
                    qty: null,
                    parkingId: p.parkingId || null,
                    parkingDate: null,
                    presale: this.getPresale(p)
                };
                temp.push(cat);
            });
        });
        return temp;
    }

    getPresale(price: any) {
        if (!price) {
            return null;
        }
        if (price.presale) {
            return price.presale;
        }
        if (price.presaleCode) {
            return price.presaleCode;
        }

        return null;
    }


    formatToHold(cartDetailTokens: CartDetailToken[]) {
        const temp = [];
        cartDetailTokens.forEach(p => {
            temp.push(p.idCartLine);
        });
        return temp;
    }



}
