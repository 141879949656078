import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';

@Pipe({
    name: 'twDatePipe'
})
export class TwDatePipePipe implements PipeTransform {

    constructor(public translate: TranslateService) {
        registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
    }

    transform(value: any, format?: string): any {
        const datePipe = new DatePipe(this.translate.currentLang);
        return datePipe.transform(value, format, '', this.translate.currentLang);
    }

}
