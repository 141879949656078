<div style="padding-top: 4px">

  @if ( location) {
    <div class="cart-line-location" twHideByEnvironment environment="feq mta"
      style="font-size: 16px; padding-left: 11px; padding-top: 8px;">
      @if (location.name) {
        <strong>{{location.name}} </strong>
        }<br>
        @if (location.addressLine1) {
          <span>{{location.addressLine1}} </span>
        }
        @if (location.addressLine2) {
          <span>{{location.addressLine2}}, </span>
        }
        @if (location.city) {
          <span>{{location.city}}, </span>
        }
        @if (location.provinceState) {
          <span>{{location.provinceState}}</span>
        }
        @if (location.postalCode) {
          <span>, {{location.postalCode}}</span>
        }
        &nbsp;
        <a href="{{location | twGoogleMaps}}" target="_blank"><img src="./assets/resources/svg/map_marker.svg"
        style="height: 16px;" alt="marker"></a>
      </div>
    }

    @if (cartPrice) {
      <div>
        <!-- ***** GA and RS -->
        <ng-container>
          @if (cartPrice.hoursId) {
            <span class="tw-padding-10">{{cartPrice.fullDate }}</span>
          }
          <div class="tw-cart-line-container">
            <div class="tw-cart-line-container-row" style="flex: 3 !important;">
              <div>
                @if (cartPrice.priceLevel) {
                  <span> </span>
                  }{{cartPrice.name}}
                </div>
              </div>
              <div class="tw-cart-line-container-row" style="flex: 1;">
                @if (!cartPrice.donation) {
                  <div style="text-align: right;padding-right: 10px;">
                    {{cartPrice.cartDetailToken.length}} x
                  </div>
                }
              </div>
              <div class="tw-cart-line-container-row" style="flex: 2;">
                <div style="text-align: right;position: relative;">
                  @if (cartPrice.originalPrice >0) {
<div style="position: absolute; text-decoration: line-through;    top: -26px;
    font-size: 16px;
    right: 0;"
                      >
                      {{(cartPrice.serviceCharges + cartPrice.originalPrice) / 100 | currency :'':'symbol-narrow':'':translate.currentLang}}
                    </div>
                  }
                  <div
                    style="font-weight: bold;font-size: 16px">{{(cartPrice.serviceCharges + cartPrice.price) / 100 | currency :'':'symbol-narrow':'':translate.currentLang}}
                  </div>
                </div>
              </div>
              <div [hidden]="hideEdit" class="tw-cart-line-container-row"
                style=" flex: 0 !important; margin-top: -12px;position: relative">
                @if (!componentRef && !cartPrice.donation) {
                  <button class="tw-primary bt-cart-info-temp" style="margin: 0 4px" mat-mini-fab  color="primary"
                    (click)="infoPrice(cartPrice.eventId, cartPrice.subscriptionId, cartPrice.memberId, cartPrice.id)">
                    <mat-icon aria-label="info price" class="info-icon">info</mat-icon>
                    <div class="info-temp"></div>
                  </button>
                }
                @if (componentRef) {
                  <button class="tw-primary bt-cart-clear-temp" style="margin: 0 4px" mat-mini-fab color="primary"
                    (click)="deleteInfoPrice()">
                    <mat-icon aria-label="info price" class="clear-icon">clear</mat-icon>
                    <div class="clear-temp"></div>
                  </button>
                }
                <div class="bi-tooltip">
                  <template #viewInfoTaxPrice></template>
                </div>
              </div>
              <div [hidden]="hideEdit" class="tw-cart-line-container-row"
                style=" flex: 0 !important; margin-top: -12px;">
                <button class="tw-primary bt-cart-delete" mat-mini-fab color="primary" (click)="deletedPrice()">
                  <mat-icon aria-label="delete price" class="delete-icon">delete_forever</mat-icon>
                  <div class="delete-temp"></div>
                </button>
              </div>
            </div>
          </ng-container>
          <!-- ***** RS Only -->
          <!--{{cartPrice | json}}-->
        <div style="display: flex;
    align-items: center">
            @if (!hideEditButton && cartPrice.rs) {
              <button class="tw-primary" mat-mini-fab color="primary" (click)="detailSeats(cartPrice.visualizeSeatUrl )"
                >
                <mat-icon aria-label="delete price">picture_as_pdf</mat-icon>
              </button>
            }
            @if (!hideEditButton && cartPrice.pageUrl && websiteSaleService && websiteSaleService.configWebsite.cart) {
              <button style="margin-left: 5px;border-radius: 20px" class="tw-primary" mat-raised-button color="primary"
                (click)="goToPage(cartPrice.pageUrl )"
                >
                @if (translate.currentLang !=='fr') {
                  <span>
                    Add tickets
                  </span>
                }
                @if (translate.currentLang ==='fr') {
                  <span>
                    Ajouter des billets
                  </span>
                }
              </button>
            }
          </div>
          @if (cartPrice.rs) {
            <div class="tw-flex tw-column ">
              <div class="tw-cart-line-container"
                style="border-bottom: #0000000f 1px solid; margin: 0 4px; max-width: 400px;">
                <div class="tw-cart-line-container-row tw-flex tw-row">
                  @if (cartPrice.cartDetailToken[0].seat.level) {
                    <div class=" tw-seat">
                      <strong>{{'checkout.level' | translate}}</strong>
                    </div>
                  }
                  @if (cartPrice.cartDetailToken[0].seat.section) {
                    <div class=" tw-seat">
                      <strong>{{'checkout.section' | translate}}</strong>
                    </div>
                  }
                  @if (cartPrice.cartDetailToken[0].seat.row) {
                    <div class=" tw-seat">
                      <strong>{{'checkout.row' | translate}}</strong>
                    </div>
                  }
                  @if (cartPrice.cartDetailToken[0].seat.seat) {
                    <div class=" tw-seat" style="text-align: center">
                      <strong>{{'checkout.seat' | translate}}</strong>
                    </div>
                  }
                </div>
              </div>
              @for (rs of cartPrice.cartDetailToken; track rs) {
                <div class="tw-cart-line-container" style="margin: 0 4px; padding: 0px;max-width: 400px">
                  <div class="tw-cart-line-container-row tw-flex tw-row" style="padding: 5px;font-size: 13px">
                    @if (rs.seat.level) {
                      <div class="tw-seat">
                        {{rs.seat.level}}
                      </div>
                    }
                    @if (rs.seat.section) {
                      <div class="tw-seat">
                        {{rs.seat.section}}
                      </div>
                    }
                    @if (rs.seat.row) {
                      <div class="tw-seat">
                        {{rs.seat.row}}
                      </div>
                    }
                    @if (rs.seat.seat) {
                      <div class=" tw-seat" style="text-align: center">
                        {{rs.seat.seat}}
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
            @if (showDetailseat) {
              <div class=" details-seats" style="width: 100%;margin-top: 40px;background: white">
                <div class="mat-elevation-z3" style="width: 100% ;margin: 10px">
                  <button class="tw-primary" mat-icon-button color="primary" (click)="hideDetailSeats()">
                    <mat-icon aria-label="delete price" class="delete-icon">clear</mat-icon>
                    <div class="delete-temp"></div>
                  </button>
                  <div id="pdf-output" style=" margin: auto; width:80%;height: 600px;z-index: 1">
                  </div>
                </div>
              </div>
            }
          }
        </div>
      }
    </div>
