import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationUserService} from './authentication-user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationUserGuard  {
    constructor(private authService: AuthenticationUserService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return !this.authService.isExpirationRefreshToken();
    }
}
