import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ImageJson} from '../modules/models/image-json';
import {Location} from '../modules/models/location';
import {carousel} from './bannerDataMock';
import {Observable, of} from 'rxjs';
import {Banner} from '../../projects/shared-lib/src/lib/models/banner';

// export interface Banner {
//     url: '';
//     images: ImageJson[];
//     html: string;
//     htmlFr: string;
//     location: Location;
//
// }

@Injectable({
    providedIn: 'root'
})
export class BannersService {

    constructor(private http: HttpClient) {
    }

    getCarousel(httpParams ?: HttpParams): Observable<Banner[]> {
         return this.http.get<any>('/v1/carousel', {params: httpParams});
        //return of(<Banner[]>carousel);
    }

    getBannerPrincipal(httpParams ?: HttpParams) {
        return this.http.get<any>('v1/privileged', {params: httpParams});
    }

    getBannerSecondary(httpParams ?: HttpParams) {
        return this.http.get<any>('v1/banner', {params: httpParams});
    }
}
