

<ng-content select="title"></ng-content>
<!--{{startDate}}{{endDate}}-->
@if (styleDate === 1) {
  <div class="tw-flex tw-row tw-flex-wrap tw-margin-10 data-rang-style-1"
    id="data-rang-style-1" style="align-items: baseline;justify-content: space-between;"
    >
    <div   class="date-range-select">
      @if (hasRangDate) {
        <mat-form-field  appearance="outline"
          >
          <ng-content select="[image]"></ng-content>
          <mat-select placeholder='{{"date.date" | translate}}..' [formControl]="typeRangeControl">
            @for (it of typeRange; track it) {
              <mat-option [value]="it">
                {{"date."+it | translate}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </div>
    <div class="dateContainer data-range-body" style="align-items: baseline;justify-content: space-between">
      <div class="tw-flex tw-column date-range-c-1" >
        <!--            {{startDate | twDatePipe:'MMM d, y, h:mm:ss a'}}-->
        <mat-form-field color="accent" style=" margin-bottom: -1em" class="date date-range-1" appearance="outline">
          <mat-label>{{"date.startDate" | translate}}.</mat-label>
          <input matInput [matDatepicker]="picker1" [formControl]="startDateCtr" #startDateTemp
            >
          <mat-error>
            <tw-form-error-message [errors]="startDateTemp.errors"></tw-form-error-message>
          </mat-error>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 color="primary" [disabled]="currentTypeRange!=='other'">
            <!--                    <mat-datepicker-actions *ngIf="hasTime">-->
<!--                        <ngx-mat-timepicker-field style="    width: 100%;-->
<!--    justify-content: center;-->
<!--    display: flex;"-->
<!--                            [format]="24"-->
<!--                            [defaultTime]="startDefaultTime"-->
<!--                            (timeChanged)="startTimeChange($event)"-->
<!--                        >-->

<!--                        </ngx-mat-timepicker-field>-->
<!--                    </mat-datepicker-actions>-->


                </mat-datepicker>



            </mat-form-field>
            @if (hasTime) {
<div  class="tw-flex tw-row" style="align-content: center;
flex-wrap: wrap;
    align-content: center;
    ">
        <ngx-mat-timepicker-field [disabled]="!startDate"
          [format]="24"
          [defaultTime]="startDefaultTime"
          (timeChanged)="startTimeChange($event)"
          >
        </ngx-mat-timepicker-field>
      </div>
    }
  </div>
  <div class="tw-flex tw-column date-range-c-2" >
    @if (hasRangDate) {
      <mat-form-field  color="accent" style=" margin-bottom: -1em;" class="date date-range-2" appearance="outline">
        <mat-label>{{"date.endDate" | translate}}</mat-label>
        <input matInput [matDatepicker]="picker2" [formControl]="endDateCtr" #endDate>
        <mat-error>
          <tw-form-error-message [errors]="endDate.errors"></tw-form-error-message>
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2 color="primary" [disabled]="currentTypeRange!=='other'">
          <!--                    <mat-datepicker-actions  *ngIf="hasTime" >-->
<!--                        <ngx-mat-timepicker-field   style="    width: 100%;-->
<!--    justify-content: center;-->
<!--    display: flex;"-->
<!--                            [format]="24"-->
<!--                            [defaultTime]="endDefaultTime"-->
<!--                            (timeChanged)="endTimeChange($event)"-->
<!--                        ></ngx-mat-timepicker-field>-->
<!--                    </mat-datepicker-actions>-->

                </mat-datepicker>
            </mat-form-field>
}
            @if (hasTime) {
<div  class="tw-flex tw-row" style="align-content: center;
flex-wrap: wrap;
    align-content: center;
    ">
    <ngx-mat-timepicker-field [disabled]="!endDate"
      [format]="24"
      [defaultTime]="endDefaultTime"
      (timeChanged)="endTimeChange($event)"
    ></ngx-mat-timepicker-field>
  </div>
}
</div>
</div>
@if (hasRangDate && hasButton && typeRangeControl.value === 'other') {
  <div class="date-range-button" >
    @if (hasRangDate && hasButton && typeRangeControl.value === 'other') {
      <button  [disabled]="!startDate || !endDate"  mat-stroked-button color="primary" (click)="getDataBy()"
        class="tw-btn-hover-transition"
        >
        {{ submitButtonLabel }}
      </button>
    }
  </div>
}
</div>
}
@if (styleDate === 2) {
  <div class="tw-flex tw-column tw-center tw-margin-10">
    @if (hasRangDate) {
      <mat-form-field style="min-width: 100px ;margin-right: 10px; margin-bottom: -1em">
        <mat-select placeholder='  {{"date.date" | translate}}'   [formControl]="typeRangeControl"  >
          @for (it of typeRange; track it) {
            <mat-option [value]="it">
              {{"date."+it | translate}}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
    <div class="tw-flex tw-flex-flow-row-wrap">
      <mat-form-field color="accent" style="margin-bottom: -1em;margin-right: 2px" class="date">
        <mat-label> {{"date.startDate" | translate}}</mat-label>
        <input style="color: black;" matInput [matDatepicker]="picker1" [formControl]="startDateCtr" #startDateTemp
          [disabled]="currentTypeRange !== 'other'"
          >
        <mat-error>
          <tw-form-error-message [errors]="startDateTemp.errors"></tw-form-error-message>
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1 color="primary" ></mat-datepicker>
      </mat-form-field>
      @if (hasRangDate) {
        <mat-form-field color="accent" style=" margin-bottom: -1em" class="date">
          <mat-label>{{"date.startDate" | translate}}</mat-label>
          <input style="color: black;" matInput [matDatepicker]="picker2" [formControl]="endDateCtr" #endDate
            [disabled]="currentTypeRange !== 'other'">
          <mat-error>
            <tw-form-error-message [errors]="endDate.errors"></tw-form-error-message>
          </mat-error>
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 color="primary" ></mat-datepicker>
        </mat-form-field>
      }
    </div>
    @if (hasRangDate && hasButton && typeRangeControl.value === 'other') {
      <button style="height: 45px;margin: 6px;" mat-raised-button color="primary" (click)="getDataBy()">
        {{ submitButtonLabel }}
      </button>
    }
  </div>
}
