import {AfterViewInit, Component, ElementRef, Input} from '@angular/core';

@Component({
  selector: 'tw-show-password',
  templateUrl: './show-password.component.html',
  styleUrls: ['./show-password.component.css']
})
export class ShowPasswordComponent implements AfterViewInit{
    @Input() inputRef: any;

    ngAfterViewInit() {
        if (this.inputRef) {
            console.log(this.inputRef.type)
            const valeurInput = this.inputRef.nativeElement;
        }
    }
}
