<ul  class="tw-form-error"  style="top: 0;display: flex;flex-direction: column">
  @for (msgError of displayedFormErrors; track msgError) {
    @if (msgError.values.length>0) {
      <li [innerHTML]="msgError.message | translate:({value:msgError.values[0]})" style="font-size: 15px"></li>
    }
    @if (msgError.values.length===0) {
      <li [innerHTML]="msgError.message | translate" style="font-size: 15px"></li>
    }
  }



</ul>

