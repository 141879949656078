import {LoginProvider} from './login-provider';
import {SocialUser} from './user';
import {BehaviorSubject} from 'rxjs';

export abstract class BaseLoginProvider implements LoginProvider {

    protected _readyState: BehaviorSubject<boolean> = new BehaviorSubject(false);
    protected messageError;

    protected constructor() {
    }

    static loadScript(id: string, src: string, onload: any, async = true, inner_text_content = ''): void {
        if (document.getElementById(id)) {
            return;
        }

        const signInJS = document.createElement('script');
        signInJS.async = async;
        signInJS.src = src;
        signInJS.onload = onload;
        signInJS.text = inner_text_content; // LinkedIn
        document.head.appendChild(signInJS);
    }

    protected onReady(): Promise<void> {
        return new Promise((resolve, reject) => {
            this._readyState.subscribe((isReady) => {
                if (isReady) {
                    // @ts-ignore
                    resolve('ok');
                } else {
                 //   console.log(this.messageError);
                    reject(this.messageError);
                }
            }, error1 => {
               // console.log(error1);
            });
        });
    }

    abstract initialize(): Promise<void>;

    abstract getLoginStatus(): Promise<SocialUser>;

    abstract signIn(): Promise<SocialUser>;

    abstract signOut(revoke?: boolean): Promise<any>;


}
