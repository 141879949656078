import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from '../../modules/shared/component/page-not-found/page-not-found.component';
import {AppResolveService} from '../app-resolve.service';
import {AppResolveTranslateService} from '../app-resolve-translate.service';
import {MainAppComponent} from '../main-app/main-app.component';
import {AppComponent} from '../app.component';
import {RedirectionPageModule} from '../../modules/redirectionPage/redirection-page.module';
import {ListLocationWebComponent} from '../../modules/location-web/list-location-web/list-location-web.component';
import {LocationWebViewComponent} from '../../modules/location-web/location-web-view/location-web-view.component';
import {ClientAuthGuardService} from '../../modules/client/client-auth-guard.service';
import {RedirectPasswordExpiryService} from '../redirect-password-expiry.service';
import {CanActiveAdsPageResolver} from '../../modules/ads-pages/can-active-ads-page.resolver';


export const routes: Routes = [
    {
        path: ':lang',
        component: MainAppComponent,
        resolve: {
            data: AppResolveService
        },

        children: [
            {
                path: '',
                loadChildren: () => import('modules/page/page.module').then(m => m.PageModule)
            },
            {
                path: 'checkout',
                // canActivate: [RedirectPasswordExpiryService],
                loadChildren: () => import('modules/checkout/checkout.module').then(m => m.CheckoutModule),
                resolve: {
                    data: AppResolveTranslateService
                }
            },
            {
                path: 'cart',
                // canActivate: [RedirectPasswordExpiryService],
                loadChildren: () => import('modules/cart/cart.module').then(m => m.CartModule),
                resolve: {
                    data: AppResolveTranslateService
                }
            },
            {
                path: 'client',
                loadChildren: () => import('modules/client/client.module').then(m => m.ClientModule)
            },
            {
                path: 'seats-map/:idPerformance',
                loadChildren: () => import('modules/seats-map/seats-map.module').then(m => m.SeatsMapModule)
            },
            {
                path: 'streaming',
                loadChildren: () => import('modules/streaming/streaming.module').then(m => m.StreamingModule)
            },
            {
                path: 'vq',
                loadChildren: () => import('modules/page/ville-quebec/ville-quebec.module').then(m => m.VilleQuebecModule)
            },
            {
                path: 'redirect',
                loadChildren: () => import('modules/redirectionPage/redirection-page.module').then(m => m.RedirectionPageModule)
            },
            {
                path: 'static',
                loadChildren: () => import('modules/static-pages/static-pages.module').then(m => m.StaticPagesModule)
            },
            {
                path: 'ads-pages',
                loadChildren: () => import('modules/ads-pages/ads-pages.module').then(m => m.AdsPagesModule),
            },
            {
                path: 'subscription-renewal/:token',
                loadChildren: () => import('modules/subscription-renewal/subscription-renewal.module').then(m => m.SubscriptionRenewalModule),
            },
            {
                path: 'locations',
                component: ListLocationWebComponent
            },
            {
                path: 'locations/:id',
                component: LocationWebViewComponent
            },
            {path: '**', component: PageNotFoundComponent}
        ]
    },
    {
        path: '',
        component: AppComponent,
        resolve: {
            data: AppResolveService
        }
    },

    {path: '**', redirectTo: '/en/pages'},
    {path: 'page-not-fount', component: PageNotFoundComponent}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})
    ],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {
}
