import {Pipe, PipeTransform} from '@angular/core';
import {ImageJson} from '../models/image-json';
import {ImageSizeEnum} from '../models/image-size-enum';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'typeImageJson'
})
export class TypeImageJsonPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: ImageJson[], type: ImageSizeEnum | any, defaultImageUrl = ''): string {

        const img = value.find(value1 => value1.imageSize === type);
        console.log(img);
        if (!img) {
            return defaultImageUrl;
        }
        return img.url;
    }

}
