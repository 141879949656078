import {Component, HostBinding, OnInit} from '@angular/core';
import {WebsiteSaleService} from '../modules/shared/services/website-sale.service';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {OverlayContainer} from '@angular/cdk/overlay';
import {environment} from '../../environments';

@Component({
    selector: 'tw-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    showApp = false;
    errorPage: any;
    response = false;
    @HostBinding('class') componentCssClass;

    constructor(private websiteSaleService: WebsiteSaleService, public overlayContainer: OverlayContainer,
                public translate: TranslateService,
                public l: Location) {
    }

    async ngOnInit() {
        //console.log('entre ------------------------- 1211212');
        if (environment.systemValues.search('feq') != -1) {
            this.onSetTheme('feq-theme');
        }
        if (environment.systemValues.search('tkp') != -1) {
            this.onSetTheme('tkp-theme');
        }
        if (environment.systemValues.search('mta') != -1) {
            this.onSetTheme('mta-theme');
        }

        try {
            await this.websiteSaleService.getConfigWeb();
            this.showApp = true;
            this.response = true;
        } catch (error1) {
            this.showApp = false;
            this.errorPage = error1;
            this.response = true;
        }

    }

    onSetTheme(theme) {
        // console.log(theme);
        this.overlayContainer.getContainerElement().classList.add(theme);
        this.componentCssClass = theme;
    }
}
