import { Component, Input, OnInit } from '@angular/core';
import { Client } from '../../../models/client';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from '../../../client/client.service';

@Component({
    selector: 'tw-client-view',
    templateUrl: './client-view.component.html',
    styleUrls: ['./client-view.component.css']
})
export class ClientViewComponent implements OnInit {

    @Input() client: Client = <Client>{};
    hideEditButton = false;
    @Input() showOnlyClientView = false;
    @Input() showAddress = true;

    constructor(private router: Router,
                public translate: TranslateService, private clientService: ClientService) {

        this.hideEditButton = router.url.search('checkout-confirmation') !== -1
            || router.url.search('reservationToken') !== -1
            || router.url.search('checkout-deferred') !== -1
            || router.url.search('checkout-colts') !== -1;
        //console.log(this.hideEditButton);
    }

    ngOnInit() {
    }

    goEditClient() {
        if (this.clientService.isLoggedInFromSession()) {
            this.router.navigate([this.translate.currentLang + '/client/auth/profile'], { queryParamsHandling: 'merge' });
        } else {
            this.router.navigate([this.translate.currentLang + '/client/profile-guest'], { queryParamsHandling: 'merge' });
        }
    }

}
