@if (!loadPage) {
  <mat-progress-bar style="margin-top: 10px" mode="indeterminate"></mat-progress-bar>
}

<!--{{listFlexPass | json}}-->
@if (loadPage) {
  @if (listFlexPass.length > 0) {
    <div style="padding: 10px">
      @for (fp  of listFlexPass; track fp ) {
        <div style="padding: 10px">
          <mat-card class="example-card" style="background: #00000003">
            <!--            <mat-card-subtitle>{{fp.title}}</mat-card-subtitle>-->
            <mat-card-title>{{fp.title}}</mat-card-title>
            <mat-card-content>
              <p>{{fp.description}}</p>
            </mat-card-content>
            <mat-divider inset></mat-divider>
            <mat-card-actions align="end">
              <button mat-stroked-button color="primary" (click)="goFlex(fp)">
                @if (translate.currentLang === 'fr') {
                  <span>Échanger votre Flex Pass</span>
                }
                @if (translate.currentLang !== 'fr') {
                  <span>Exchange your Flex Pass</span>
                }
              </button>
            </mat-card-actions>
            @if (fp.load) {
              <mat-card-footer>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </mat-card-footer>
            }
          </mat-card>
        </div>
      }
    </div>
  }
  @if (listFlexPass.length === 0) {
    <div class="tw-flex tw-column " style="justify-content: center;align-items: center">
      <h1>
        @if (translate.currentLang === 'fr') {
          Il n'y a actuellement aucun FlexPass disponible.
        }
        @if (translate.currentLang !== 'fr') {
          There are currently no FlexPasses available.
        }
      </h1>
    </div>
  }
}



