<div [formGroup]="formGroupAddress" >
  <!--{{ formGroupAddress.value | json}}-->


  <div class="tw-flex tw-row-2 labelMobilAddress" style="justify-content: center;color: red"  >
    <div class="outline" style="margin: 0 20px" twHideByEnvironment environment="tkp mta">{{'client.address.addressLine1CreditCart' | translate}}</div>
    <!--        <div class="tw-input-width" appearance="outline" style="margin: 0 20px"></div>-->
  </div>
  <div class="tw-flex tw-row-2" style="justify-content: center;"  >
    <mat-form-field class="tw-input-width single-input" appearance="outline"  >
      <mat-label>{{'client.address.companyName' | translate}}</mat-label>
      <input matInput placeholder="{{'client.address.companyName' | translate}}" formControlName="company" autocomplete='organization' name="company">
    </mat-form-field>

  </div>
  <div  class="tw-flex tw-row-2" style="justify-content: center">

    <mat-form-field class="tw-input-width" appearance="outline" style="margin: 0 20px">
      <mat-label>{{'client.address.addressLine1' | translate}}</mat-label>
      <input matInput placeholder="" required formControlName="addressLine1" autocomplete='address-line1' name="Ecom_ShipTo_Postal_Street_Line1">
      <mat-error >
        <tw-form-error-message [errors]="formGroupAddress.controls['addressLine1'].errors" ></tw-form-error-message>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="tw-input-width" appearance="outline" style="margin: 0 20px">
      <mat-label>{{'client.address.addressLine2' | translate}}</mat-label>
      <input matInput formControlName="addressLine2" autocomplete='address-line2'  name="Ecom_ShipTo_Postal_Street_Line2">
    </mat-form-field>
  </div>
  <div class="tw-flex tw-row-2" style="justify-content: center">
    <mat-form-field  class="tw-input-width" appearance="outline" style="margin: 0 20px">
      <mat-label>{{'client.address.apartment' | translate}}</mat-label>

      <input  [pattern]="" matInput  formControlName="appartment" autocomplete='states' name="appt_ShipTo">
    </mat-form-field>

    <mat-form-field class="tw-input-width" appearance="outline" style="margin: 0 20px">
      <mat-label>{{'client.address.postal' | translate}}</mat-label>
      <!--            <input  pattern="^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$" matInput required formControlName="zipCode" name="Ecom_ShipTo_Postal_PostalCode"    twTwUppercase>-->

      <input  matInput required formControlName="zipCode" name="Ecom_ShipTo_Postal_PostalCode"    twTwUppercase>
      <mat-error >
        <tw-form-error-message [errors]="formGroupAddress.controls['zipCode'].errors" ></tw-form-error-message>
      </mat-error>
    </mat-form-field>


  </div>
  <div class="tw-flex tw-row-2" style="justify-content: center">
    <mat-form-field class="tw-input-width" appearance="outline" style="margin: 0 20px;">
      <mat-label>{{'client.address.city' | translate}}</mat-label>
      <input matInput  required formControlName="city" name='Ecom_ShipTo_Postal_City'>
      <mat-error >
        <tw-form-error-message [errors]="formGroupAddress.controls['city'].errors" ></tw-form-error-message>
      </mat-error>
    </mat-form-field>
    <div class="tw-input-width" style="margin: 0 20px;"></div>
  </div>
  <div class="tw-flex tw-row-2" style="justify-content: center">

    <mat-form-field  class="tw-input-width"  appearance="outline" style="margin: 0 20px;">
      <mat-label>{{'client.address.country' | translate}}</mat-label>

      <mat-select  formControlName="country" autocomplete='shipping country'>
        @for (ct of country; track ct) {
          <mat-option [value]="ct.ab">{{ct.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    @if (countrySelected === 'US') {
      <mat-form-field  class="tw-input-width"   appearance="outline" style="margin: 0 20px;">
        <mat-label>{{'client.address.state' | translate}}</mat-label>
        <mat-select  formControlName="statesUS" name="Ecom_ShipTo_Postal_StateProv">
          @for (st of statesUS; track st) {
            <mat-option [value]="st.ab">{{st.nameEn}}</mat-option>
          }
        </mat-select>
        <mat-error >
          <tw-form-error-message [errors]="formGroupAddress.controls['statesUS'].errors" ></tw-form-error-message>
        </mat-error>
      </mat-form-field>
    }
    @if (countrySelected === 'CA') {
      <mat-form-field  class="tw-input-width"  appearance="outline" style="margin: 0 20px">
        <mat-label>{{'client.address.province' | translate}}</mat-label>
        <mat-select formControlName="statesCAD" name="Ecom_ShipTo_Postal_StateProv">
          @for (st of statesCAD; track st) {
            <mat-option [value]="st.ab">{{st.nameEn}}</mat-option>
          }
        </mat-select>
        <mat-error >
          <tw-form-error-message [errors]="formGroupAddress.controls['statesCAD'].errors" ></tw-form-error-message>
        </mat-error>
      </mat-form-field >
    }
    @if (countrySelected !== 'US' && countrySelected !== 'CA') {
      <mat-form-field class="tw-input-width" appearance="outline" style="margin: 0 20px;" name="Ecom_ShipTo_Postal_StateProv">
        <mat-label>{{'client.address.province' | translate}}</mat-label>
        <input matInput formControlName="statesWorld" autocomplete='address-level1'>
      </mat-form-field>
    }


  </div>



</div>
