import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ClientService } from '../../client.service';
import { NewsletterService } from '../../../shared/services/newsletter.service';
import { TranslateService } from '@ngx-translate/core';
import { NewsletterResponse } from '../../../models/newsletter-response';
import { Client } from '../../../models/client';
import { map } from 'rxjs/operators';
import { CartService } from '../../../shared/services/cart.service';

export enum NewsletterType {
    PROFILE = 'profile',
    SALE_FLOW = 'sale flow'
}

@Component({
    selector: 'tw-client-newsletter',
    templateUrl: './client-newsletter.component.html',
    styleUrls: ['./client-newsletter.component.css']
})
export class ClientNewsletterComponent implements OnInit {
    @Output() hasSentNewsletterRequest: EventEmitter<any> = new EventEmitter();
    @Output() hasNewsletter: EventEmitter<any> = new EventEmitter();
    @Input() typeOfNewsletter: NewsletterType;
    _newsletters: NewsletterResponse;
    client: Client;


    constructor(private clientService: ClientService,
                private newsletterService: NewsletterService,
                protected translate: TranslateService,
                private cartService: CartService) {
    }

    ngOnInit() {
        this.initClient();
        this.clientService.subLogin.asObservable().subscribe(value => {
            setTimeout(() => {
                this.initClient();
            }, 2000);

        });

    }

    initClient() {
        this.client = this.clientService.getClientLocal();
        if (this.client) {
            if (this.typeOfNewsletter) {
                switch (this.typeOfNewsletter) {
                    case NewsletterType.PROFILE:
                        this.getMasterNewsletterForClient();
                        break;
                    case NewsletterType.SALE_FLOW:
                        this.findNewsletterForSaleFlow();
                        break;
                }
            }
        }
    }


    getCheckedNewsletters(): number[] {
        return this._newsletters.newsletters.filter(nl => nl.alreadySubscribed).map(nl => nl.id);
    }

    getUncheckedNewsletters(): number[] {
        return this._newsletters.newsletters.filter(nl => !nl.alreadySubscribed).map(nl => nl.id);
    }

    submitNewsletterRequest() {
        if (this.client) {
            this.newsletterService.sendRequestToNewsletterSubscription(this.getCheckedNewsletters(), this.getUncheckedNewsletters(), this.client.id).subscribe(response => {
                this.hasSentNewsletterRequest.emit(response);
            });
        }
    }

    updateSubscription(id: number) {
        const newsletter = this._newsletters.newsletters.find(nl => nl.id === id);
        newsletter.alreadySubscribed = !newsletter.alreadySubscribed;
    }

    getMasterNewsletterForClient() {
        this.newsletterService.getDefaultNewsletterForClient(this.client.id).subscribe((response) => {
            this._newsletters = response;
            this.hasNewsletter.emit(true);
        });
    }

    findNewsletterForSaleFlow() {
        this.cartService.getCart().pipe(
            map(cart => {
                return cart.cartElements.filter(element => element.prices)
                    .map((element) => {
                        return element.prices.map((price) => {
                            return price.eventId;
                        });
                    }).filter((perfId, index, self) => {
                        return self.indexOf(perfId) === index; // Filter unique
                    });
            })
        ).subscribe((response) => {
            const myFlatMap = (f, xs) =>
                xs.reduce((acc, x) =>
                    acc.concat(f(x)), []);
            this.newsletterService.findNewsletterForSaleFlow(this.client.id, myFlatMap(x => [...x], response)).subscribe((newsletters) => {
                this._newsletters = newsletters;
                if (newsletters.newsletters && newsletters.newsletters.length > 0) {
                    this.hasNewsletter.emit(true);
                } else {
                    this.hasNewsletter.emit(false);
                }


            },error => {
                this.hasNewsletter.emit(false);
            });
        });
    }
}
