
<div class="btn-floating-order"  style="display: flex;justify-content: center;
    background: white;
    border: 1px rgba(0,0,0,0.22) solid"
  [ngStyle]="!splitPrices && !bestsSelect ? {'flexDirection':'column'}:''"

  id="{{nameUnique}}">


  <div  style="width: 100%;padding: 10px">
    <div style="font-weight: bold;display: flex;flex-direction: row;flex-wrap: wrap;align-content: center;justify-content: end" class="temp-taxes-stats">
      <div class="titleTax">
        @if (translate.currentLang === 'fr') {
          <span >* Tous les prix incluent les taxes.</span>
        }
        @if (translate.currentLang === 'en') {
          <span>* All prices include tax.</span>
        }

        <!--                <span>{{ 'cart.addToCart' | translate }} <span *ngIf="total>0">{{total / 100 | currency :'':'symbol-narrow':'':translate.currentLang}}</span></span>-->
      </div>
      <div style=""  class="tw-flex tw-row"  >
        @if (qtyReserved>0) {
<div class="tw-flex   tw-column" style="
justify-content: center;
    align-items: center;
    margin-right: 5px;
">
            @if (qtyReserved===1) {
<div class="tw-primary-color" style="    text-align: right;
    width: 100%">{{qtyReserved}} {{'title.ticket'| translate}}</div>
            }
            @if (qtyReserved>1) {
<div class="tw-primary-color" style="    text-align: right;
    width: 100%">{{qtyReserved}} {{'title.ticket'| translate}}s</div>
            }
            @if (total && translate.currentLang === 'fr') {
              <div style="font-weight: bold;font-size: larger" >Total : {{total / 100 | currency :'':'symbol-narrow':'':translate.currentLang}}</div>
            }
            @if (total && translate.currentLang !=='fr') {
              <div style="font-weight: bold;font-size: larger">Total: {{total / 100 | currency :'':'symbol-narrow':'':translate.currentLang}}</div>
            }
          </div>
        }
        <div class="tw-flex">
          <button  mat-raised-button color="primary" [ngClass]="!activeBtn ? '' : 'tw-primary'"
            (click)="createHoldPage()" [disabled]="!activeBtn"
            >
            <span>{{ 'cart.addToCart' | translate }}</span>
            <span style="font-weight: 900; margin-left: 10px;">> </span>
            @if (!load) {
              <div class="cssload-speeding-wheel"></div>
            }
          </button>
        </div>


      </div>
    </div>
  </div>


  <!--    <button mat-raised-button color="primary" [ngClass]="qtyReserved ===0 ? '' : 'tw-primary'"-->
  <!--            (click)="createHoldPage()" [disabled]="qtyReserved ===0"  style="width: 100%;max-width: 1200px"-->
  <!--    >-->
  <!--        <div style="font-weight: bold;text-align: center;display: flex;flex-direction: row" class="temp-taxes-stats">-->
  <!--            <div style="display: flex;flex-direction: column;flex-grow: 4;">-->
  <!--                <span *ngIf="translate.currentLang === 'fr'" >* Tous les prix incluent les taxes.</span>-->
  <!--                <span *ngIf="translate.currentLang === 'en'">* All prices include tax.</span>-->

  <!--                <span>{{ 'cart.addToCart' | translate }} <span *ngIf="total>0">{{total / 100 | currency :'':'symbol-narrow':'':translate.currentLang}}</span></span>-->
<!--            </div>-->
<!--            <div style="margin: auto">-->
<!--                <mat-icon>shopping_cart</mat-icon>{{qtyReserved}}-->
<!--            </div>-->
<!--        </div>-->


<!--        <div class="cssload-speeding-wheel" *ngIf="!load"></div>-->
<!--    </button>-->
<div style="height: 10px"></div>
@if (!splitPrices && !bestsSelect) {
  <button mat-raised-button color="primary"  class="tw-primary"
    (click)="closeDialog()"   style="width: 100%"
    >
    <span>{{ 'title.next' | translate }}</span>
    <mat-icon>next</mat-icon>
    @if (!load) {
      <div class="cssload-speeding-wheel"></div>
    }
  </button>
}
</div>
