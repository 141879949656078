import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionLocaleService {
    session = [];

    constructor() {
    }

    static getSync(key: any, persistent?: boolean) {
        let read = null;
        const storeMethod = persistent === true ? localStorage : sessionStorage;
        try {
            if (storeMethod.getItem(key) === 'undefined') {
                return null;
            }
            read = storeMethod.getItem(key);
            return JSON.parse(read);

        } catch (e) {
            return read;
        }
    }

    static putSync(key: any, data: any, persistent?: boolean) {
        const storeMethod = persistent === true ? localStorage : sessionStorage;
        if ((typeof data) !== 'string') {
            storeMethod.setItem(key, JSON.stringify(data));
        } else {
            storeMethod.setItem(key, data);
        }

    }

    static deleteByKey(key: any, persistent?: boolean) {
        const storeMethod = persistent === true ? localStorage : sessionStorage;
        storeMethod.removeItem(key);
    }

    destroy() {
        this.session = [];
        localStorage.clear();
        sessionStorage.clear();
    }


}
