
@if (inputRef && inputRef.type) {
  <mat-icon style="margin: 5px" (click)="inputRef.type=inputRef.type=='password'?'text':'password'"
    color="primary"
    >
    {{inputRef.type == 'password' ? 'visibility_off' : 'visibility'}}
  </mat-icon>
}


