<form #user="ngForm" style="margin: auto;" (ngSubmit)="resetPws(user)" class="tw-max-width-6">
  <mat-form-field class="tw-input">
    <input matInput placeholder="{{'client.email' | translate}}" ngModel required name="username">
    <mat-error>
      is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <button class="tw-primary" mat-raised-button color="primary" [disabled]="load && !user.valid "> {{'formMsg.submit' | translate}}
    @if (!load) {
      <div class="cssload-speeding-wheel"></div>
    }
  </button>
  <a class mat-button color="primary" (click)="goto('signup')" > {{ 'title.signUp' | translate }} </a>
  <a class mat-button color="primary"  (click)="goto('login')" > {{ 'title.login' | translate }} </a>

</form>
