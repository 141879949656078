@if (ticketElements) {
  <div class="tw-max-width-6 tw-ticket-summary-container">
    @for (el of ticketElements; track el; let i = $index) {
      <tw-client-ticket-detail [ticketElement]="el" (displayPdf)="displayPdfFile($event)"></tw-client-ticket-detail>
    }
    @if (displayPdf) {
      <div class=" details-seats" style="width: 100%;margin-top: 40px;background: white">
        <div class="mat-elevation-z3" style="width: 100% ;margin: 10px">
          <button class="tw-primary" mat-icon-button color="primary" (click)="hidePdf()">
            <mat-icon aria-label="hide details">clear</mat-icon>
          </button>
          <div id="pdf-output" style=" margin: auto; width:80%;height: 600px;z-index: 1">
            <div class="loader">Loading...</div>
            <h2 style="text-align: center">{{'client.tickets.loadingPdfMessage' | translate}}</h2>
          </div>
        </div>
      </div>
    }
  </div>
}
