import {Injectable} from '@angular/core';
import {PriceWeb} from '../../../../../projects/shared-lib/src/lib/models/price-web';
import {Observable, Subject} from 'rxjs';
import {CombisType} from '../../../models/combis-type.enum';

export interface Combo2State {
    performancesDisabled: PriceWeb[];
    showsDisabled: PriceWeb [];
    hasMoreSelection: boolean;
    hasRightToGoPseudoMandatory: boolean;
    hasRightToGoOptional: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class PageCombo2Service {
    priceSelected: PriceWeb[] = [];
    nbSelectionRightInMandatoryGroupe = 0;
    combo2State: Combo2State = <Combo2State>{
        showsDisabled: [],
        performancesDisabled: [],
        hasMoreSelection: true,
        hasRightToGoPseudoMandatory: false,
        hasRightToGoOptional: false
    };

    pseudoMandatoryRequiredQtyMax: number;
    pseudoMandatoryRequiredQtyMin: number;
    showDuplicataForbidden: boolean;
    performanceDuplicataForbidden: boolean;


    qtySubject = new Subject<any>();
    combo2StateSubject = new Subject<Combo2State>();
    nbMandatory = 0;
    nbPseudo = 0;
    nbOptional = 0;
    hasOnePriceInGroupMANDATORYAndSoldOut=false;

    constructor() {
    }

    init() {
        this.priceSelected = [];
        this.combo2State = <Combo2State>{
            showsDisabled: [],
            performancesDisabled: [],
            hasMoreSelection: true,
            hasRightToGoPseudoMandatory: false,
            hasRightToGoOptional: false
        };
    }

    watchQtyGroup(): Observable<any> {
        return this.qtySubject.asObservable();
    }

    watchCombo2StateSubject(): Observable<Combo2State> {
        return this.combo2StateSubject.asObservable();
    }

    addQtyGroup(val) {
        this.qtySubject.next(val);
    }

    addPrices(priceWeb: PriceWeb, priceSelected: PriceWeb[]) {
        this.priceSelected = priceSelected;// Object.assign({},priceSelected);
        this.actionStateCombo(priceWeb);
    }

    updateQtyPrices(priceWeb: PriceWeb) {
        //this.priceSelected.filter(value => value.)
    }

    setCombo(
        pseudoMandatoryRequiredQtyMax: number,
        pseudoMandatoryRequiredQtyMin: number,
        showDuplicataForbidden: boolean,
        performanceDuplicataForbidden: boolean,
        nbSelectionRightInMandatoryGroupe: number,
        nbMandatory = 0,
        nbPseudo = 0,
        nbOptional = 0

    ) {
        this.pseudoMandatoryRequiredQtyMax = pseudoMandatoryRequiredQtyMax;
        this.pseudoMandatoryRequiredQtyMin = pseudoMandatoryRequiredQtyMin;
        this.showDuplicataForbidden = showDuplicataForbidden;
        this.performanceDuplicataForbidden = performanceDuplicataForbidden;
        this.nbSelectionRightInMandatoryGroupe = nbSelectionRightInMandatoryGroupe;
        this.nbMandatory = nbMandatory;
        this.nbPseudo = nbPseudo;
        this.nbOptional = nbOptional;

    }

    actionStateCombo(priceWeb: PriceWeb) {

        const pseudoMandatoryList = this.priceSelected.filter(value => value.typeGroup === CombisType.PSEUDO_MANDATORY);
        const pseudoMandatoryQty = this.getNbSelectionPriceLevel(pseudoMandatoryList);
        this.combo2State.hasMoreSelection = pseudoMandatoryQty < this.pseudoMandatoryRequiredQtyMax;

        if (this.showDuplicataForbidden) {
            if (priceWeb.qty === 0) {
                this.combo2State.showsDisabled = this.combo2State.showsDisabled.filter(value => value.showId !== priceWeb.showId);
            } else {

                this.combo2State.showsDisabled.push(priceWeb);
            }

        }
        if (this.performanceDuplicataForbidden) {
            if (priceWeb.qty === 0) {
                this.combo2State.performancesDisabled = this.combo2State.performancesDisabled.filter(value => value.eventId !== priceWeb.eventId);
            } else {
                this.combo2State.performancesDisabled.push(priceWeb);
            }
        }
        const listMandatoryQty = this.priceSelected.filter(value => value.typeGroup === CombisType.MANDATORY);
        const nbMandatoryQty = this.getNbSelectionPriceLevel(listMandatoryQty);

        this.combo2State.hasRightToGoPseudoMandatory = (nbMandatoryQty === this.nbSelectionRightInMandatoryGroupe) || this.hasOnePriceInGroupMANDATORYAndSoldOut;
        this.combo2State.hasRightToGoOptional = (pseudoMandatoryQty >= this.pseudoMandatoryRequiredQtyMin
            && pseudoMandatoryQty <= this.pseudoMandatoryRequiredQtyMax) || this.nbPseudo===0;
        this.combo2StateSubject.next(this.combo2State);

    }

    groupByPriceLevel(price: PriceWeb[]) {
        const previousValue = [];
        price
            .forEach((currentValue) => {
                //console.log(previousValue);
                const priceLevel = currentValue.priceLevel.replace(' ', '');
                const key = currentValue.eventId + 'a' + priceLevel + currentValue.comboGroupDetailId;

                previousValue[key] = previousValue[key] || [];
                previousValue[key].push(currentValue);
            });
        return previousValue;
    }

    getNbSelectionPriceLevel(price: PriceWeb[]): number {
        let nb = 0;
        const list = this.groupByPriceLevel(price);
        //console.log(toString.call(list));
        //console.log(list);
        Object.keys(list).forEach(value => {
            //console.log(value)
            let temp = <PriceWeb[]>list[value];
            temp = temp.filter(value1 => value1.qty > 0);
            if (temp.length > 0) {
                nb++;

            }
        });
        return nb;
    }


}
