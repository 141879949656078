import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GooglePayComponent} from './google-pay.component';
import {GooglePayButtonModule} from '@google-pay/button-angular';


@NgModule({
    declarations: [GooglePayComponent],
    exports: [GooglePayComponent],
    imports: [
        CommonModule,
        GooglePayButtonModule
    ]
})
export class GPayModule {
}
