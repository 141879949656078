import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, lastValueFrom, Subject } from 'rxjs';
import { Country } from '../../../address/address/address.component';
import { ActionCartService } from '../../services/action-cart.service';
import { CartService } from '../../services/cart.service';
import { AnalyticsService } from '../../services/analytics.service';


interface TimeReserve {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

@Component({
    selector: 'tw-timer-view',
    templateUrl: './timer-view.component.html',
    styleUrls: ['./timer-view.component.css']
})

export class TimerViewComponent implements OnInit, OnDestroy {

    @Input() reservationDate: Date;
    @Input() remainingTime: number;
    @Input() smallTime = false;
    @Input() clearBySelf = false;
    @Output() offTimer = new EventEmitter<boolean>();
    reservationTime: TimeReserve = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    };
    subscribe: any;

    _sub = [];

    constructor(private actionCartService: ActionCartService, public cartService: CartService, public analyticsService: AnalyticsService) {
    }

    ngOnInit() {
        this.remainingTime = -this.remainingTime;
        const source = interval(1000);
        this.subscribe = source.subscribe(val => {
            this.reservationTime = <TimeReserve>this.dhms();


        });
        const a = this.cartService.cartSub.subscribe(value => {
            this.subscribe.unsubscribe();
            if (!value) {
                return;
            }
            this.remainingTime = -value.remainingTime;
            this.reservationDate = new Date(value.reservationTime);
            //console.log(this.subscribe)
            this.subscribe = source.subscribe(val => {

                this.reservationTime = <TimeReserve>this.dhms();


            });

        });
        const b = this.actionCartService.watchActionCartResponse().subscribe(value => {
            this.subscribe.unsubscribe();
            this.actionCartService.cartTimeOut.next(-1);
        });
        this._sub = [a, b];
    }

    getRemainingTime() {
        return Math.abs(this.remainingTime) - 1;
    }

    dhms() {
        let t = this.getRemainingTime();
        this.remainingTime = t;

        if (t <= 0) {
            this.actionCartService.cartTimeOut.next(-1);
            this.offTimer.emit(true);
            console.log('fini time');
            if (this.clearBySelf) {
                /** c'est utilise quand le panier configue est false (website.cart = false) **/
                this.timerHold().then();
            }
            if (t <= -2) {
                this.subscribe.unsubscribe();
            }
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            };
        }
        this.actionCartService.cartTimeOut.next(t);
        let days, hours, minutes, seconds;
        days = Math.floor(t / 86400);
        t -= days * 86400;
        hours = Math.floor(t / 3600) % 24;
        t -= hours * 3600;
        minutes = Math.floor(t / 60) % 60;
        t -= minutes * 60;
        seconds = t % 60;

        return {
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        };
    }

    async timerHold() {
        try {

            const flatPrices = [];
            this.cartService.cartSub.value?.cartElements?.forEach(el => {
                el?.prices?.forEach(price => {
                    flatPrices.push(price);
                });
            });
            this.analyticsService.trackCartTimeOutFromCartPrice(flatPrices);
            this.offTimer.emit(true);
            await lastValueFrom(this.cartService.clearCart());
            await lastValueFrom(this.cartService.getCart());

        } catch (e) {

        }

    }

    ngOnDestroy(): void {
        this.subscribe.unsubscribe();
        this._sub.forEach(value => {
            if (value) {
                (<Subject<any>>value).unsubscribe();
            }
        });
    }
}
