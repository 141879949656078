import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {KeyServer, KeyServerEta, keyServerStateEnum} from '../models/key-server';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SessionLocaleService} from '../shared/services/session-locale.service';

export enum ModeKeyServeEnum {
    PAGES = 'pages',
    EVENTS = 'events',
    SUBSCRIPTIONS = 'subscriptions',
    FLEX_PASS='flexpass'

}


export interface Keypage {
    id?:any;
    pageId?: any;
    keyserver: KeyServer;
    type:ModeKeyServeEnum;
}

export interface KeyEmailRequest {
    token: string;
    websiteUrl: string;
    keyserverUrl: string;
    email: string;
}


@Injectable({
    providedIn: 'root'
})
export class KeyServiceService {

    tempValue = 10;
    /***pour test  ***/
    tempValueEta = 5;

    /***pour test  ***/

    constructor(private http: HttpClient, private sessionLocaleService: SessionLocaleService) {
    }

    getKeyServer(modeKeyServeEnum: ModeKeyServeEnum, id: string | number, httpParams ?: HttpParams): Observable<KeyServer> {


        const url = 'v1/key/' + modeKeyServeEnum + '/' + id;
        let body = {};
        const k = this.getKeyFromSession(id);

        if (k) {
            body = k;
        }

        return this.http.post<KeyServer>(url, body, { params: httpParams }).pipe(
            map(keyServer => {
                keyServer.url = url;
                if (httpParams && httpParams.has('presale')) {
                    keyServer.url = keyServer.url + '?presale=' + httpParams.get('presale');
                }
               // keyServer.state=keyServerStateEnum.WAITINGROOM
                this.putKeyFromSession(id, keyServer,modeKeyServeEnum);
                /**
                 * mock pour les test keyserver
                 * */
                // keyServer.state = keyServerStateEnum.QUEUE;
                // if(this.tempValue <= this.tempValueEta){
                //  //   keyServer.state =keyServerStateEnum.QUEUE
                // }
                // keyServer.onSaleEta = this.tempValue;
                return keyServer;
            })
        );
    }

    getKeyServerEta(modeKeyServeEnum: ModeKeyServeEnum, id: string | number, presale ?: string): Observable<KeyServerEta> {
        let httpParams: HttpParams;
        if (presale) {
            httpParams = new HttpParams().set('presale', presale);
        }

        let body = {};
        const k = this.getKeyFromSession(id);

        if (k) {
            body = k;
        }
        const url = 'v1/key/' + modeKeyServeEnum + '/' + id + '/eta';
        return this.http.post<KeyServerEta>(url, body, { params: httpParams }).pipe(
            map(eta => {
                /**
                 *mock pour les test
                 */
                // if(this.tempValueEta){
                //     eta.eta  = this.tempValueEta;
                //     eta.speed= 3
                //     eta.peopleBeforeMe=   eta.speed *this.tempValueEta
                // }

                return eta;
            })
        );
    }

    getKeyFromSession(id: number | string,modeKeyServeEnum=ModeKeyServeEnum.PAGES): KeyServer {
        const list = this.getKeysFromSession(modeKeyServeEnum);
        if (!list || list.length === 0) {
            return;
        }
        let keyFind;
        switch (modeKeyServeEnum) {
            case ModeKeyServeEnum.PAGES:
                 keyFind = list.find(value => value.pageId === String(id));
                break;
            case ModeKeyServeEnum.FLEX_PASS:
            case ModeKeyServeEnum.EVENTS:
            case ModeKeyServeEnum.SUBSCRIPTIONS:
                 keyFind = list.find(value => value.id === String(id));

        }


        if (!keyFind) {

            //return null;
            /*** temp Harvest ****/
            return list[0].keyserver;
        }

        return keyFind.keyserver;
    }

    deleteKeysAfterSale(id?: any,modeKeyServeEnum=ModeKeyServeEnum.PAGES) {
        const list = this.getKeysFromSession(modeKeyServeEnum);
        if (!list) {
            return;
        }
        if (id) {
            switch (modeKeyServeEnum) {
                case ModeKeyServeEnum.PAGES:

                    const keyFind = list.findIndex(value => value.pageId === id);
                    if (keyFind >= 0) {
                        list.splice(keyFind, 1);
                    }
                    SessionLocaleService.putSync('key', list, true);
                    break;
                case ModeKeyServeEnum.FLEX_PASS:
                case ModeKeyServeEnum.EVENTS:
                case ModeKeyServeEnum.SUBSCRIPTIONS:
                    const keyFind2 = list.findIndex(value => value.id === id);
                    if (keyFind2 >= 0) {
                        list.splice(keyFind2, 1);
                    }
                    SessionLocaleService.putSync('key-'+modeKeyServeEnum, list, true);

            }

            /** only delete by id page **/
            return;
        }
        /*** or delete all ids keyserver  ****/

        switch (modeKeyServeEnum) {
            case ModeKeyServeEnum.PAGES: SessionLocaleService.putSync('key', [], true);
                break;
            case ModeKeyServeEnum.FLEX_PASS:
            case ModeKeyServeEnum.EVENTS:
            case ModeKeyServeEnum.SUBSCRIPTIONS:
                 SessionLocaleService.putSync('key-' + modeKeyServeEnum,[], true);

        }

    }

    getKeysFromSession(modeKeyServeEnum=ModeKeyServeEnum.PAGES): Keypage[] {
        switch (modeKeyServeEnum) {
            case ModeKeyServeEnum.PAGES:
                return <Keypage[]>SessionLocaleService.getSync('key', true);
            case ModeKeyServeEnum.FLEX_PASS:
            case ModeKeyServeEnum.EVENTS:
            case ModeKeyServeEnum.SUBSCRIPTIONS:
                return <Keypage[]>SessionLocaleService.getSync('key-' + modeKeyServeEnum, true);

        }

    }

    putKeyFromSession(id: number | string, key: KeyServer,modeKeyServeEnum=ModeKeyServeEnum.PAGES) {

        let list = this.getKeysFromSession(modeKeyServeEnum);
        if (!list) {
            list = [];
            switch (modeKeyServeEnum) {
                case ModeKeyServeEnum.PAGES:
                    list.push({
                        pageId: id,
                        keyserver: key,
                        type:modeKeyServeEnum
                    });
                    SessionLocaleService.putSync('key', list, true);
                    break;
                case ModeKeyServeEnum.FLEX_PASS:
                case ModeKeyServeEnum.EVENTS:
                case ModeKeyServeEnum.SUBSCRIPTIONS:
                    list.push({
                        id: id,
                        keyserver: key,
                        type:modeKeyServeEnum
                    });
                    SessionLocaleService.putSync('key-'+modeKeyServeEnum, list, true);

            }

            return;
        }


        /** harvest ***/
        list.forEach(val => {
            val.keyserver = key;
        });

        SessionLocaleService.putSync('key', list, true);
    }

    registerEmail(email: string, pageId: string | number,modeKeyServeEnum=ModeKeyServeEnum.PAGES): Observable<any> {
        const url = 'v1/keyserver/email';
        const k = <KeyServer>this.getKeyFromSession(pageId);
        // @ts-ignore
        const temp: KeyEmailRequest = <KeyEmailRequest>{};
        temp.email = email;
        temp.keyserverUrl = k.url;
        temp.token = k.token;
        temp.websiteUrl = window.location.href;
        return this.http.post<any>(url, temp);
    }

    isUpEmailKeyServer(): Observable<any> {
        const url = 'v1/keyserver/email/' + 'account code ' + '/status';
        return this.http.get<any>(url);
    }

}
