import { Component, Input, OnInit } from '@angular/core';
import { Tax } from '../../../models/tax';
import { ShippingService } from '../../../shipping/services/shipping.service';
import { ShippingModeRest } from '../../../models/shipping-mode-rest';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'tw-cart-tax-view',
    templateUrl: './cart-tax-view.component.html',
    styleUrls: ['./cart-tax-view.component.css']
})
export class CartTaxViewComponent implements OnInit {
    @Input() tax: Tax;
    @Input() typeStyle = 1;
    sh: ShippingModeRest;
    totalShippingSelected = 0;


    constructor(private shippingService: ShippingService, public translate: TranslateService) {
    }

    ngOnInit() {
        this.totalSipping();
        this.shippingService.watchShippingSelect().subscribe(() => {
            this.totalSipping();
        });
    }

    totalSipping() {
        this.totalShippingSelected = 0;
        const temp = this.shippingService.getShippingSelectedLocal();
        if (temp) {
            Object.keys(temp)?.forEach(value => {
                //console.log(temp[value].price);
                try {
                    this.totalShippingSelected = this.totalShippingSelected + temp[value].price;
                }catch (e) {
                    console.log(e)
                }

            });
        }

    }

}
