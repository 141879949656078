<div class="tw-max-width-6" style="margin:auto">

  <tw-app-cart></tw-app-cart>

  @if (websiteSaleConfig.clientAccount !='NO') {
    <div style="margin: 27px 0 27px;">
      <mat-checkbox [(ngModel)]="showGuest">{{"card.guest_checkout" | translate}}</mat-checkbox>
    </div>
  }
  @if (!showGuest && websiteSaleConfig.clientAccount !='NO') {
    <tw-login-client></tw-login-client>
  }

  @if (showGuest || websiteSaleConfig.clientAccount ==='NO' && !timeOut) {
    <form [formGroup]="guestForm">
      <div class="item-client tw-max-width-6  ">
        <tw-client-form [formGroupClient]="clientGroup" [client]="clientClone"></tw-client-form>
      </div>
      @if (!isCompletedProfile) {
        <div class="item-phone  tw-max-width-6">
          <tw-phone-form [formGroupPhone]="phoneGroup" [phone]="phoneClone"></tw-phone-form>
        </div>
      }
      <div class="item-address tw-max-width-6 ">
        <tw-address [formGroupAddress]="addressGroup" [address]="addressClone" (addressChange)="addressChange($event)"></tw-address>
        @if (websiteSaleConfig.flowStandard) {
          <hr>
            <div class="tw-padding-10">
              <mat-checkbox formControlName="isSameAddress"> same address</mat-checkbox>
            </div>
            @if (!guestForm.controls['isSameAddress'].value) {
              <tw-address [formGroupAddress]="address2Group"></tw-address>
            }
          }
        </div>
        <div style="min-height: 50px; text-align: right; margin-top: 17px;">
          <button class="tw-primary" mat-raised-button color="primary" type="submit" [disabled]="!guestForm.valid || !load" (click)="onSubmit()">{{ "cart.save" | translate }}
            @if (!load) {
              <div class="cssload-speeding-wheel"></div>
            }
          </button>
        </div>
      </form>
    }
    <!--  <pre>{{clientRequest | json}}</pre>-->

  </div>
