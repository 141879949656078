<!--<select #langSelect (change)="translate.use(langSelect.value)">-->
<!--<option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>-->
<!--</select>-->


@if (languageSupported.length>2) {
  <mat-form-field class="btn-lang-select-temp">
    <mat-select placeholder="lang" [(ngModel)]="selectedValue"  (selectionChange)="selectChange(selectedValue)">
      @for (lang of languageSupported; track lang) {
        <mat-option [value]="lang" [selected]="lang.toLowerCase() === translate.currentLang">
          {{ lang.toUpperCase() }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
}
@if (languageSupported.length<=2 && languageSupported.length >1) {
  <div  class="tw-padding-10 btn2-lang-c-temp" >
    @if (selectedValue === 'en') {
      <button style=" text-transform: uppercase;width: 100%" mat-raised-button color="primary" class="btn1-lang-temp tw-primary" (click)="selectChange('fr')">fr</button>
    }
    @if (selectedValue === 'fr') {
      <button style=" text-transform: uppercase;width: 100%"  mat-raised-button color="primary" class="btn1-lang-temp tw-primary" (click)="selectChange('en')">en</button>
    }
  </div>
}


