<div class="snack-bar-temp">
  @if (!data.translate) {
    <span>{{data.message}}</span>
  }
  @if (data.translate) {
    <div [innerHTML]="data.message  | translate"></div>
  }

</div>

