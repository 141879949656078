import { Component, OnInit } from '@angular/core';
import {  MatDialog } from '@angular/material/dialog';
import { MatSnackBar,  MatSnackBarConfig, MatSnackBarHorizontalPosition,  MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MessageAction, MessageObserverService } from '../../services/message-observer.service';
import { DialogMsgComponent } from '../dialog-msg/dialog-msg.component';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';

@Component({
    selector: 'tw-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
    setAutoHide = true;
    autoHide = 1500;
    addExtraClass = false;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(public dialog: MatDialog,
                private messageObserverService: MessageObserverService,
                public snackBar: MatSnackBar) {

    }

    ngOnInit() {
        this.messageObserverService.watchMessage().subscribe(value => {
            // console.log(value)
            // this.snackBar.openFromComponent(SnackBarComponent, this._createConfig(value));
            if (value.dialog) {
                const dy = this.dialog.open(DialogMsgComponent, {
                    ...value.config,
                    data: {
                        message: value.message,
                        translate: value.translate,
                        title: value.title,
                        id: value.id
                    }
                });
                if(value.dialogRef){
                    this.messageObserverService.dialog = dy
                }
            }
            if (value.snackBar) {
                this.snackBar.openFromComponent(SnackBarComponent, this._createConfig(value));
            }

        });

    }

    private _createConfig(data: MessageAction) {
        const config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;
        // config.panelClass = this.addExtraClass ? ['demo-party'] : undefined;
        //config.duration = 105000;
        config.panelClass = data.snackBar.panelClass || 'tw-snack-bar';
        config.data = data;
        return config;
    }

}
