import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {PerformanceResponseLight} from "../../../models/performance-response-light";
import {UntypedFormControl} from "@angular/forms";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import { MAT_DIALOG_DATA,  MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'tw-flex-pass-films',
    templateUrl: './flex-pass-films.component.html',
    styleUrls: ['./flex-pass-films.component.css']
})
export class FlexPassFilmsComponent implements OnInit {
    queryField: UntypedFormControl = new UntypedFormControl();
    films: PerformanceResponseLight[];
    films$: PerformanceResponseLight[];
    filmSelected$ = {
        key: null,
        value: null
    };

    @Input() set toBeExchange(toBeExchange: any) {
        this.films = toBeExchange.value;
        this.films$ = toBeExchange.value;
    }

    @Output() watchSelectFilm = new EventEmitter<any>();

    constructor(
        public dialogRef: MatDialogRef<FlexPassFilmsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.films = data.films;
        this.films$ = data.films;
        this.filmSelected$.key = data.ticketId;
        this.filmSelected$.value = data.filmSelected;
    }

    ngOnInit() {
        this.queryField.valueChanges.pipe(
            debounceTime(200),
            distinctUntilChanged()
        ).subscribe(value => {
            // console.log(value);
            this.search(value);
        });
    }

    filmSelected(film: any) {
        //console.log(film)
        if (film.saleState === "ONSALE") {
            this.filmSelected$.value = film
        }

    }

    search(val: string) {
        this.films$ = this.films.filter(value => {
            //   console.log(value.code.search(val));
            if (value.venue && value.venue.toUpperCase().search(val.toUpperCase()) !== -1) {
                return value;
            }
            if (value?.itemHours[0].formattedDate && value?.itemHours[0].formattedDate.toUpperCase().search(val.toUpperCase()) !== -1) {
                return value;
            }
            if (value.title && value.title.toUpperCase().search(val.toUpperCase()) !== -1) {
                return value;
            }
        }).map(value => {
            return value;
        });
    }


}
