import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {ClientService} from '../../client.service';
import {WebsiteSaleService} from '../../../shared/services/website-sale.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class MiddlewareClientResolveService {

    constructor(private clientService: ClientService,
                private websiteSaleService: WebsiteSaleService,
                public  translate: TranslateService,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const config = this.websiteSaleService.getLocalConfig();
        const client = this.clientService.getClientLocal();
        const edit = route.queryParams['edit'];
       // console.log(edit);
        if (edit) {
            if (this.clientService.isLoggedInFromSession()) {
                this.router.navigate([this.translate.currentLang + '/client/auth/profile']);
            } else {
                this.router.navigate([this.translate.currentLang + '/client/profile-guest']);
            }
            return undefined;
        }

        if (this.clientService.isLoggedInFromSession()) {
            if (client && !client.isCompletedProfile) {
                this.router.navigate([this.translate.currentLang + '/client/auth/profile'], {  queryParamsHandling: 'merge' });
            } else {
                this.router.navigate([this.translate.currentLang + '/checkout'], {  queryParamsHandling: 'merge' });
            }
        } else {
            if (config.clientAccount === 'NO' || config.clientAccount === 'OPTIONAL') {
                if (client) {
                    this.router.navigate([this.translate.currentLang + '/checkout'], {  queryParamsHandling: 'merge' });
                } else {
                    this.router.navigate([this.translate.currentLang + '/client/select-checkout-method'], {  queryParamsHandling: 'merge' });
                }

            } else {
                this.router.navigate([this.translate.currentLang + '/client/login'], {  queryParamsHandling: 'merge' });
            }
        }
        return undefined;
    }
}
