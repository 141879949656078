import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Address } from '../../../projects/shared-lib/src/lib/models/address';

export interface FilterPageInterface {
    startDate?: any;
    endDate?: any;
    address?: Address;
    search?: any;
    action?: any;
    time?: any;
}

@Injectable({
    providedIn: 'root'
})
export class FilterPageService {
    filter = new BehaviorSubject<FilterPageInterface>({});

    constructor() {
    }
}
