
<!--<div  *ngIf="activeBtn" class="apple-pay-button-with-text apple-pay-button-black-with-text"  (click)="onApplePayClick()">-->
<!--    <span class="text">toooo</span>-->
<!--    <span class="logo"></span>-->

<!--</div>-->
@if (activeBtn) {
<button   (click)="onApplePayClick()"  style="    background: black;
    color: white;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center">
    @if (translate.currentLang === 'fr') {
      <span class="text" style="font-size: 12px;font-weight: 700">Acheter avec</span>
    }
    @if (translate.currentLang !== 'fr') {
      <span class="text"  style="font-size: 16px">Buy with</span>
    }
    <img  src="./assets/resources/svg/Apple_Pay_logo.svg" style="height: 17px;margin: 7px" alt="cartlogo">
  </button>
}
<!--<div *ngIf="activeBtn">-->
<!--    sssssssssss-->
<!--</div>-->
<!--llll-->
<!--({{activeBtn}})-->
<!--<button  *ngIf="isApplePaySession()" class="apple-pay-button"-->
<!--        (click)="onApplePayClick()"-->
<!--        [lang]="'fr'"-->
<!--         style="display: inline-block"-->

<!--&gt;-->
<!--</button>-->
<!--<apple-pay-button buttonstyle="black" type="buy" locale="el-GR"></apple-pay-button>-->
