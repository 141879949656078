@if (data.title) {
  <h1 class="theme-wrapper" mat-dialog-title>{{data.title}}</h1>
}
<div mat-dialog-content class="theme-wrapper">
  @if (!data.translate) {
    <div [innerHTML]="data.message | transformHtml"></div>
  }
  @if (data.translate) {
    <div [innerHTML]="data.message  | translate"></div>
  }
  <!--<pre>{{data | json}}</pre>-->
</div>
<div mat-dialog-actions  align="end">
  <button mat-stroked-button  (click)="onNoClick()">Ok</button>
</div>
