@if (!reservationTime && !smallTime) {
  <div  style="text-align: center ;font-size: 40px" class="tw-timer-container"disabled>
    <div style="color: #cccccc;width: 100px;text-align: center;margin: auto " >
      <mat-progress-bar style="width: 100px;text-align: center" mode="indeterminate"></mat-progress-bar>
      00:00
    </div>
  </div>
}
@if (!reservationTime && smallTime) {
  <div style="text-align: right ;font-size: 10px ;margin-right: 15px;" disabled>
    <!--<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
    <span style="color: #cccccc">00:00</span>
  </div>
}

@if (!smallTime ) {
  <div style="justify-content: center ;font-size: 20px" class="tw-flex">
    @if (!reservationDate) {
      <mat-progress-bar   style="width: 100px;text-align: center" mode="indeterminate"></mat-progress-bar>
    }
    <div class="big-timer-c big-timer">
        <div style="    position: absolute;
    top: 31%;right: 8px;color: black" class="big-timer-container">
        @if (reservationTime.days >0 && reservationTime.days <=9) {
          <span>0{{reservationTime.days}}:</span>
        }
        @if (reservationTime.days >9) {
          <span> {{reservationTime.days}}:</span>
        }
        @if (reservationTime.hours >0 && reservationTime.hours <=9) {
          <span>0{{reservationTime.hours}}:</span>
        }
        @if (reservationTime.hours >9) {
          <span>{{reservationTime.hours}}:</span>
        }
        @if (reservationTime.minutes >=0 && reservationTime.minutes <=9) {
          <span>0{{reservationTime.minutes}}:</span>
        }
        @if (reservationTime.minutes >9) {
          <span>{{reservationTime.minutes}}:</span>
        }
        @if (reservationTime.seconds >=0 && reservationTime.seconds <=9) {
          <span>0{{reservationTime.seconds}}</span>
        }
        @if (reservationTime.seconds >9) {
          <span>{{reservationTime.seconds}}</span>
        }
      </div>
    </div>
  </div>
}
@if (reservationTime && smallTime) {
  <div class="small-timer">
    @if (reservationTime.days >0 && reservationTime.days <=9) {
      <span>0{{reservationTime.days}}:</span>
    }
    @if (reservationTime.days >9) {
      <span> {{reservationTime.days}}:</span>
    }
    @if (reservationTime.hours >0 && reservationTime.hours <=9) {
      <span>0{{reservationTime.hours}}:</span>
    }
    @if (reservationTime.hours >9) {
      <span>{{reservationTime.hours}}:</span>
    }
    @if (reservationTime.minutes >=0 && reservationTime.minutes <=9) {
      <span>0{{reservationTime.minutes}}:</span>
    }
    @if (reservationTime.minutes >9) {
      <span>{{reservationTime.minutes}}:</span>
    }
    @if (reservationTime.seconds >=0 && reservationTime.seconds <=9) {
      <span>0{{reservationTime.seconds}}</span>
    }
    @if (reservationTime.seconds >9) {
      <span>{{reservationTime.seconds}}</span>
    }
  </div>
}
