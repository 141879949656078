import { Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'tw-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
    @Input() message: any;
    showError = false;

    constructor() {
    }

    ngOnInit() {
    }

}


@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ]
})
export class PageNotFoundModule {

}

