<div class="tw-flex tw-row tw-header-show" style="margin-bottom:20px">
  <div style="text-align: center;width: 80px" >
    <img src="./assets/resources/svg/movie-tickets.svg" style="height: 47px;" alt="cartlogo">
    <div style="
                            font-size: 22px;
                            margin-top: -53px;
                            margin-left: 47px;
                            height: 48px;
                            color:green;
                            "

      > <span class="tw-header-number">{{invoice.tickets.length}}</span>

    </div>
  </div>
  <div  class="tw-header-font">
    <div style="font-weight: 600">
      {{"invoice.invoiceNumber"|translate}} : {{invoice.id}}
    </div>
    <div style="font-weight: 600">
      {{"invoice.date"|translate}} : {{invoice.date | twDatePipe:'longDate'}} {{invoice.date | twDatePipe:'shortTime'}}
    </div>
    <div>
      {{"invoice.total"|translate}} : {{invoice.totalAmount/100 | currency :'':'symbol-narrow':'':translate.currentLang}}
    </div>
    @if (invoice.paymentMode.length>0) {
      <div>
        {{"invoice.payModes"|translate}} :
        @for (paymode of invoice.paymentMode; track paymode) {
          <span>
            {{ paymode }}
          </span>
        }
      </div>
    }
    <!--
    <div *ngIf="price">
      {{price.priceType}}
    </div>
    -->
  </div>
  <div style="flex: 1">
    @if (!isShowingDetails) {
      <mat-icon class="toggle-invoice-details" (click)="toggle()">keyboard_arrow_right</mat-icon>
    }
    @if (isShowingDetails) {
      <mat-icon class="toggle-invoice-details" (click)="toggle()">keyboard_arrow_down</mat-icon>
    }
  </div>
</div>