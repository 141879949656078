import {Component, Input, OnInit} from '@angular/core';
import { Parking } from '../../../../../projects/shared-lib/src/lib/models/parking';
import {TranslateService} from '@ngx-translate/core';
import {ActionCartRequest, ActionCartService, EnumTypeActionCart} from '../../../shared/services/action-cart.service';
import { CartPrice } from '../../../../../projects/shared-lib/src/lib/models/cart-price';
import {CartDetailToken} from '../../../models/cart-detail-token';

@Component({
    selector: 'tw-cart-parking',
    templateUrl: './cart-parking.component.html',
    styleUrls: ['./cart-parking.component.css']
})
export class CartParkingComponent implements OnInit {

    @Input() parking: Parking[];
    @Input() isInCheckout = false;

    constructor(public translate: TranslateService, private actionCart: ActionCartService) {
    }

    ngOnInit() {
    }

    deleteParking(prk: Parking) {
        if (!this.isInCheckout) {
            const temp = <CartPrice><unknown>{
                eventId: prk.eventId,
                parkingId: prk.id,
                cartDetailToken: [
                    <CartDetailToken>{
                        idCartLine: prk.hold
                    }
                ]
            };
            this.actionCart.actionRequest(<ActionCartRequest>{type: EnumTypeActionCart.deleteElement, prices: [temp]});
        }
    }
}
