import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    BiSafeUrlPipe,
    PhonePipe,
    SecondsToTimeStringPipe,
    TransformHtmlPipe,
    TwDatePipePipe,
    TwGoogleMaps, TwUrlPipe
} from './index';
import {EnumToArrayPipe} from './enum-to-array.pipe';
import {UrlSecurePipe} from './tw-url-secure-pipe/url-secure.pipe';
import {UrlResourcePipe} from './url-resource.pipe';
import {TypeImageJsonPipe} from './type-image-json.pipe';
import {TranslateLangPipe} from './translate-lang.pipe';


@NgModule({
    declarations: [BiSafeUrlPipe, PhonePipe, SecondsToTimeStringPipe, TransformHtmlPipe, TwDatePipePipe, TwGoogleMaps, TwUrlPipe, EnumToArrayPipe, UrlSecurePipe, UrlResourcePipe, TypeImageJsonPipe, TranslateLangPipe],
    exports: [BiSafeUrlPipe, PhonePipe, SecondsToTimeStringPipe, TransformHtmlPipe, TwDatePipePipe, TwGoogleMaps, TwUrlPipe, EnumToArrayPipe, UrlSecurePipe, UrlResourcePipe, TypeImageJsonPipe, TranslateLangPipe],
    imports: [
        CommonModule
    ]
})
export class PipeModule {
}
