import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../../../../environments/environment';


@Pipe({
    name: 'twUrl'
})
export class TwUrlPipe implements PipeTransform {

    transform(value: string, args?: any): any {
        if (value && value.charAt(0) === '/') {
            value = value.substring(1);
        }
        console.log(value);
        if (value.search('http') !== -1) {
            return value;
        }
        if (value.search('/assets/') !== -1) {
            return value;
        }

        return environment.urlApi + value;
    }

}
