import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShippingModeComponent} from './shipping-mode/shipping-mode.component';
import {ShippingModesListComponent} from './shipping-modes-list/shipping-modes-list.component';
import {MaterialModule} from '../material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SharedModule,
        TranslateModule,
    ],
    declarations: [ShippingModeComponent, ShippingModesListComponent],
    exports: [CommonModule, ShippingModeComponent, ShippingModesListComponent]
})
export class ShippingModule {
}
