import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LangsObserverService {

    private lang = new Subject<string>();
    constructor() {
    }

    watchLang(): Observable<string> {
        return this.lang.asObservable();
    }

    addLang(lan: string) {
        this.lang.next(lan);
    }

}
