import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[twMyNumberOnly]'
})
export class MyNumberOnlyDirective {

    // Allow decimal numbers. The \. is only allowed once to occur
    private regex: RegExp = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);

    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }

        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        const current: string = this.el.nativeElement.value;
        let rx: string = this.el.nativeElement.value;
        rx = rx.concat(event.key);
        rx = rx.replace('(', '');
        rx = rx.replace(')', '');
        rx = rx.replace(' ', '');
        //console.log("rx",rx)
        if (rx && !String(rx).match(this.regex)) {
            event.preventDefault();
        }
    }
}
