<div [formGroup]="formGroupClient" class="tw-flex tw-column" style="justify-content: center">
  <div class="tw-flex tw-row-2" style="justify-content: center">
    <mat-form-field class="tw-input-width" appearance="outline" style="margin: 0 20px">
      <mat-label>{{'client.firstName' | translate}} </mat-label>
      <input matInput required formControlName="firstname"
        autocomplete='given-name'>
      <mat-error>
        <tw-form-error-message [errors]="formGroupClient.controls['firstname'].errors"></tw-form-error-message>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="tw-input-width" appearance="outline" style="margin: 0 20px">
      <mat-label>{{'client.lastName' | translate}} </mat-label>
      <input matInput  required
        formControlName="lastname" autocomplete='family-name'>
      <mat-error>
        <tw-form-error-message [errors]="formGroupClient.controls['lastname'].errors"></tw-form-error-message>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="tw-flex tw-row-2" style="justify-content: center">
    <mat-form-field  class="tw-input-width" appearance="outline" style="margin: 0 20px">
      <mat-label>{{'client.email' | translate}} </mat-label>
      <input matInput formControlName="email" required
        autocomplete='email' twValidateEmail >
      <mat-error>
        <tw-form-error-message [errors]="formGroupClient.controls['email'].errors"></tw-form-error-message>
      </mat-error>
    </mat-form-field>
    <div   class="tw-input-width" style=" font-size: 12px ;margin: 0 20px;" >
      @if (!this.isGuestForm) {
        <mat-form-field appearance="outline" style="width: 100px" >
          <mat-label>{{'client.language' | translate}}" </mat-label>
          <mat-select  formControlName="language" autocomplete='language' name="language" [(ngModel)]="lang">
            <mat-option value='en'>EN</mat-option>
            <mat-option value='fr'>FR</mat-option>
          </mat-select>
          <mat-error>
            <tw-form-error-message [errors]="formGroupClient.controls['language'].errors"></tw-form-error-message>
          </mat-error>
        </mat-form-field>
      }
    </div>

  </div>
  @if (isCheckoutPage) {
<div style="
    min-height: 50px;
    width: 100%;
    margin: auto;
    text-align: right;
    padding-top: 20px;
    ">
      <button  class="tw-primary" mat-raised-button color="primary" type="submit" [disabled]=" !load" (click)="updateClient()" >{{ "formMsg.submit" | translate }}
        @if (!load) {
          <div class="cssload-speeding-wheel"></div>
        }
      </button>
    </div>
  }



</div>


