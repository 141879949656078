import {Component, Input, OnInit} from '@angular/core';
import { Address, Sta } from '../../../projects/shared-lib/src/lib/models/address';
import { constSystem } from '../../../projects/shared-lib/src/lib/utils/const-system';
import { BannersService } from '../banners.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ClientService } from '../../modules/client/client.service';
import { AnalyticsService } from '../../modules/shared/services/analytics.service';
import { HelpsService } from '../../../projects/shared-lib/src/lib/utils/helps.service';
import { FilterPageService } from './filter-page.service';
import { RangeDates } from '../../../projects/shared-lib/src/lib/component/date-range-select-advanced/date-range-select-advanced.component';


@Component({
    selector: 'tw-filter-pages-events',
    templateUrl: './filter-pages-events.component.html',
    styleUrls: ['./filter-pages-events.component.css']
})
export class FilterPagesEventsComponent implements OnInit {
    provinces = <Sta[]>constSystem.world.provinces;
    provinceSelected: Sta;
    search = '';
    postal: any;


    constructor(public bannersService: BannersService, public translate: TranslateService,
                private router: Router, private clientService: ClientService,
                private analyticsService: AnalyticsService,
                public helpsService: HelpsService,
                public filterPageService: FilterPageService) {
    }

    async ngOnInit() {
        const a = await this.helpsService.getGeoProvince();
        //console.log(a);
        this.provinceSelected = a;

    }


    selectProvince(st: Sta) {
        const temp = this.filterPageService.filter.value;
        const address = <Address>{
            province: st.ab
        };
        //console.log(address)
        this.filterPageService.filter.next({ ...temp, address: address, action: false });
        this.provinceSelected = st;
        this.postal = null;
    }

    getDates(rangeDates: RangeDates) {
        //console.log(rangeDates);
        const temp = this.filterPageService.filter.value;
        this.filterPageService.filter.next({ ...temp, ...rangeDates, action: false });
    }

    searchEvent() {
        const temp = this.filterPageService.filter.value;
        this.filterPageService.filter.next({ ...temp, search: this.search, action: true, time: new Date().getTime() });
    }

    stopPropagation($event: MouseEvent) {

    }
}
