import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApplePayComponent} from './apple-pay/apple-pay.component';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        ApplePayComponent
    ],
    exports: [
        ApplePayComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
    ]

})
export class ApplePayModule {
}
