import {Component, Input, OnInit} from '@angular/core';
import {TicketElement} from '../../../../models/ticket-element';
import * as PDFObject from '../../../../../../lib/PDFObject';

@Component({
    selector: 'tw-client-tickets-summary',
    templateUrl: './client-tickets-summary.component.html',
    styleUrls: ['./client-tickets-summary.component.css']
})
export class ClientTicketsSummaryComponent implements OnInit {

    @Input() ticketElements: TicketElement[];
    displayPdf = false;

    constructor() {
    }

    ngOnInit() {
    }

    displayPdfFile(url) {
        this.displayPdf = true;
        setTimeout(() => {
            PDFObject.embed(url, '#pdf-output');
        }, 200);
    }

    hidePdf() {
        this.displayPdf = false;
    }

}
