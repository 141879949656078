import {SessionLocaleService} from './session-locale.service';
import {HttpTemplateService} from './http-template.service';
import {RequestCache, RequestCacheWithMap} from './request-cache-with-map.service';
import {HelpsService} from './helps.service';
import {CalendarObserverService} from './calendar-observer.service';
import {LangsObserverService} from './langs-observer.service';
import {ActionCartService} from './action-cart.service';
import {MessageObserverService} from './message-observer.service';
import {AuthenticationUserGuard} from './authentication-user.guard';
import {AuthenticationUserService} from './authentication-user.service';
import {PermissionUserService} from './permission-user.service';
import {BrowserFaviconsService} from './browser-favicons.service';
import {ThemeService} from './theme.service';

export const SERVICES_SHARED: any [] = [
    SessionLocaleService,
    HttpTemplateService,
    CalendarObserverService,
    { provide: RequestCache, useClass: RequestCacheWithMap },
    HelpsService,
    LangsObserverService,
    ActionCartService,
    MessageObserverService,
    AuthenticationUserGuard,
    AuthenticationUserService,
    PermissionUserService,
    BrowserFaviconsService,
    ThemeService
];


