import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NewsletterResponse } from '../../models/newsletter-response';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NewsletterService {

    constructor(private http: HttpClient) {
    }

    getMasterNewsletterForClient(clientId: number): Observable<NewsletterResponse> {
        const url = `v1/mailinglist/master/client/${clientId}`;
        const dataForm = {};
        return this.http.get<NewsletterResponse>(url, dataForm);
    }

    getDefaultNewsletterForClient(clientId: number): Observable<NewsletterResponse> {
        const url = `v1/mailinglist/client/${clientId}`;
        const dataForm = {};
        return this.http.get<NewsletterResponse>(url, dataForm);
    }

    findNewsletterForSaleFlow(clientId: number, performanceIds: number[]): Observable<NewsletterResponse> {
        const url = `v1/mailinglist`;
        return this.http.post<NewsletterResponse>(url, {clientId, performanceIds}, {});
    }

    sendRequestToNewsletterSubscription(subscribe: number[], unsubscribe: number[], clientId: number): Observable<NewsletterResponse> {
        const url = `v1/subscribetonewsletter`;
        const subscriptionRequest = {clientId, subscribe, unsubscribe};
        return this.http.post<NewsletterResponse>(url, subscriptionRequest);
    }

}
