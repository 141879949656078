import { ReserveTicketsRequest } from './reserve-tickets-request';
/**
 * @deprecated utilse l'interface  le model de shared-lib/src/lib/models **/
export enum ItemType {
    SHOW = 'show',
    SUBSCRIPTION = 'subscription',
    PARKING = 'parking',
    COMBO = 'COMBO',
    BYOC = 'BYOC',
    MEMBER = 'MEMBER',
    GIFT_CERTTIFICATES='GIFT_CERTTIFICATES'
}

export interface HoldItemRequest extends ReserveTicketsRequest {
    type: ItemType;
    id: number;

}
