import {Component, ElementRef, OnInit} from '@angular/core';
import {ConfigWebsite} from '../../modules/models/config-website';
import {SessionRedisService} from '../../modules/shared/services/session-redis.service';
import {WebsiteSaleService} from '../../modules/shared/services/website-sale.service';
import {LangsObserverService} from '../../modules/shared/services/langs-observer.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {ClientService} from '../../modules/client/client.service';
import {SubdomainService} from '../../modules/shared/services/subdomain.service';
import {BrowserFaviconsService} from '../../modules/shared/services/browser-favicons.service';
import {registerLocaleData,Location} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {AnalyticsService} from '../../modules/shared/services/analytics.service';

import {FacebookUser} from '../../modules/models/facebook-user';
import {GoogleUser} from '../../modules/models/google-user';
import {HelpsService} from '../../modules/shared/services/helps.service';
import {RedirectUrlService} from '../redirect-url.service';
import {PageSaleService} from '../../modules/page/services/page-sale.service';
import {PriceManagerService} from '../../modules/page/services/price-manager.service';
import {environment} from '../../../environments';
import {ThemeService} from '../../../projects/shared-lib/src/lib/services/theme.service';
import {SessionLocaleService} from '../../modules/shared/services/session-locale.service';
import {HttpParams} from '@angular/common/http';
import {SymplifyCartService} from '../../modules/shared/services/symplify-cart.service';
import {CartService} from '../../modules/shared/services/cart.service';

@Component({
    selector: 'tw-main-app',
    templateUrl: './main-app.component.html',
    styleUrls: ['./main-app.component.css']
})
export class MainAppComponent implements OnInit {

    showApp = false;
    configWeb: ConfigWebsite;
    loggedIn = false;
    errorPage: any;
    showCart = false;
    mutationObserver: MutationObserver;
    langCurrent = 'fr';
    isSwt = false;
    styleBackground = {};
    isWidget = false;
    template = null;


    constructor(private sessionRedisService: SessionRedisService,
                private websiteSaleService: WebsiteSaleService,
                private langsObserverService: LangsObserverService,
                public translate: TranslateService,
                private router: Router,
                private route: ActivatedRoute,
                private clientService: ClientService,
                private elm: ElementRef,
                public subdomainService: SubdomainService,
                private browserFaviconsService: BrowserFaviconsService,
                private analyticsService: AnalyticsService,
                private themeService: ThemeService,
                private redirectUrlService: RedirectUrlService,
                public pageService: PageSaleService,
                public priceManagerService: PriceManagerService,
                private location: Location,
                public symplifyCartService:SymplifyCartService,
                private cartService: CartService,
    ) {
        registerLocaleData(localeFr, 'fr');

        const targetNode = document.querySelector('tw-root');
        this.mutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
                mutations.forEach((mutation: MutationRecord) => {

                    //  console.log(mutation);
                    //   const url = elm.nativeElement.getAttribute('url');
                    // //  console.log(url);
                    //   this.subdomainService.setUrl(url);
                    //   this.initApp();
                });
            }
        );
        this.mutationObserver.observe(targetNode, {
            attributes: true,
            characterData: true,
            attributeOldValue: true
        });

        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                // Show loading indicator


                if (this.isWidget) {
                    // console.log('llllllllllllllllllllll', window.location.host + event.url);

                    // window.open(window.location.host + event.url, '_blank');
                    const currentRoute = this.router.routerState;

                    if (currentRoute.snapshot.url !== event.url) {

                        let urlRedirect = window.location.origin + event.url;
                        urlRedirect = urlRedirect.replace('widget=true', '');
                        //console.log(urlRedirect);
                        window.open(urlRedirect, '_blank');
                        this.router.navigateByUrl(currentRoute.snapshot.url, {skipLocationChange: true}).then(r => {

                        });
                    }

                }

            }

            if (event instanceof NavigationEnd) {
                // Call is from confirmation.component.ts because it needs the saleId
                // Hide loading indicator
                if (this.router.url.search('/confirmation') !== -1) {
                    this.showCart = false;
                } else {
                    if (this.configWeb) {
                        this.showCart = this.configWeb.cart;
                    }
                }
                if (this.router.url.length === 3) {
                    this.goToRoute('/pages');
                }
                this.loggedIn = this.clientService.isLoggedInFromSession();
                //console.log(event);

                if (environment.systemValues === 'tkp' && environment.checkout === true) {
                    const urlCheckoutValid = ['checkout/mode', 'checkout/checkout-confirmation'];
                    const isCheckoutMode = urlCheckoutValid.find(value => event.urlAfterRedirects.search(value) !== -1);
                    if (isCheckoutMode && window.location.origin.search('checkout') === -1) {
                        SessionLocaleService.putSync('subdomain',this.subdomainService.getSubdomain())
                        let newUrl = window.location.origin.replace(this.subdomainService.getSubdomain(), 'checkout') +
                            '/' + this.translate.currentLang + '/checkout/mode';
                        //console.log(newUrl);
                        const param = HelpsService.getUrlParams();
                        if (Object.keys(param).length !== 0) {
                            newUrl = newUrl + '?';
                            Object.keys(param).forEach(value => {
                                //console.log(value);
                                newUrl = newUrl + '&' + value + '=' + param[value];
                            });
                            newUrl = newUrl + '&subdomain=' + this.subdomainService.getSubdomain();
                        } else {
                            newUrl = newUrl + '?subdomain=' + this.subdomainService.getSubdomain();
                        }
                        window.location.href = newUrl;

                    }
                    if (!isCheckoutMode && window.location.hostname.search('checkout') !== -1) {


                        let subdomain = this.subdomainService.getSubdomain()
                        if(!subdomain || subdomain === '' || subdomain ==='checkout'){
                            subdomain = SessionLocaleService.getSync('subdomain')
                        }
                        let newUrl = window.location.href.replace('checkout', subdomain);
                        newUrl = newUrl.replace('subdomain=' + subdomain, '');
                        //console.log(newUrl)
                        //console.log("History.state before pushState: ", history.state);
                       // window.history.replaceState(null, '', newUrl);

                        //console.log(   window.location.href)
                        window.location.href = newUrl;

                    }
                }
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator
                // console.info(event.url);
                // Present error to user
                //console.log(event.error);
            }
        });

    }

    async ngOnInit() {
        this.isSwt = HelpsService.getValueParam('swt');
        await this.symplifyCartService.init(this.translate.currentLang,true)
        this.clientService.createInstanceAuthServiceSocial();
        this.isWidget = !!this.route.snapshot.queryParams['widget'];
        this.clientService?.authServiceSocial?.readyState.subscribe(value => {
            //console.log(value);
        });
        this.redirectUrlService._url.asObservable().subscribe(value => {
            /*** redirect url **/
            //console.log(value);
            if (value.length > 0) {
                if (this.configWeb && this.configWeb.cartRedirectionUrl) {
                    try {
                        if (this.configWeb.cartRedirectionUrl && this.configWeb.cartRedirectionUrl.search('{') !== -1) {
                            this.configWeb.cartRedirectionUrl = this.configWeb.cartRedirectionUrl.replace('{}', this.translate.currentLang);
                        }
                        window.location.href = this.configWeb.cartRedirectionUrl;
                    } catch (e) {
                        //console.log(e);
                    }


                } else {
                    this.router.navigate([this.translate.currentLang + '/pages']);
                }
            }
        });
        this.clientService?.authServiceSocial?.authState.subscribe((user) => {
            if (user && user.facebookAuthResponse) {
                const facebookUser = <FacebookUser>{
                    userID: user.id,
                    email: user.email,
                    first_name: user.firstName,
                    last_name: user.lastName,
                    signedRequest: user.facebookAuthResponse.signedRequest
                };
                this.clientService.isLoggedIn(this.configWeb.accountCode).subscribe(value => {
                    if (!value) {
                        this.clientService.connectFacebook(facebookUser, 'MainAppComponent').subscribe(value => {

                        }, error1 => {

                        });
                    }
                });

            }
            /** idToken  only google**/
            if (user && user.idToken) {
                const googleUser = <GoogleUser>{
                    idToken: user.idToken,
                    userID: user.id,
                    email: user.email,
                    first_name: user.firstName,
                    last_name: user.lastName

                };
                this.clientService.isLoggedIn(this.configWeb.accountCode).subscribe(value => {
                    if (value === null) {
                        return;
                    }
                    if (!value) {
                        this.clientService.connectGoogle(googleUser).subscribe(value => {

                        }, error1 => {

                        });
                    }
                });

            }
        }, error1 => {

        });
        this.initApp();
        this.clientService.subLogin.asObservable().subscribe(value => {
            // console.log(value);
            this.loggedIn = value;
            if(value){
                this.symplifyCartService.addAllCart(this.cartService.cartSub.value)
            }

        });
    }

    initApp() {
        this.configWeb = this.websiteSaleService.getLocalConfig();
        if (this.configWeb.backgroundImage && this.configWeb.backgroundImage.length > 2) {
            this.styleBackground['background-image'] = `url('${this.configWeb.backgroundImage}')`;
        }
        if (this.configWeb.backgroundColor && this.configWeb.backgroundColor.length > 2) {
            this.styleBackground['background-color'] = this.configWeb.backgroundColor;
        }
        if (this.configWeb.subdomain === 'www') {
            this.template = true;
            environment.color.primary = '#121944';
            // #F4F4F9
        }

        ThemeService.changeTheme(this.configWeb.themePalette);
        document.documentElement.style.setProperty(
            `--theme-primary-50`, '#F4F4F9'
        );
        /*** get css pour la ville de quebec ***/
        if (this.configWeb.accountCode === 'LVDQ') {
            HelpsService.injectCss('https://ticketpro.s3.us-east-1.amazonaws.com/demo/vq/css/css_villeQC.css').then(() => {
            }).catch(error => {
                // console.error(error);
            });
        }

        this.showCart = this.configWeb.cart;
        if (this.configWeb.images) {
            const icon = this.configWeb.images.filter(value => value.imageSize === 'SMALL');
            if (icon.length > 0) {
                this.browserFaviconsService.activate(icon[0].url);
            } else {
                this.browserFaviconsService.activate('');
            }
        }

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            // do something
            this.langCurrent = event.lang;
            this.langsObserverService.addLang(event.lang);

        });
        this.clientService.isLoggedIn(this.configWeb.accountCode).subscribe(value => {
            if (!value) {
                this.clientService.loggedOutFromSession();
            }
            this.clientService.getClientSelf(new HttpParams().set('cache', '0')).subscribe(clientDetails => {
                this.showApp = true;
                if (clientDetails) {
                    this.loggedIn = true;
                }
                this.showHtmlByLang();
                setTimeout(() => {
                    this.addVilleQuebec();
                }, 2000);

            }, error1 => {
                this.showApp = true;
                setTimeout(() => {
                    this.addVilleQuebec();
                }, 2000);
                this.showHtmlByLang();
                this.clientService.loggedOutFromSession();
                this.clientService.logoutClient(this.configWeb.accountCode).subscribe(() => {
                });
            });
        }, error1 => {
            this.showApp = true;
            this.clientService.loggedOutFromSession();
            this.showHtmlByLang();
        });
    }

    addVilleQuebec() {
        //console.log('ville quebec------------------');
        const element = document.querySelector('#vq-icon-menu-controle');
        if (!element) {
            //console.log('not header ville quebec------------------');
            return;
        }

        document.getElementById('vq-icon-menu-controle')
            .addEventListener('click', function (event) {
                // do something
                //console.log('hhhhhhhhhhhhhhhhhh');
                const elm = document.querySelector('.openMenuActive');
                //console.log(elm);
                const elementBody = document.querySelector('#mobile-menu-container');
                const element = document.querySelector('#vq-icon-menu-controle');
                const listLink = document.querySelector('#myLinks');
                if (!elm) {
                    element.classList.add('openMenuActive');
                    // @ts-ignore
                    elementBody.style.height = '391px';
                    // @ts-ignore
                    listLink.style.display = 'grid';

                } else {
                    element.classList.remove('openMenuActive');
                    // @ts-ignore
                    elementBody.style.height = '202px';
                    // @ts-ignore
                    listLink.style.display = 'none';
                }

                //console.log(document.querySelector('.openMenuActive'));
            });
    }

    goClientRoute(val) {
        if (val === 'logout') {
            this.analyticsService.trackLogout();
            this.clientService.logoutClient(this.configWeb.accountCode).subscribe(value => {
                //this.router.navigate([this.translate.currentLang + '/client/login']);

                if (this.router.url.search('/checkout/mode') !== -1) {
                    /*** dans la page checkout/mode il faut le rediriger  **/

                    return;
                }
                this.goToRoute('/client/login');
                this.loggedIn = false;
            });
        } else if (this.loggedIn) {
            //  this.router.navigate([this.translate.currentLang + '/client/auth/' + val]);
            this.goToRoute('/client/auth/' + val);
        } else {
            //this.router.navigate([this.translate.currentLang + '/client/' + val]);
            this.goToRoute('/client/' + val);
        }
    }

    goToRoute(val) {

        if (this.translate && this.translate.currentLang) {
            this.router.navigate([this.translate.currentLang + val], {queryParamsHandling: 'merge'});
        } else {
            this.router.navigate([val], {queryParamsHandling: 'merge'});
        }
    }

    showHtmlByLang() {

        setTimeout(() => {

            //console.log(this.translate.currentLang);
            if (this.translate.currentLang !== 'fr') {
                if (document.querySelectorAll('.langFr')) {
                    const temp = <any[any]>document.querySelectorAll<Element>('.langFr');
                    temp.forEach((value, index, array) => {
                        temp[index].style.display = 'none';
                    });

                }
            } else {
                if (document.querySelectorAll('.langEn')) {
                    const temp = <any[any]>document.querySelectorAll<Element>('.langEn');
                    temp.forEach((value, index, array) => {
                        temp[index].style.display = 'none';
                    });
                }
            }
        }, 100);
    }


}
