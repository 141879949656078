import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddressComponent} from './address/address.component';
import {PhoneComponent} from './phone/phone.component';
import {AddressesComponent} from './addresses/addresses.component';
import {PhonesComponent} from './phones/phones.component';
import {SharedModule} from '../shared/shared.module';
import {MaterialModule} from '../material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AddressViewComponent } from './address-view/address-view.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MaterialModule,
        TranslateModule,
    ],
    declarations: [AddressComponent, PhoneComponent, AddressesComponent, PhonesComponent, AddressViewComponent],
    exports: [CommonModule, AddressComponent, PhoneComponent, AddressesComponent, PhonesComponent, AddressViewComponent]
})
export class AddressModule {
}
