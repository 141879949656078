import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {SubdomainService} from '../services/subdomain.service';
import {WebsiteSaleService} from '../services/website-sale.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationUserService} from '../services/authentication-user.service';


@Injectable({
    providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor {

    constructor(private subdomainService: SubdomainService,
                private websiteSaleService: WebsiteSaleService,
                private authenticationUserService: AuthenticationUserService,
                public translate: TranslateService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       // console.log('addConfigApiURL', this.addConfigApiURL(req));
        return next.handle(this.addConfigApiURL(req));
    }

    addConfigApiURL(req: HttpRequest<any>) {
        let url;
        const appRoute = document.querySelector('tw-root');
        if (appRoute) {
             url = appRoute.getAttribute('url');
         }
       // console.log(req.url)
        if (req.url.search('http') !== -1 || req.url.search('assets') !== -1) {

              if(req.url.search('assets') !== -1){
                  const urlOrg = req.url.replace('./', '/');
                  if (url && req.url.search('assets') !== -1) {
                      return req.clone(
                          {
                              url: 'http://' + url + urlOrg
                          });
                  }
              }


            return req;
        }
        const ht = {};

        ht['language'] = this.translate.currentLang;
        if (this.websiteSaleService.getLocalConfig()) {
            if (req.url.search('context=WEB') === -1) {
                ht['context'] = 'WEB';
            }

            if (this.websiteSaleService.getLocalConfig().accountId) {
                ht['accountId'] = this.websiteSaleService.getLocalConfig().accountId + '';
            }
            if (this.websiteSaleService.getLocalConfig().accountCode) {
                ht['accountCode'] = this.websiteSaleService.getLocalConfig().accountCode;
            }
        }
        const subdomain = this.subdomainService.getSubdomain(url);
        const urlApi = this.subdomainService.getApiUrl( req.url);
        console.log('^&^&^&^&^&^&^&^&^^&^&^&^&^&**********',urlApi)
        if (subdomain) {
            ht['subdomain'] = subdomain;
        }
        const isMember = this.authenticationUserService.isMember()
        if(isMember){
            ht['member'] = isMember;
        }


        return req.clone(
            {
                url: urlApi,
                setParams: ht
            }
        );

    }

}
