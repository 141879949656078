import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionRedisService } from '../services/session-redis.service';

@Injectable({
    providedIn: 'root'
})
export class SessionSaleInterceptorService implements HttpInterceptor {


    constructor(private sessionRedisService: SessionRedisService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        const se = this.sessionRedisService.getXSessionLocal('SessionSaleInterceptorService') || '';
        if (req?.url?.search('/actions/authentication') !== -1) {
            //console.log(req);
            //console.log(se);
        }

        const authClient = req.clone({ setHeaders: { 'x-session-token': se } });

        return next.handle(authClient);
    }
}
