import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
/**
 *@deprecated use transformHtml project shared-lib
 */
@Pipe({
    name: 'transformHtml'
})

export class TransformHtmlPipe implements PipeTransform {

    constructor(private sanitized: DomSanitizer) {
    }

    transform(value) {
       // console.log('=============================',value)
        if (!value) {
            return '';
        }
        return this.sanitized.bypassSecurityTrustHtml('<div>' + value + '<div>');
    }

}
