import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Cart } from '../../../../../projects/shared-lib/src/lib/models/cart';
import { CartService } from '../../../shared/services/cart.service';
import { ActionCartService } from '../../../shared/services/action-cart.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PriceManagerService } from '../../../page/services/price-manager.service';
import { MessageObserverService } from '../../../shared/services/message-observer.service';
import { TranslateService } from '@ngx-translate/core';
import { ShippingService } from '../../../shipping/services/shipping.service';
import { AnalyticsService } from '../../../shared/services/analytics.service';

@Component({
    selector: 'tw-cart-desktop',
    templateUrl: './cart-desktop.component.html',
    styleUrls: ['./cart-desktop.component.css']
})
export class CartDesktopComponent implements OnInit {

    @Output() offTimer = new EventEmitter<boolean>();
    @Input() isSummary = false;
    cart: Cart;
    future: Date;
    openCart = false;


    constructor(private cartService: CartService,
                private actionCart: ActionCartService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private priceManagerService: PriceManagerService,
                protected messageObserverService: MessageObserverService,
                public translate: TranslateService,
                private shippingService: ShippingService,
                private analyticsService: AnalyticsService) {


        this.actionCart.watchActionCartResponse().subscribe(() => {
            // console.log("actionCart")
            setTimeout(() => {
                this.getCart();
            }, 1000);


        });
    }

    ngOnInit() {
        this.getCart();

    }


    getCart() {
        const sub = this.cartService.getCart().subscribe(cart => {
            this.cart = cart;
            /** have to delete this code whene i use de master bd**/
            this.formatToGa();
            if (this.cart.reservationTime && this.cart.reservationTime > 0) {
                this.future = new Date(this.cart.reservationTime);
            } else {
                this.offTimer.emit(true);
            }

            sub.unsubscribe();
        });
    }

    formatToGa() {
        for (const cartElement of this.cart.cartElements) {
            cartElement.prices.forEach(pr => {
                const isGa = pr.cartDetailToken.filter(value => value.seat !== null);
                pr.ga = isGa.length === 0;
                pr.rs = isGa.length > 0;
            });
        }
    }

    timerHold(val) {
        if (val) {
            const flatPrices = [];
            this.cart.cartElements.forEach(el => {
                el.prices.forEach(price => {
                    flatPrices.push(price);
                });
            });
            this.analyticsService.trackCartTimeOutFromCartPrice(flatPrices);

            this.getCart();
        }
    }

    trackContinueShopping() {
        this.analyticsService.trackContinueShopping();
    }

    goToHome() {
        this.trackContinueShopping();
        // this.router.navigate([`${this.translate.currentLang}`], {queryParamsHandling: 'merge'})
        //     .then(() => {
        //     }).catch(error => {
        //     console.error(error);
        // });
    }

    goToCheckout() {
        this.router.navigate([this.translate.currentLang + '/client/middlewareClient'], { queryParamsHandling: 'merge' })
            .then(() => {
            }).catch(error => {
            //console.error(error);
        });
    }

}
