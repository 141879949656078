import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[twEqualValidator]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidatorDirective), multi: true }
    ]
})
export class EqualValidatorDirective implements Validator {
    @Input('twEqualValidator') val: string;

    constructor() {
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const val1 = control.value;
        const val2 = control.root.get(this.val).value;
        if (val1 && val2) {
            if (val1 !== val2) {
                return <ValidationErrors>{
                    equalValidator: 'Not equal'
                };
            }
        }
        return null;
    }

}
