import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShippingMode } from '../../models/shipping-mode';
import { SessionLocaleService } from '../../shared/services/session-locale.service';
import { ShippingModeRest } from '../../models/shipping-mode-rest';
import { Cart } from '../../../../projects/shared-lib/src/lib/models/cart';
import { environment } from '../../../../environments/environment';
import {SubdomainService} from '../../shared/services/subdomain.service';

export interface EventShippingModes {
    eventshippingModes: ShippingMode[];
}

@Injectable({
    providedIn: 'root'
})
export class ShippingService {
    mandatoryAddress = false;
    private subjectRsp = new Subject<any>();

    constructor(
        private http: HttpClient,
        private sessionLocaleService: SessionLocaleService,
        private subdomainService: SubdomainService) {
    }

    static hasContainsPriceByName(cart: Cart, name) {
        let result = false;
        cart.cartElements.forEach(cartElement => {
            cartElement.prices.forEach(price => {
                if (price.priceType.search(name) > -1) {
                    result = true;
                }
            });
        });
        return result;
    }

    static hasContainsPriceById(cart: Cart, ids) {
        let result = false;
        cart.cartElements.forEach(cartElement => {
            cartElement.prices.forEach(price => {
                if (ids.includes(price.id)) {
                    result = true;
                }
            });
        });
        return result;
    }

    static filterForFeq(shippingModeRest: ShippingModeRest, cart: Cart): boolean {
        if (cart.totalQty === 0) {
            return false;
        }
        const laissezPasser = ShippingService.hasContainsPriceByName(cart, 'admission générale');
        const laissezPasserPro = ShippingService.hasContainsPriceByName(cart, 'Passe Pro');
        const multiple = ShippingService.hasContainsPriceByName(cart, 'Multiple');
        const unique = ShippingService.hasContainsPriceByName(cart, 'Unique');
        const jardin = ShippingService.hasContainsPriceByName(cart, 'Jardin');

        if (laissezPasserPro) {
            //return true;
            return shippingModeRest.code === 'ETICKET_Pro';

        }
        if (!multiple && !jardin && !unique && !laissezPasser) {
            return true;
        }

        if ((multiple || jardin) && !unique && !laissezPasser) {
            return shippingModeRest.code === 'ETICKET';
        }

        if (laissezPasser && !unique && !multiple) {
            return ['CAN', 'XPQC', 'XPCAN', 'XPWORLD'].indexOf(shippingModeRest.code) > -1;
            //return ['BILLETTERIE'].indexOf(shippingModeRest.code) > -1;
        }

        return ['XPQC', 'XPCAN', 'XPWORLD'].indexOf(shippingModeRest.code) > -1;
    }

    static filterForTkp(shippingModeRest: ShippingModeRest, cart: Cart): boolean {
        if (cart.totalQty === 0) {
            return false;
        }
        const passportEchange = ShippingService.hasContainsPriceById(cart, [1557486213, 1557486214, 1557486215, 1557486216, 1557486217, 1557486218]);

        if (passportEchange) {
            return shippingModeRest.code === 'WCTSDFREE';
        }
        return shippingModeRest.code !== 'WCTSDFREE';
    }

    watchShippingSelect(): Observable<any> {
        return this.subjectRsp.asObservable();
    }

    private isNeedPostalCode(shippingMode: ShippingMode[]): void {
        shippingMode.forEach(s => {
            s.shippingModeRests.forEach(tr => {
                Object.keys(tr.territory).forEach(p => {
                    if (tr.territory[p] && !this.mandatoryAddress) {
                        this.mandatoryAddress = true;
                    }
                });
            });
        });
    }

    getShipping(httpParams ?: HttpParams): Observable<EventShippingModes> {
        return this.http.get<EventShippingModes>('v1/shippings', { params: httpParams }).pipe(
            map(s => {
                this.isNeedPostalCode(s.eventshippingModes);
                return s;
            })
        );
    }

    isFreeShipping(sh: EventShippingModes) {
        try {
            if (sh.eventshippingModes.length === 1) {
                if (sh.eventshippingModes[0].shippingModeRests.length === 1) {
                    if (sh.eventshippingModes[0].shippingModeRests[0].price === 0) {
                        return true;
                    }
                }
            }
        } catch (e) {
            return false;
        }

    }

    getShippingSelectedLocal() {
        return SessionLocaleService.getSync('shippingSelected');
    }
    getTotalShippingSelected() {
        return SessionLocaleService.getSync('shippingSelected');
    }

    addShippingSelectedLocale(val: ShippingModeRest, key) {
        let t = this.getShippingSelectedLocal();
        if (!t) {
            t = {};

        }
        t[String(key)] = val;
        JSON.stringify(t);
        console.log(t);

        SessionLocaleService.putSync('shippingSelected', t);
        this.subjectRsp.next('add');
    }

    deleteShippingSelectedLocale() {
        //console.log("dddddddddddddddddddddddd")
        SessionLocaleService.deleteByKey('shippingSelected');
        this.subjectRsp.next('delete');
    }

    getHasMandatoryAddress() {
        return this.mandatoryAddress;
    }

    getShippingByTerritory(keyTerritory: string, shippingMode: ShippingModeRest[], cart?: Cart): ShippingModeRest[] {
        //keyTerritory exp:QC,BC,MB .....  or HORS_CANADA
        let sh: ShippingModeRest[] = [];
        let hasOnlyFestivalPass = false;
        if (this.mandatoryAddress) {
            if (environment.systemValues === 'feq') {
                hasOnlyFestivalPass = this.hasOnlyFestivalPass(cart);
            }
            shippingMode.forEach(shippingModeRest => {
                Object.keys(shippingModeRest.territory).forEach(() => {
                    if (shippingModeRest.territory[keyTerritory]) {
                        if (!sh.find(value => value.code === shippingModeRest.code) || !this.hasTerritoryChecked(shippingModeRest.territory)) {
                            if (environment.systemValues === 'feq' && this.subdomainService.getSubdomain() === 'billetterie') {
                                if (ShippingService.filterForFeq(shippingModeRest, cart)) {
                                    sh.push(shippingModeRest);
                                }
                            } else {
                                sh.push(shippingModeRest);
                            }
                        }

                    }
                });
            });
        } else {
            if (environment.systemValues === 'tkp') {
                shippingMode.forEach(shippingModeRest => {
                    if (ShippingService.filterForTkp(shippingModeRest, cart)) {
                        sh.push(shippingModeRest);
                    }
                });
            } else {
                sh = shippingMode;
            }
        }
        return sh;
    }

    hasTerritoryChecked(territory) {
        let bool = false;
        if (!territory || typeof territory !== 'object') {
            return true;
        }
        Object.keys(territory).forEach(p => {
            if (territory[p]) {
                bool = true;
            }
        });
        return bool;
    }


    hasOnlyFestivalPass(cart: Cart) {
        let result = true;
        cart.cartElements.forEach(cartElement => {
            cartElement.prices.forEach(price => {
                if (!price.name.startsWith('Laissez-passer')) {
                    result = false;
                }
            });
        });
        return result;
    }

}
