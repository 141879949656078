import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TicketElement} from '../../../../models/ticket-element';
import {MailCategory} from '../../../../models/ticket-detail-token';
import {TicketsService} from '../../../tickets.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'tw-client-ticket-detail',
    templateUrl: './client-ticket-detail.component.html',
    styleUrls: ['./client-ticket-detail.component.css']
})
export class ClientTicketDetailComponent implements OnInit {
    @Input() ticketElement: TicketElement;
    @Output() displayPdf: EventEmitter<string> = new EventEmitter<string>();
    displayDetails = false;
    ticketsFileUrl: string;
    seatsFileUrl: string;
    filename: string;

    constructor(private ticketService: TicketsService,
                private translateService: TranslateService) {
    }

    ngOnInit() {
    }

    displayPdfFile(url: string) {
        this.displayPdf.emit(url);
    }

    getMailTypes() {
        return Object.keys(MailCategory);
    }

    isPrintableCategory(category: MailCategory|string) {
        return category === MailCategory.E_TICKETS || category === MailCategory.SMARTPHONE;
    }

    printTickets(mail: MailCategory|string) {
        this.displayDetails = true;
        if (!this.ticketsFileUrl) {
            const sodIds: number[] = [];
            this.ticketElement.pricesByMail[mail].forEach((el: { upcomingTicketDetailTokens: any[]; }) => {
                el.upcomingTicketDetailTokens.forEach(token => {
                    sodIds.push(token.sodId);
                });
            });
            this.ticketService.getTicketsPdfForSodIds(sodIds, mail).subscribe((pdf) => {
                this.ticketsFileUrl = URL.createObjectURL(pdf);
                this.displayPdf.emit(this.ticketsFileUrl);
            });
        } else {
            this.displayPdf.emit(this.ticketsFileUrl);
        }
    }

    detailSeats(mail: MailCategory|string) {
        this.displayDetails = true;
        if (!this.seatsFileUrl) {
            const seatIds: number[] = [];
            this.ticketElement.pricesByMail[mail].forEach(el => {
                el.upcomingTicketDetailTokens.forEach(token => {
                    seatIds.push(token.seat.dispositionDetailId);
                });
            });
            this.ticketService.getSeatsPdfForEventAndDDId(this.ticketElement.pricesByMail[mail][0].eventId, seatIds).subscribe((pdf) => {
                this.seatsFileUrl = URL.createObjectURL(pdf);
                this.displayPdf.emit(this.seatsFileUrl);

            });
        } else {
            this.displayPdf.emit(this.seatsFileUrl);
        }
    }


    addToGoogleWallet(mail: MailCategory|string) {
        const prices = this.ticketElement.pricesByMail[mail];
        this.ticketService.getGoogleWalletLink(prices[0].addToGoogleWalletLink)
            .subscribe(link => {
                window.open(link.replace(/['"]+/g, ''), '_blank');
            });

    }

    addToAppleWallet(mail: MailCategory|string) {
        const prices = this.ticketElement.pricesByMail[mail];
        this.ticketService.getAppleWalletLink(prices[0].addToAppleWalletLink)
            .subscribe(blob => {
                const fileName = this.generatePKPassesFileName(prices[0].addToAppleWalletLink);
                const url = URL.createObjectURL(blob);
                this.downloadFile(url, fileName);
            });
    }

    generatePKPassesFileName(filename: string) {
        const regExp = /(?<=apple\/)(.*)(?=\?token)/;
        return filename.match(regExp)?.pop();
    }

    downloadFile(url: string, fileName: string) {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName;
        anchor.click();
        URL.revokeObjectURL(url);
    }

    getCurrentLanguage(): string {
        return this.translateService.currentLang;
    }

}
