<form #clientForm="ngForm" style="margin: auto;" class="tw-padding-10 tw-max-width-6">
  @if (progressLoad) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
  <br>
    @if (!this.token) {
      <mat-form-field class="tw-input">
        <input matInput
          placeholder="{{'client.oldPassword' | translate}}"
          ngModel required
          name="oldPassword"
          #oldPassword
          type="password"
          >
        <tw-show-password matSuffix [inputRef]="oldPassword"></tw-show-password>
        <mat-error>
          <span [innerHTML]="'formMsg.isRequired' | translate"></span>
        </mat-error>
      </mat-form-field>
    }

    <mat-form-field class="tw-input">
      <input matInput
        placeholder="{{'client.newPassword' | translate}}"
        ngModel required
        name="newPassword"
        (input)="resetConfirmation()"
        [minlength]="8"
        [maxlength]="18"
        twUppercaseValidator
        twLowercaseValidator
        twSpecialCharactersValidator
        twHasNumberValidator
        type="password"
        #newPassword="ngModel"
        #newPasswordT
        >
      <tw-show-password matSuffix [inputRef]="newPasswordT"></tw-show-password>


      <mat-error>
        <tw-form-error-message [errors]="newPassword.errors"
        [customFormErrors]="{equalValidator: 'formMsg.errors.notSamePassword'}"></tw-form-error-message>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="tw-input">
      <input matInput
        placeholder="{{'client.newPasswordConfirm' | translate}}"
        ngModel required
        #cPassword="ngModel"
        name="cPassword"
        twEqualValidator="newPassword"
        type="password"
        #confirmPassword
        >
      <mat-icon (click)="confirmPassword.type=confirmPassword.type=='password'?'text':'password'"
        matSuffix color="primary"
        >
        {{confirmPassword.type == 'password' ? 'visibility_off' : 'visibility'}}
      </mat-icon>
      <mat-error>
        <tw-form-error-message [errors]="cPassword.errors"
        [customFormErrors]="{equalValidator: 'formMsg.errors.notSamePassword'}"></tw-form-error-message>
      </mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" [disabled]="load && !clientForm.valid "
      (click)="changePw(clientForm)"> {{ 'formMsg.submit' |
      translate }}
      @if (!load) {
        <div class="cssload-speeding-wheel"></div>
      }
    </button>
  </form>
