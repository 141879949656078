@if (!loadPage) {
  <mat-progress-bar style="margin-top: 10px" mode="indeterminate"></mat-progress-bar>
}

<!--<div style="margin: 0 auto;width: 100%;min-width:320px;text-align: center">-->
<!--    <h1>{{"title.Link_tickets_movie" | translate}}</h1>-->
<!--</div>-->


@if (flexPassResponse) {
  <div class="tw-flex tw-column">
    <!--    <div  *ngIf="flexPassResponse.imageUrl"  class="tw-page-container-image " style="margin: 25px 5px;flex-grow: 1;text-align: center;" >-->
    <!--        <img style="max-width: 300px"-->
    <!--            [src]="flexPassResponse.imageUrl | twUrl"-->
    <!--             alt="{{flexPassResponse.title}}">-->
  <!--    </div>-->
  @if (flexPassResponse.title) {
    <div class="tw-page-container-image " style="text-align: center;">
      <h1>{{flexPassResponse.title}}</h1>
      @if (flexPassResponse.description) {
        <div
          style="flex-grow: 4;margin:10px 10px 10px 20px;font-size: 19px;font-weight: bold">
          <div [innerHTML]="flexPassResponse.description |transformHtml">
          </div>
        </div>
      }
      <p>
        {{"flexpass.t1" | translate}}
      </p>
      <p>
        {{"flexpass.t2" | translate}}<strong>&nbsp;{{"flexpass.t2bis" | translate}}</strong>
      </p>
      <p>
        {{"flexpass.t3" | translate}}
      </p>
    </div>
  }
</div>
}
@if (!flexPassResponse && loadPage) {
  <div style="margin: 0 auto;width: 300px;text-align: center">
    <h4>{{"title.emptyTickets" | translate}}</h4>
  </div>
}

@if (flexPassResponse && loadPage) {
  <div class="tw-flex tw-column"
    style="max-width: 800px;margin: 0 auto;position: relative">
    @for (film of flexPassResponse.alreadyExchanged | keyvalue; track film; let i = $index) {
      @if (film.value) {
        <div class="tw-flex tw-row " style="border-bottom: 1px rgba(168,168,168,0.82) solid;padding: 10px"
          >
          <div style="flex-grow: 1;">
            <h1>{{i + 1}}</h1>
          </div>
          <div class="tw-flex tw-row" style="flex-grow: 5;align-items: center;padding: 16px;">
            <div style="margin-right: 20px">
              @if (film.value.images && film.value.images[0] ) {
                <img style="max-width: 100px"
                  [src]="film.value.images[0].url | twUrl"
                  >
              }
            </div>
            <div style="align-items: center;">
              <h2 style="margin: 0;padding: 0">{{film.value.title}}</h2>
              {{film.value.venue}}<br>
              {{film.value.itemHours[0].formattedDate}}
              <!--                {{film.location | json}}-->
              @if (film.value.location) {
                <div>
                  @if (film.value.location.addressLine1) {
                    <span> {{film.value.location.addressLine1}}</span>
                    }@if (film.value.location.addressLine1) {
                    <br
                      >
                  }
                  @if (film.value.location.city) {
                    <span> {{film.value.location.city}}</span>
                  }
                  @if (film.value.location.provinceState) {
                    <span>, {{film.value.location.provinceState}}</span>
                  }
                  @if (film.value.location.postalCode) {
                    <span>, {{film.value.location.postalCode}}</span>
                  }
                  @if (film.value.location.country) {
                    <span>, {{film.value.location.country}}</span>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      }
    }
    @for (film of flexPassResponse.alreadyExchanged | keyvalue; track film; let i = $index) {
      @if (!film.value) {
        <div class="tw-flex tw-row " style="border-bottom: 1px rgba(168,168,168,0.82) solid;padding: 10px"
          >
          <div style="flex-grow: 1;width: 32px;">
            <h1>{{i + 1}}</h1>
          </div>
          <div class="tw-flex tw-row" style="flex-grow: 5;align-items: center;padding: 16px;position: relative">
            @if (!exchanges
              || !exchanges[film.key]) {
              <img class="tw-desktop-g" src="./assets/resources/svg/empty-response.svg" style="width: 400px"
                alt="cartlogo">
            }
            @if (!exchanges
              || !exchanges[film.key]) {
              <img class="tw-tablet" src="./assets/resources/svg/empty-response.svg" style="width: 320px"
                alt="cartlogo">
            }
            @if (!exchanges
              || !exchanges[film.key]) {
              <img class="tw-mobile" src="./assets/resources/svg/empty-response.svg" style="width: 220px"
                alt="cartlogo">
            }
            <div style="margin-right: 20px">
              @if (exchanges
                && exchanges[film.key]
                && exchanges[film.key].images
                && exchanges[film.key].images[0]) {
                <img style="max-width: 100px"
                  [src]="exchanges[film.key].images[0].url | twUrl"
                  >
              }
            </div>
            @if (exchanges && exchanges[film.key]) {
              <div style="align-items: center;"
                >
                <h2 style="margin: 0;padding: 0">{{exchanges[film.key].title}}</h2>
                {{exchanges[film.key].venue}}
                {{exchanges[film.key].itemHours[0].formattedDate}}
                @if (exchanges[film.key].location) {
                  <div>
                    @if (exchanges[film.key].location.addressLine1) {
                      <span
                      > {{exchanges[film.key].location.addressLine1}}</span>
                      }@if (exchanges[film.key].location.addressLine1) {
                      <br
                        >
                    }
                    @if (exchanges[film.key].location.city) {
                      <span
                        > {{exchanges[film.key].location.city}}
                      </span>
                    }
                    @if (exchanges[film.key].location.provinceState) {
                      <span
                        > ,{{exchanges[film.key].location.provinceState}}
                      </span>
                    }
                    @if (exchanges[film.key].location.postalCode) {
                      <span
                      > ,{{exchanges[film.key].location.postalCode}}</span>
                    }
                    @if (exchanges[film.key].location.country) {
                      <span
                        > ,{{exchanges[film.key].location.country}}
                      </span>
                    }
                  </div>
                }
              </div>
            }
            @if (!exchanges
              || !exchanges[film.key]) {
<div style="flex-grow: 1;padding: 16px;text-align: right; position: absolute;
    right: 0;bottom: 0">
                <button class="tw-primary" mat-raised-button color="primary" (click)="openDialog(film)">
                  {{ "formMsg.selectFilm" | translate }}
                </button>
              </div>
            }
            @if (exchanges
              && exchanges[film.key]) {
<div style="flex-grow: 1;padding: 16px;text-align: right; position: absolute;
    right: 0;top: -8px">
                <!--                    <button class="tw-primary" mat-raised-button color="primary" (click)="openDialog(film)">-->
                <!--                        {{ "formMsg.selectFilm" | translate }}-->
              <!--                    </button>-->
              <button mat-icon-button color="primary" class="tw-primary" (click)="openDialog(film)"
                aria-label="Example icon-button with a heart icon">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          }
        </div>
      </div>
    }
  }
  <div class="tw-flex tw-row">
    @if (isSwt) {
      <div style="text-align: left;padding: 20px;flex-grow: 1">
        <button mat-raised-button color="primary" class="tw-primary" (click)="cancel()">
          {{ "title.Cancel" | translate }}
        </button>
      </div>
    }
    <div style="text-align: right;padding: 20px;flex-grow: 1">
      @if (exchanges.length > 0) {
        <button mat-raised-button color="primary" class="tw-primary"
          (click)="addFilm()">
          {{ "formMsg.submit" | translate }}
        </button>
      }
      @if (exchanges.length === 0) {
        <button mat-raised-button color="primary" (click)="addFilm()"
          [disabled]="exchanges.length === 0">
          {{ "formMsg.submit" | translate }}
        </button>
      }
    </div>
  </div>
</div>
}


