<div>
  <tw-client-invoice-header [invoice]="invoice" (toggleDetails)="toggleDetails()" [isShowingDetails]="isShowingDetails"></tw-client-invoice-header>
  <div>
    @if (isShowingDetails) {
      <div class="invoice-line-wrapper">
        @if (htmlReport) {
          <div [innerHTML]="htmlReport | transformHtml">
          </div>
        } @else {
          <mat-progress-bar style="margin-top: 10px" mode="indeterminate"></mat-progress-bar>
        }
      </div>
    }
  </div>
</div>