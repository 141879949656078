<mat-form-field [color]="color"
                [floatLabel]="floatLabel"
                [ngClass]="{'active': isFocused}"
                class="ngx-mat-timepicker-control"  appearance="outline">
    <input id="ngx_mat_timepicker_field_{{id}}"
           name="ngx_mat_timepicker_field_{{id}}"
           matInput
           maxlength="2"
           [ngModel]="time | ngxMatTimepickerParser: timeUnit | timeLocalizer: timeUnit : true"
           (ngModelChange)="onModelChange($event)"
           [placeholder]="placeholder"
           [disabled]="disabled"
           (keydown)="onKeydown($event)"
           (beforeinput)="changeTime($event)"
           (focus)="onFocus()"
           (blur)="onBlur()" />
    <div class="arrows-wrap"
         matSuffix>
        <span class="arrow"
              role="button"
              (click)="increase()">
            <svg xmlns="http://www.w3.org/2000/svg"
                 height="18"
                 viewBox="0 0 24 24"
                 width="18">
                <path d="M0 0h24v24H0z"
                      fill="none" />
                <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
            </svg>
        </span>
        <span class="arrow"
              role="button"
              (click)="decrease()">
            <svg xmlns="http://www.w3.org/2000/svg"
                 height="18"
                 viewBox="0 0 24 24"
                 width="18">
                <path d="M0 0h24v24H0V0z"
                      fill="none" />
                <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
            </svg>
        </span>
    </div>
</mat-form-field>
