
@if (locations) {
  <div class="tw-items-list-container" style=" ">
    <!--    {{locations | json}}-->
    <!--    <ng-container *ngFor="let page of locations">-->
    <!--        <div class="item test4 mat-elevation-z1">-->
    <!--            <mat-progress-bar mode="indeterminate" *ngIf="load && pageSelected.id == page.id  "></mat-progress-bar>-->
    <!--            <div class="PicItem">-->
    <!--                <img  alt=""-->
    <!--                     *ngIf="page.images.length>0" src="{{ (page.images | typeImageJson : ImageSizeEnum.SIZE_400X250) | twUrl }}">-->
  <!--            </div>-->
  <!--            <div style="" class="itemblockText4">-->
  <!--                <p class="CTitle" *ngIf="page.name">{{page.name}} </p>-->
  <!--                &lt;!&ndash;                <p *ngIf="page.miniDescription" class="CEndroit"&ndash;&gt;-->
<!--                &lt;!&ndash;                   [innerHTML]="page.miniDescription | transformHtml"></p>&ndash;&gt;-->
<!--                <div-->
<!--                     style="font-size: 11px; padding:9px ; font-style: italic;-->
<!--    color: #535353!important;"-->
<!--                     class="location-page-temp location-page-temp{{page.id}}">-->


<!--                    <span *ngIf="page.addressLine1">{{page.addressLine1}} </span>-->
<!--                    <span *ngIf="page.addressLine2">{{page.addressLine2}}, </span>-->
<!--                    <span *ngIf="page.city">{{page.city}}, </span>-->
<!--                    <span *ngIf="page.provinceState">{{page.provinceState}}</span>-->
<!--                    <span *ngIf="page.postalCode">, {{page.postalCode}}</span>-->
<!--                    &nbsp;-->
<!--                    <a href="{{page | twGoogleMaps}}" target="_blank">-->
<!--                        <svg width="16" height="16">-->
<!--                            <image xlink:href="./assets/resources/svg/map_marker.svg"  width="16" height="16"/>-->
<!--                        </svg>-->
<!--                        &lt;!&ndash;                        <img&ndash;&gt;-->
<!--                        &lt;!&ndash;                        src="./assets/resources/svg/map_marker.svg"&ndash;&gt;-->
<!--                        &lt;!&ndash;                        style="height: 16px;" alt="marker">&ndash;&gt;-->

<!--                    </a>-->
<!--                </div>-->

<!--                <p class="btnboxItem4"><a class="" [disabled]="load" (click)="goTaPage(page)">-->
<!--                    <button class="grow_skew_forward">{{"formMsg.reserve" | translate}}</button>-->
<!--                </a>-->
<!--                </p>-->
<!--            </div>-->

<!--            <div >-->
<!--                <button mat-raised-button color="primary" >jjjjjjjjjjj</button>-->
<!--            </div>-->
<!--        </div>-->
<!--    </ng-container>-->

    @for (page of locations; track page) {


        <mat-card  style="margin: 18px;">
            @if (load && pageSelected.id == page.id  ) {
<mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
            <mat-card-header>

                <mat-card-title style="max-width: 290px">{{page.name}}</mat-card-title>

            </mat-card-header>
            @if (page.images.length>=0) {
<img mat-card-image
                 class="PicItem"
        src="{{ (page.images | typeImageJson : ImageSizeEnum.SIZE_400X250 : './assets/resources/reachstream_video_poster.jpg') | twUrl }}" alt="">
      }
      <mat-card-content>
        <div
                    style="font-size: 11px; padding:9px ; font-style: italic;
    color: #535353!important;"
          class="location-page-temp location-page-temp{{page.id}}">
        @if (page.addressLine1) {
          <span>{{page.addressLine1}} </span>
        }
        @if (page.addressLine2) {
          <span>{{page.addressLine2}}, </span>
        }
        @if (page.city) {
          <span>{{page.city}}, </span>
        }
        @if (page.provinceState) {
          <span>{{page.provinceState}}</span>
        }
        @if (page.postalCode) {
          <span>, {{page.postalCode}}</span>
        }
        &nbsp;
        <a href="{{page | twGoogleMaps}}" target="_blank">
          <svg width="16" height="16">
            <image xlink:href="./assets/resources/svg/map_marker.svg"  width="16" height="16"/>
          </svg>
          <!--                        <img-->
          <!--                        src="./assets/resources/svg/map_marker.svg"-->
          <!--                        style="height: 16px;" alt="marker">-->
        </a>
      </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
      <button mat-stroked-button color="primary" [disabled]="load" (click)="goTaPage(page)">{{"title.detail" | translate}}</button>
    </mat-card-actions>
  </mat-card>
}
@if (locations.length === 0) {
  <div class="tw-center-600 tw-padding-10 mat-elevation-z1"
    style="min-height: 100px;text-align: center">
    {{ 'page.noEvent' | translate }}
  </div>
}
</div>
}
