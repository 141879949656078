import {Directive, forwardRef, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
    selector: '[twUppercaseValidator]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => UppercaseValidatorDirective), multi: true }
    ]
})
export class UppercaseValidatorDirective implements Validator{
    @Input() regExpForUpper = /^(?=.*[A-Z])(.*)$/;

    constructor() {
    }

    validate(control: AbstractControl): ValidationErrors | null {

        const v = control.value;


        if (!this.regExpForUpper.test(v)) {
            return <ValidationErrors>{
                minOneUppercase: 'Include Uppercase Characters:(e.g. ABCDEFGH )'
            };
        }
        return null;
    }

}
