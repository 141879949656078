import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {CartPrice} from '../../../../projects/shared-lib/src/lib/models/cart-price';
import {Cart} from '../../../../projects/shared-lib/src/lib/models/cart';
import {HttpParams} from '@angular/common/http';
import {CartElement} from '../../../../projects/shared-lib/src/lib/models/cart-element';
import {CartService} from './cart.service';
import {PriceManagerService} from '../../page/services/price-manager.service';
import {AnalyticsService} from './analytics.service';
import {SymplifyCartService} from './symplify-cart.service';

export enum EnumTypeActionCart {
    moreOne = 'moreOne',
    lessOne = 'lessOne',
    addElements = 'addElements',
    deleteElement = 'delete',
    deleteAllElement = 'deleteAllElement',
    callCart = 'callCart',
    splitPrice = 'splitPrice'
}

export interface ActionCartRequest {
    type: EnumTypeActionCart;
    prices?: [CartPrice];
    httpParam?: HttpParams;
    keyServer?: string;
    /** symlify ***/
    urlImage?:string
    title?:string;
    date?:string;
    venue?:string;
    from?:string;


}

export interface ActionCartResponse {
    name?: any;
    hold?: any;
    success: boolean;
    message?: any;
    cart?: Cart;
    originalCall?:string

}

@Injectable({
    providedIn: 'root'
})
export class ActionCartService {
    private subjectRqt = new Subject<any>();
    private subjectRsp = new Subject<ActionCartResponse>();
    cartTimeOut = new BehaviorSubject(-1);

    constructor(private cartService: CartService,
                private priceManagerService: PriceManagerService,
                private analyticsService: AnalyticsService,
                public symplifyCartService:SymplifyCartService) {

   }

    watchActionCartRequest(): Observable<any> {
        return this.subjectRqt.asObservable();
    }

    watchActionCartResponse(): Observable<ActionCartResponse> {
        return this.subjectRsp.asObservable();
    }

    actionRequest(value: ActionCartRequest) {
        switch (value.type) {
            case EnumTypeActionCart.deleteElement:
                // @ts-ignore
                const cartItems = this.cartService.convertToCartItem([<CartElement>{ prices: value.prices }]);
                this.cartService.deleteHolds(cartItems).subscribe(
                    {
                        next:() => {
                                const val = {
                                    success: true,
                                    message: 'success.successDeleteCart',
                                    cart: null,
                                    originalCall:value.from
                                };

                                this.analyticsService.trackRemoveFromCartFromCartPrice(value.prices);
                                this.symplifyCartService.removeFromCartPrice(value.prices)
                                const cart = this.cartService.cartSub.value
                                if(cart && cart.cartElements && cart.cartElements.length === 0){
                                    this.symplifyCartService.resetCart()
                                }
                                this.subjectRsp.next(val);
                        },
                        error:error1 => {
                                const val = {
                                    success: false,
                                    message: error1.error.error.message,
                                    cart: null,
                                    originalCall:value.from
                                };
                                this.subjectRsp.next(val);
                        }
                    }
                )

                return;
            case EnumTypeActionCart.callCart:
                this.callCart();
                return;
            case EnumTypeActionCart.addElements:
                const body = this.cartService.formatPriceHoldToReserveTicketsRequest
                (this.priceManagerService.getPricesSelected(), value.keyServer,value.urlImage);
                //console.log(body);
                //return;
                this.cartService.createHolds(body).subscribe(
                    {
                        next:(cartRes)=>{
                            this.analyticsService.trackAddToCart(this.priceManagerService.getPricesSelected())
                            this.symplifyCartService.addToCartPrice(this.priceManagerService.getPricesSelected())
                            const val = {
                                success: true,
                                message: '',
                                cart: null,
                                hold: cartRes,
                                originalCall:value.from
                            };
                            this.subjectRsp.next(val);
                        },
                        error:(error1)=>{
                            let er = '503';
                            if (error1.error && error1.error.error && error1.error.error.message) {
                                er = error1.error.error.message;
                            }
                            const val = {
                                success: false,
                                message: er,
                                cart: null,
                                originalCall:value.from
                            };
                            this.subjectRsp.next(val);
                        }
                    }
                );
                return;
            case EnumTypeActionCart.lessOne:

                return;
            case EnumTypeActionCart.deleteAllElement:
                const val = {
                    success: true,
                    message: '',
                    cart: null,
                    originalCall:value.from
                };
                this.subjectRsp.next(val);
                return;
            case EnumTypeActionCart.moreOne:
                const qty = this.priceManagerService.qtyPlusOne(value.prices[0]);
                if (qty === value.prices[0].cartDetailToken.length) {

                }
                return;
            case EnumTypeActionCart.splitPrice:

                this.subjectRsp.next({
                    name: EnumTypeActionCart.splitPrice,
                    success: true,
                    originalCall:value.from

                });
                return;
        }

        this.subjectRqt.next(value);
    }

    actionResponse(val: ActionCartResponse) {
        this.subjectRsp.next(val);
    }

    callCart() {
        const val = <ActionCartResponse>{
            success: true,
            message: '',
            cart: <Cart>{}
        };
        // sub.unsubscribe();
        this.subjectRsp.next(val);
    }
}
