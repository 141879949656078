<div class="">
  @switch (type) {
    @case ('event') {
      <div class="tw-flex tw-row tw-header-show tw-header-show-temp " >
        <div style="text-align: center;width: 80px">
          <div  class="bk-image-svg bk-image-svg-temp "
          ></div>
          <div style="font-size: 22px; margin-top: -53px; margin-left: 47px; height: 48px; color:green;">
            <span class="tw-header-number">{{getTickets()}}</span>
          </div>
        </div>
        <div style="text-align: center;margin-right: 10px;font-size: 42px; border: 1px solid;position: relative" class="total-price-temp">
          @if (totalOriginal >0) {
<div style="position: absolute; text-decoration: line-through;

    top: -1px;
    font-size: 25px;
    right: 0;"
              >
              {{( totalOriginal) | currency :'':'symbol-narrow':'':translate.currentLang}}
            </div>
          }
          {{total | currency :'':'symbol-narrow':'':translate.currentLang}}
        </div>
        <div style="flex: 1">
          @if (!iconShow) {
            <mat-icon>keyboard_arrow_right</mat-icon>
          }
          @if (iconShow) {
            <mat-icon>keyboard_arrow_down</mat-icon>
          }
        </div>
      </div>
      <div class="tw-header-font  tw-header-font-temp ">
        @if (cartElement.title) {
          <div style="font-weight: 600" class="cart-header-title">
            {{cartElement.title}}
          </div>
        }
        @if (cartElement.location?.name) {
          <div  twHideByEnvironment environment="feq mta" style="font-weight: 600"
            class="cart-header-location-name"
            >
            {{cartElement.location.name}}
          </div>
        }
        @if (cartElement.eventStart) {
          <div class="cart-header-eventStart" >
            {{cartElement.eventStart}}
          </div>
        }
      </div>
    }
    @case ('subscription') {
      <div class="tw-flex tw-row tw-header-show tw-primary" >
        <div style="text-align: center;width: 80px">
          <img src="./assets/resources/svg/movie-tickets.svg" style="height: 30px;" alt="cartlogo">
          <div style="font-size: 22px; margin-top: -53px; margin-left: 47px; height: 48px; color:#008000;">
            <span class="tw-header-number">{{getTickets()}}</span>
          </div>
        </div>
        <div class="tw-header-font">
          @if (cartElement.title) {
            <div style="font-weight: 600">
              {{cartElement.title}}
            </div>
          }
          @if (cartElement.location && cartElement.location.name) {
            <div twHideByEnvironment environment="feq mta" style="font-weight: 600">
              {{cartElement.location.name}}
            </div>
          }
          @if (cartElement.eventStart) {
            <div>
              {{cartElement.eventStart}}
            </div>
          }
        </div>
        <div style="text-align: right;width: 77px;margin-right: 10px;position: relative">
          @if (totalOriginal) {
            <div style="position: absolute; text-decoration: line-through;"
            >{{totalOriginal}}</div>
          }
          {{total | currency :'':'symbol-narrow':'':translate.currentLang}}
        </div>
        <div style="flex: 1">
          @if (!iconShow) {
            <mat-icon>keyboard_arrow_right</mat-icon>
          }
          @if (iconShow) {
            <mat-icon>keyboard_arrow_down</mat-icon>
          }
        </div>
      </div>
    }
    @case ('combo') {
    }
    @case ('member') {
      <div class="tw-flex tw-row tw-header-show tw-header-show-temp " >
        <div style="text-align: center;width: 80px">
          <div  class="bk-image-svg bk-image-svg-temp "
          ></div>
          <div style="font-size: 22px; margin-top: -53px; margin-left: 47px; height: 48px; color:green;">
            <span class="tw-header-number">{{getTickets()}}</span>
          </div>
        </div>
        <div style="text-align: center;margin-right: 10px;font-size: 42px; border: 1px solid;position: relative" class="total-price-temp">
          @if (totalOriginal >0) {
<div style="position: absolute; text-decoration: line-through;

    top: -1px;
    font-size: 25px;
    right: 0;"
              >
              {{( totalOriginal) | currency :'':'symbol-narrow':'':translate.currentLang}}
            </div>
          }
          {{total | currency :'':'symbol-narrow':'':translate.currentLang}}
        </div>
        <div style="flex: 1">
          @if (!iconShow) {
            <mat-icon>keyboard_arrow_right</mat-icon>
          }
          @if (iconShow) {
            <mat-icon>keyboard_arrow_down</mat-icon>
          }
        </div>
      </div>
      <div class="tw-header-font  tw-header-font-temp ">
        @if (cartElement.title) {
          <div style="font-weight: 600" class="cart-header-title">
            {{cartElement.title}}
          </div>
        }
        @if (cartElement.location?.name) {
          <div  twHideByEnvironment environment="feq mta" style="font-weight: 600"
            class="cart-header-location-name"
            >
            {{cartElement.location.name}}
          </div>
        }
        @if (cartElement.eventStart) {
          <div class="cart-header-eventStart" >
            {{cartElement.eventStart}}
          </div>
        }
      </div>
    }
  }
</div>
